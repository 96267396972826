@import '~@zf/stella-react/variables.scss';

.notification-center {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0.4rem;
  width: 46rem;
  box-sizing: border-box;
  bottom: 8rem;
  z-index: $highest-z-index;
}
