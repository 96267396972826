.subject-wrapper {
  width: 100%;
}

.send-test-btn {
  height: fit-content;
  white-space: nowrap;
}

.spacer {
  height: 2rem;
}
