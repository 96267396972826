@import '~@zf/stella-react/variables.scss';

.search-autofill-container {
  position: inherit;
  width: 100%;
  background-color: $silver-200;
  border-radius: $border-radius-4;
}

.search-autofill-container span p {
  text-align: left;
  text-overflow: ellipsis;
}
