@import '~@zf/stella-react/variables.scss';

.columns-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.actions {
  display: flex;
  flex-direction: row;
}

.actions > button {
  margin-left: 1rem;
}
