@import '~@zf/stella-react/variables.scss';

.listpage-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8rem);
  width: 90%;
  border-radius: 5rem;

  .top-bar {
    padding-top: 2.5vh;
    width: 100%;
  }

  .list {
    box-sizing: border-box;
    height: 100%;
    overflow-y: hidden;
    border-radius: $border-radius-8 0 $border-radius-8 $border-radius-8;
    border: $border-one $silver-200;
    background-color: white;
  }
}

.extra {
  display: flex;
  gap: $spacing-16;
  justify-content: center;
  align-items: center;
}

.card {
  height: 69.5vh;
}

.search-bar {
  margin-right: 1rem !important;
}

.more-actions {
  margin: auto;
  margin-left: 1rem;
  font-size: $font-size-16;
}

.card-body {
  min-height: 36rem;
  overflow-y: hidden;
}



