@import '~@zf/stella-react/variables.scss';

.chart-wrapper {
  overflow-y: auto;
}

.chart * {
  font-size: $font-size-12;
  font-weight: $font-weight-bold;
}

.empty-chart {
  min-height: 25rem !important;
}

.chart-container-wrapper {
  height: 25rem;
}
