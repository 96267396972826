@import '~@zf/stella-react/variables.scss';

.virtual-table-body {
  width: 100%;
  outline: none;
  overflow: hidden;
  color: $graphite;
}

.virtual-table-cell {
  outline: none;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  font-family: $font-default;
  font-size: $font-size-16;
  padding-left: $spacing-24;

  &.no-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.virtual-table-cell-content {
  margin: auto 0rem;

  &.fixed-height {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.cell-content-wrapper {
  width: 100%;
  overflow: hidden;
}

.overflow-elipsis {
  display: flex;
  align-items: flex-end;
}

.breakword {
  white-space: normal;
}

.overlay {
  width: 100% !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.virtual-table-header,
.virtual-table-row {
  display: block;
  box-sizing: border-box;
}

.virtual-table-row {
  .virtual-table-checkbox-hide {
    display: flex;
  }
  background-color: $white-200;
  transition: all 0.1s ease-in-out;

  .virtual-table-checkbox {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &:hover,
  &.selected {
    background-color: $silver-50;

    &.invert {
      background-color: $white-200;
    }

    .virtual-table-checkbox-hide {
      display: flex;
    }
  }

  &.invert {
    background-color: $silver-50;
  }
}
.virtual-table-row-with-collapsed {
  display: grid;
  grid-template-rows: min-content min-content;
}

.action-performed {
  font-weight: $font-weight-bold;
}

.virtual-table-header {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 998;
  width: 100%;
  top: 0;
  left: 0;
  font-weight: $font-weight-bold;
  color: $silver-600;

  &.invert {
    box-shadow: none !important;
    background: $silver-200 !important;
  }
}

.sortable-fields {
  cursor: pointer;
}

.virtual-table-header-cell {
  position: relative;
  background: $white-200;
  font-size: $font-size-16;

  .virtual-table-header-cell-content-wrapper {
    display: flex;
    align-items: center;
  }

  .virtual-table-header-cell-sort-icon {
    margin-left: $spacing-8;
    color: $silver-600;
    margin-right: $spacing-8;
  }
}

.virtual-table-data-cell {
  position: relative;
}

.contentAlignRight > * {
  display: flex;
  justify-content: flex-end;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.row-pointer * {
  cursor: pointer !important;
}

.simple-tooltip {
  border: 0.1rem solid $silver-200 !important;
  border-radius: $border-radius-4 !important;
  font-family: $font-default !important;
  font-size: $font-size-16 !important;
  box-shadow: $small-box-shadow !important;
  opacity: 1 !important;
  z-index: $highest-z-index;
}

.header-ellipsis {
  width: 100% !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
