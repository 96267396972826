@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
}

.wrapper div {
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: $stacked-second-z-index;
}

.underlay {
  z-index: $stacked-first-z-index;
  transform: rotate(2.36deg);
}
