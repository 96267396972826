@import '~@zf/stella-react/variables.scss';

.transaction-details {
  display: flex;
  font-size: $font-size-16;
  gap: 2rem;
  box-sizing: border-box;
}

.transaction-details > button > span {
  display: block;
  width: $spacing-16;
}

.transaction-details > button {
  height: fit-content;
}

.top-part-table-wrapper {
  width: 100%;
}

.top-part {
  display: flex;
  gap: 2rem;
  padding-bottom: $spacing-16;
  border-bottom: 0.2rem solid $silver-200;
}

.top-part > div:nth-of-type(1),
.top-part > div:nth-of-type(2) {
  width: 40%;
}

.top-part > div:nth-of-type(3),
.top-part > div:nth-of-type(4) {
  width: 10%;
}

.arrow {
  margin-top: 2.1rem;
}
