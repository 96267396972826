@import '~@zf/stella-react/variables.scss';

.measurement-multiValue {
  margin-top: 1rem;
  padding: 2rem;
}

.heading-meter {
  margin-bottom: 2rem;
}

.heading {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.measurement-wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.measurement-wrapper p {
  line-height: $line-height-40;
}

.previous-current-next {
  padding-top: $spacing-16;
  display: grid;
  gap: $spacing-16;
  grid-template-columns: 1fr 1fr 1fr;
}

.previous-current-next span {
  margin-bottom: 1rem;
}

.channel {
  display: grid;
  gap:$spacing-32;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.channel span {
  margin-bottom: 1rem;
}

.empty-state {
  margin-top: 30vh;
}

.meter-autofill {
  margin-top: 2rem;
}

.current-value-warning {
  border: 0.2rem solid $orange-600;
}

.warning {
  line-height: normal !important;
  margin-top: 0.5rem;
  color: $orange-600;
  font-size: $font-size-16;
}
