@import '~@zf/stella-react/variables.scss';

.detail-line-wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}

.border-left {
  border-left: 0.2rem solid $silver-400;
}
