@import '~@zf/stella-react/variables.scss';

.tree {
  list-style: none;
  font-family: $font-default;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  color: $graphite;
  margin: 0;
  padding: 0;
}

.tree-item {
  display: flex;

  .tree-title {
    align-items: center;
    line-height: $line-height-24;
    outline: none;
    min-width: $spacing-32;

    .tree-title-icon {
      line-height: normal;
      font-size: $font-size-20;
      margin-right: 0.5rem;
      line-height: $line-height-24;
    }
  }
}

.tree-item > button {
  font-size: $font-size-16 !important;
}

.tree-btn {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.tree > li {
  text-align: left;
}
