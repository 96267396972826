@import '~@zf/stella-react/variables.scss';

.calendar-container {
  display: grid;
  padding: $spacing-8;

  .calendar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-16;
    color: $graphite;
    border-radius: $border-radius-4;
    padding: $spacing-12 0;

    &.highlight {
      color: $silver-700;
      background: $silver-200;
    }

    &.selected {
      color: $white-200;
      background: $blue-600;
    }

    &.range {
      color: $white-200;
      background: $blue-400;
      border-radius: 0;
    }

    &.disabled {
      &:hover {
        color: inherit;
        background: inherit;
      }
    }

    &:hover,
    &.range:hover,
    &.selected:hover,
    &.today:hover {
      background: $blue-200;
    }
  }
}
