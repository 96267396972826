@import '~@zf/stella-react/variables.scss';

.type-input-wrapper {
  display: flex;
  align-items: center;

  span {
    font-size: $font-size-20;
  }

  .input {
    width: 100%;
  }
}

.problem {
  padding-bottom: 1rem;
}
