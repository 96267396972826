@import '~@zf/stella-react/variables.scss';

.type-input-wrapper {
  min-width: 22.5rem;
  display: grid;
  padding-top: $spacing-8;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: $spacing-16;
}

.takesTwoColums {
  grid-column: 1/3;
}
