@import '~@zf/stella-react/variables.scss';

.wizard-nav {
  list-style: none;
  background: transparent;
  padding-left: 0;
  top: $spacing-24;
  position: sticky;
  height: 90vh;
  box-sizing: border-box;
}

.wizard-nav-list-item {
  width: 100%;
  margin-bottom: 5rem;

  .wizard-nav-progress {
    display: inline-block;
    width: calc(100% - 5rem);
    min-width: min-content;
    padding: 1rem 2rem;
    font-family: $font-default;
    font-size: $font-size-20;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: $silver-200;
    background-color: $white-200;
    border-radius: $border-radius-4;
    border: 1px solid transparent;
    margin-bottom: 1rem;
    transition: all 0.1s ease-in-out;

    span {
      margin-right: 1rem;
      font-size: $font-size-25;
    }

    &.wizard-nav-progress-error {
      border-left: solid 0.4rem $red-600;
      color: $red-600;
      box-shadow: none;
    }

    &.wizard-nav-progress-active {
      color: $silver-700;
      border: 1px solid $blue-600;
      font-weight: $font-weight-bold;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      box-shadow: $small-box-shadow;
    }

    &:hover {
      color: $silver-700;
      box-shadow: $small-box-shadow;

      &.wizard-nav-progress-error {
        border-left: solid 0.4rem $red-600;
        color: $red-600;
        box-shadow: none;
      }
    }
  }

  .wizard-nav-feedback {
    color: $red-600;
    list-style: upper-roman;
    font-family: $font-default;
    font-size: $font-size-12;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    text-align: left;

    .wizard-nav-feedback-item {
      margin-top: 0.5rem;
    }
  }

  &.substep {
    margin-top: -3rem;
    margin-left: $spacing-16;
    width: calc(100% - 6.5rem);
    transform: scale(0.85);
  }
}

.wizard-nav-list-item * {
  text-align: left;
}
