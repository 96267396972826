.link-meter-dialog-wrapper div:first-of-type {
  margin: auto;
}

.link-meter-dialog-wrapper h3 {
  margin: auto;
}

.inputs {
  max-width: 50rem;
}
