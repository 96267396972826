@import '~@zf/stella-react/variables.scss';

.size {
  font-size: $font-size-16;
}

.error-codes {
  color: $red-600;
  list-style: upper-roman;
  font-family: $font-default;
  font-size: $font-size-12;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  width: 100%;
  padding: 0$spacing-32 0$spacing-32;

  .error-code {
    margin-top: 0.5rem;
  }
}

.content-card {
  margin-top: 1rem;
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
}

.content-table {
  height: 31rem;
}

.export-jobs-content-card-body {
  min-height: 32rem;
  overflow: hidden;
}

.export-jobs-detail-card {
  min-height: 100%;
}
