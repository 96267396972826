@import '~@zf/stella-react/variables.scss';

.progress-bar-container {
  align-items: center;
}

.progress-label {
  color: $silver-700;
  font-family: $font-default;
  margin-top: 1rem;

  &.small {
    font-size: $font-size-16;
  }

  &.medium {
    font-size: $font-size-16;
  }

  &.large {
    font-size: $font-size-25;
  }
}

.progress-bar {
  overflow: hidden;
  background-color: rgba($silver-400, 0.8);

  &.small {
    height: 1rem;
    width: 10rem;
    border-radius: $border-radius-4;
  }

  &.medium {
    height: 1rem;
    width: 20rem;
    border-radius: $border-radius-4;
  }

  &.large {
    height: 2rem;
    width: 20rem;
    border-radius: $border-radius-4;
  }

  .progress {
    background-color: $red-600;
    height: 100%;

    &.progress-25 {
      background-color: $yellow-600;
    }

    &.progress-50 {
      background-color: $blue-400;
    }

    &.progress-75 {
      background-color: $green-600;
    }
  }
}
