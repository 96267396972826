@import '~@zf/stella-react/variables.scss';

.wizard-nav {
  padding-left: 0;
  top: $spacing-24;
  position: sticky;
  top: 0;
  height: 90vh;
  box-sizing: border-box;
}
