@import '~@zf/stella-react/variables.scss';

.graph-wrapper {
  height: 56vh;
  margin-top:$spacing-32;
}

.graph-container {
  margin-left: auto;
  margin-right: auto;
  font-size: $font-size-16;
}
