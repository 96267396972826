@import '~@zf/stella-react/variables.scss';

.attachment-wrapper {
  display: grid;
  grid-template-rows: 3fr 1fr;
}

.preview {
  width: 35vw;
  border-radius: $border-radius-4;
  height: 55vh;
}
