@import '~@zf/stella-react/variables.scss';

.tooltip-trigger {
  width: fit-content;
  background-color: $white-200;
  border: 0.1rem solid $blue-200;
  border-radius: $border-radius-8;
  box-shadow: 0 0.2rem 0.6rem rgb(0 0 0 / 10%);
  z-index: $highest-z-index;
}
