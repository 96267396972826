@import '~@zf/stella-react/variables.scss';

.row {
  display: flex;
  margin-top: $spacing-8;
  margin-bottom: $spacing-16;

  p {
    text-align: right;
    color: $silver-600;
    font-weight: bold;
    flex-grow: 1;
  }

  p:first-of-type {
    text-align: left;
    flex-grow: 2;
  }
}
