@import '~@zf/stella-react/variables.scss';

.crumb {
  display: flex;
  align-items: center;
  gap: $spacing-16;
  white-space: nowrap;
  color: $graphite;
}

.active {
  cursor: auto;
  color: $graphite;
  font-weight: $font-weight-bold;
}
