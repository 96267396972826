@import '~@zf/stella-react/variables.scss';

.no-padding-top {
  padding-top: 0rem;
}

.incoming-invoice {
  position: relative;
}

.incoming-invoice h3 {
  margin-bottom: 0.5rem;
}

.period {
  font-size: $font-size-16;
  color: $silver-600;
}

.incoming-invoices-wrapper {
  padding-left: 3.5rem;
  height: 45vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.incoming-invoices-wrapper > div:nth-of-type(1) {
  border-right: 0.2rem dashed $silver-600;
}

.download-icon {
  position: absolute;
  right: 4rem;
  top: 2rem;
}

.download-icon * {
  font-size: $font-size-16 !important;
}

.incoming-invoice-components {
  max-height: 28rem;
}
