@import '~@zf/stella-react/variables.scss';

.table-wrapper {
  margin-top: $spacing-8;
  margin-bottom: $spacing-24;
  height: 45rem;
}

.locations-summary {
  margin-top: $spacing-24;
  text-align: center;
}
