@import '~@zf/stella-react/variables.scss';

.table-row > div {
  font-family: $font-default;
  font-weight: $font-weight-regular;
  font-size: $font-size-16;
  color: $graphite;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-row {
  overflow: visible !important;
}

.table-row:hover {
  background: $silver-50;
}

.hover {
  background: $silver-50;
}

.cursorPointer {
  cursor: pointer;
}
