@import '~@zf/stella-react/variables.scss';

.positive {
  color: $green-600;
}

.negative {
  color: $red-600;
}

.unsettled-amount-wrapper {
  margin-right: $spacing-8;
}

.unsettled-amount {
  text-align: center;
  padding: $spacing-4;
  border-radius: $border-radius-4;
}

.settlement-details-card {
  min-height: 37rem;
}

.settlement-details-card-body {
  overflow: hidden;
}
