@import '~@zf/stella-react/variables.scss';

.add-customer-group-dialog-desc {
  font-size: $font-size-16;
  margin-bottom: $spacing-16;
}

.add-customer-group-input {
  width: 43.5rem;
}
