@import '~@zf/stella-react/variables.scss';

.all-locations-dialog {
  width: 77rem;
  padding: 0rem $spacing-32;
}

.all-locations-dialog > div:first-of-type {
  margin-top: $spacing-8;
  margin-bottom: $spacing-16;
}
