@import '~@zf/stella-react/variables.scss';

.wizard-input-wrapper {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.wizard-input-indicator-container {
  height: 2rem;
  margin: 1rem 0;
  font-family: $font-default;
  font-size: $font-size-12;
  color: $red-600;

  .bold {
    font-weight: $font-weight-bold;
  }

  .wizard-input-indicator {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 4rem;
    height: 2rem;
    margin-right: 1rem;
    border-radius: $border-radius-4;

    &.wizard-input-done-indicator {
      background-color: $green-600;
    }
  }
}
