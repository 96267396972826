@import '~@zf/stella-react/variables.scss';

.tree {
  list-style: none;
  font-family: $font-default;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  color: $silver-700;
  margin: 0;
  padding: 0;
}

.tree-item {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .tree-title {
    outline: none;
    min-width: $spacing-32;

    .tree-title-icon {
      line-height: normal;
      font-size: $font-size-20;
      margin-right: 0.5rem;
    }
  }
}

.pointer {
  cursor: pointer;
}

.margin-and-border {
  margin-bottom: $spacing-12;
  padding-bottom: $spacing-12;
  border-bottom: $border-one $silver-400;
}
