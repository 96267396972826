@import '~@zf/stella-react/variables.scss';

.multi-line-input {
  display: block;
  font-family: $font-default;
  width: 100%;

  &.disabled {
    color: $silver-600;
  }

  .label {
    display: block;
    font-family: inherit;
    font-size: $font-size-12;
    color: $silver-600;
    margin-bottom: 0.4rem;

    .hidden-label {
      display: none;
    }
  }

  &.success {
    .textarea {
      border-color: $green-600;
    }

    .label {
      color: $green-600;
    }
  }

  &.error {
    .textarea {
      border-color: $red-600;
    }

    .label {
      color: $red-600;
    }
  }

  .textarea {
    display: block;
    font-family: inherit;
    color: inherit;
    font-size: $font-size-16;
    width: 95%;
    border: 0.1rem solid $silver-600;
    border-radius: $border-radius-4;
    outline: none;
    padding: 1rem;

    &:focus {
      border: 0.1rem solid $blue-600;
    }
  }
}
