.locations-card-body {
  min-height: 43.5rem;
  overflow-y: hidden;
}

.service-icon {
  vertical-align: bottom;
}

.contract-link {
  margin-right: 1rem;
}

.customers {
  display: flex;
  flex-direction: column;
}
