@import '~@zf/stella-react/variables.scss';

.right-footer {
  display: flex;
  justify-content: space-between;
  height: 8rem;
  padding: $spacing-8 $spacing-24;
  border-top: 0.2rem solid $silver-200;
}

.align-center {
  display: flex;
  align-items: center;
}

.button-wrapper {
  display: flex;
  gap: $spacing-16;
  justify-content: flex-end;
}
