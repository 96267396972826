@import '~@zf/stella-react/variables.scss';

.selection-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $font-default;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  padding: $spacing-24;
  border: $border-one $silver-200;
  background-color: transparent;
  border-radius: $border-radius-4;
  cursor: pointer;
  outline: none;
}

.selection-button > span {
  font-size: $font-size-20;
}

.selection-button:hover {
  background-color: $blue-50;
  box-shadow: $small-box-shadow;
}

.small {
  padding: $spacing-8;
}

.selected,
.selected:hover {
  background-color: $blue-600;
  color: $white-200;
  box-shadow: none;
}

.disabled:hover {
  background-color: transparent;
  box-shadow: none;
  cursor: not-allowed;
}

.selected-disabled,
.selected-disabled:hover {
  background-color: $silver-200;
  color: $silver-400;
  box-shadow: none;
  cursor: not-allowed;
}
