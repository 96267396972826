@import '~@zf/stella-react/variables.scss';

.title-wrapper {
  padding-right: $spacing-8;
  min-width: 15rem;
  display: grid;
  padding-top: $spacing-12;
  padding-bottom: $spacing-12;
  text-align: left;
  grid-template-columns: min-content 1fr min-content;
  font-family: $font-default;
  font-size: $font-size-16;
  line-height: $line-height-24;
  margin: auto;
  color: $graphite;

  span {
    font-family: $font-default;
    font-size: $font-size-20;
    line-height: $font-size-20;
    font-weight: $font-weight-bold;
    color: $graphite;
  }
}
