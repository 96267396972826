@import '~@zf/stella-react/variables.scss';

.missing-msmt-btn {
  padding: 0rem;
}

.missing-msmt-btn:hover {
  color: $blue-600 !important;
  background-color: $white-200;
}

.missing-msmt-btn > div > span {
  margin-top: 0.3rem;
}

.missing-msmt-btn * {
  font-size: $font-size-16 !important;
}
