@import '~@zf/stella-react/variables.scss';

.detailpage {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  outline: none;

  .title {
    transition: color 0.2s ease;
    color: $silver-600;
    font-size: $font-size-16;
    line-height: $line-height-32;
    letter-spacing: 0.2rem;
    font-weight: $font-weight-bold;
  }

  .border {
    border-bottom: 0.3rem solid $blue-600;
    transition: all 0.3s ease;
    transform: scaleX(0);
    margin-top: $spacing-8;
  }

  &.is-selected {
    .title {
      color: $blue-600;
    }
    .border {
      transform: scaleX(1);
    }
  }

  &:hover,
  &:focus {
    .title {
      color: $blue-600;
    }
  }
}

.listpage {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
  margin-left: $spacing-24;
  outline: none;

  .title {
    transition: color 0.3s ease;
    color: $silver-600;
    padding: 0.5rem;
    font-size: $font-size-31;
    font-weight: $font-weight-bold;
  }

  .border {
    border-bottom: 0.3rem solid $graphite;
    transition: all 0.3s ease;
    transform: scaleX(0);
    margin-top: $spacing-8;
  }

  &.is-selected {
    .title {
      color: $graphite;
    }
    .border {
      transform: scaleX(1);
    }
  }

  &:hover,
  &:focus {
    .title {
      color: $graphite;
    }
  }
}
