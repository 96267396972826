@import '~@zf/stella-react/variables.scss';

.lang-checkbox {
  height: 4rem;
  margin-top: 1.25rem;
}

.contact-detail {
  font-size: $font-size-16;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.contact-detail span {
  color: $silver-700;
  margin-right: 1rem;
  padding-top: 1.25rem;
}
