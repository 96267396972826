@import '~@zf/stella-react/variables.scss';

.link {
  display: inline;
  align-items: center;
  color: $graphite;
  text-decoration: none;
  font-size: $font-size-16;
  font-family: $font-default;
  font-weight: $font-weight-bold;
  line-height: $line-height-24;
}

.link:hover {
  text-decoration: underline;
}

.bold {
  font-weight: $font-weight-bold;
}

.sub a {
  font-size: $font-size-12 !important;
  line-height: $line-height-16 !important;
}

.sub span {
  font-size: $font-size-12 !important;
  line-height: $line-height-16 !important;
}

.icon-link {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}
