@import '~@zf/stella-react/variables.scss';

.card-header {
  height: 6.5rem;
  grid-area: card-header;
  display: grid;
  grid-template-areas: 'secondary heading info extraright primary';
  grid-template-columns: min-content min-content 1fr auto auto;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 0.1rem solid $silver-200;

  .header-warning {
    color: $red-600 !important;
  }

  .card-header-secondary,
  .card-header-primary,
  .card-header-heading,
  .card-header-extra {
    display: flex;
    align-items: center;
  }

  .card-header-heading {
    margin: 0rem;
    text-align: left;
    grid-area: heading;
    padding: 0rem $spacing-8 0rem $spacing-24;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-header-heading h2 {
    margin: 0rem;
    line-height: $line-height-48;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-header-filters {
    grid-area: filters;
    padding: 0rem $spacing-8 0rem $spacing-8;
  }

  .card-header-tags {
    grid-area: tags;
    justify-content: left;
    margin-left:$spacing-32 !important;
  }

  .card-header-extra-left {
    grid-area: info;
    display: grid;
    width: 100%;
  }

  .card-header-extra-right {
    grid-area: extraright;
    padding: 0rem $spacing-8 0rem $spacing-8;
    display: grid;
    margin: auto;
  }

  .card-header-secondary {
    grid-area: secondary;
  }

  .card-header-primary {
    grid-area: primary;
    padding: 0rem $spacing-8 0rem $spacing-8;
  }

  .card-header-button {
    width: 100%;
    display: flex;
    align-items: center;
    height:$spacing-32;
  }
}

.filter-and-tags {
  grid-template-areas: 'secondary info heading filters tags extraright primary';
  grid-template-columns: min-content min-content min-content 3rem 1fr auto auto;
}

.card-header > * {
  margin: auto;
}
