@import '~@zf/stella-react/variables.scss';

.options {
  padding-left: $spacing-16;
  border-left: $border-two $silver-400;
}

.margin > div:first-of-type {
  width: 40%;
}
