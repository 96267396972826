@import '~@zf/stella-react/variables.scss';

.no-tenants {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.no-tenants p {
  padding-top: 2rem;
}

.no-tenants p:first-of-type {
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
}

.no-tenants p:nth-of-type(2) {
  padding-top: 1rem;
}

.contact-info {
  padding-bottom: 4rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
}

.contact-info p:first-of-type {
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
}

.contact-info p:nth-of-type(2) {
  padding-top: 1rem;
}
