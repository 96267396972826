@import '~@zf/stella-react/variables.scss';

.remove-icon {
  margin-top: 0.5rem;
  font-size: $font-size-16;
}

.remove-icon * {
  width: fit-content !important;
  height: fit-content !important;
}
