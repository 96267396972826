@import '~@zf/stella-react/variables.scss';
.error-image {
  padding: $spacing-8;
  width: 100%;
  height: 100%;
}

.wrapper {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-image-alternative {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50vh;
  max-height: 50vh;
}
