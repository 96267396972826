@import '~@zf/stella-react/variables.scss';

.spinner-wrapper {
  height: $tab-body-height;
}

.general-grid {
  padding: $spacing-24 0rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content 1fr;
}

.general-grid > h3 {
  padding-left: $spacing-24;
  padding-bottom: 0.5rem;
}

.four-columns {
  grid-template-columns: 1fr 1fr 1.3fr 1fr;
}

.general-grid > h2 {
  padding: 1rem 1rem 1rem $spacing-32;
  font-weight: $font-weight-bold;
}
