@import '~@zf/stella-react/variables.scss';

.number-sequence-config {
  display: grid;
  grid-template-areas: 'numbersequences segments';
  grid-template-columns: 30% 1fr;
  grid-template-rows: 70vh;
  grid-gap: $spacing-24;
  padding: 3.5rem;
  position: relative;
}

.number-sequences {
  grid-area: numbersequences;
}

.segments {
  grid-area: segments;
}

.tree {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.tree > ul > li > div:first-of-type {
  margin: auto;
}
.segment-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-16;
}

.segment-title > p {
  width: fit-content;
}

.tree-item-lookup {
  padding: 1rem;
}

.segments-card-body {
  padding: 2rem;
}
