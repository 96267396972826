@import '~@zf/stella-react/variables.scss';

.title {
  gap: $spacing-16;
}

.title * {
  font-size: $font-size-20 !important;
  font-weight: $font-weight-bold;
}

.tree {
  padding: $spacing-16;
  font-size: $font-size-20;
  width: unset;
}

.tree-nested {
  color: $graphite;
  margin-left:$spacing-32;
}

.configure {
  font-size: $font-size-16 !important;
  color: $silver-600;
  line-height: $line-height-24;
  width: 100%;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;
  color: $graphite;
}

.configure:hover {
  color: $blue-700 !important;
}

.failed-paragraph {
  text-align: right;
  color: $red-600;
}

.ready-paragraph {
  text-align: right;
  color: $green-600;
}

.tree-item {
  margin-left: 6.5rem;
  width: 30rem;
  cursor: default;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tree-item p {
  text-align: right;
  color: $graphite !important;
}

.insights-title {
  font-size: $font-size-20;
}
