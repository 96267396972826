@import '~@zf/stella-react/variables.scss';

.header {
  height: 6rem;
}


.title-icon span {
  font-size: $spacing-32;
}

.sub-header {
 
  margin-left: 4rem;
  color: $silver-600;
  font-size: $font-size-16;
  padding-bottom: $spacing-4;

}

.main-header {
  position: relative;
  height: $spacing-48;
  display: flex;
  align-items: center;
}

.main-header > div {
  margin-right: $spacing-8;
}

.main-header h1 {
  margin-right: $spacing-24;
}

.main-header h1 {
  max-width: 110rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-header div:nth-of-type(2) {
  white-space: nowrap;
}

.status {
  margin-top: $spacing-4;
}

.actions {
  position: absolute;
  margin-right: 0rem !important;
  right: 0rem;
}
