@import '~@zf/stella-react/variables.scss';

.card-body {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.cash-position {
  display: flex;
  gap: $spacing-8;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.prepayment-device-balance {
  display: flex;
  justify-content: flex-end;
}

.cash-position > p {
  width: min-content;
  white-space: nowrap;
}

.icon-amount {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-amount > span {
  font-size: $font-size-25;
}

.icon-amount > p {
  font-size: $font-size-25;
  width: fit-content;
  margin-left: $spacing-8;
}

.table-labels {
  display: flex;
  padding: 0rem 2rem;
}

.table-labels > div:last-of-type {
  margin-left: auto;
}

.type {
  display: flex;
  align-items: center;
}

.type a {
  width: fit-content;
  margin-left: $spacing-8;
  margin-right: 0rem;
}

.transactions {
  height: 100%;
  display: grid;
  grid-template-columns: 49% 2% 49%;
  padding: $spacing-8;
}

.divider {
  height: 18rem;
  width: 0.4rem;
  margin-bottom: auto;
  margin-top: $spacing-8;
  margin-left: 50%;
  border-radius: 50%;
  opacity: 0.5;
  background-color: $silver-200;
}

.table {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.no-transactions-left {
  margin-top: $spacing-8;
  margin-left: 2rem;
}

.no-transactions-right {
  margin-top: $spacing-8;
  width: fit-content;
  margin-right: 2rem;
}
