@import '~@zf/stella-react/variables.scss';

.filter-tags {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: $spacing-8;
}

.tag {
  display: flex;
  align-items: center;
  width: fit-content;
  float: right;
  white-space: nowrap;
  background-color: $white-200;
  gap: $spacing-16;
  padding: $spacing-8 $spacing-8 $spacing-8 $spacing-16;
  color: $graphite;
  font-family: $font-default;
  font-size: $font-size-16;
  font-weight: $font-weight-regular;
  line-height: $line-height-24;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-4;
}

.tag-left {
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  margin-top: $spacing-16;
  background-color: $white-200;
  gap: $spacing-16;
  padding: $spacing-8 $spacing-16;
  padding-right: $spacing-8;
  color: $graphite;
  font-family: $font-default;
  font-size: $font-size-16;
  font-weight: $font-weight-regular;
  line-height: $line-height-24;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-4;
}

.tag-left {
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  margin-top: $spacing-16;
  background-color: $white-200;
  gap: $spacing-16;
  padding: $spacing-8 $spacing-16;
  padding-right: $spacing-8;
  color: $graphite;
  font-family: $font-default;
  font-size: $font-size-16;
  font-weight: $font-weight-regular;
  line-height: $line-height-24;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-4;
}

.tag-left {
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  margin-top: $spacing-16;
  background-color: $white-200;
  gap: $spacing-16;
  padding: $spacing-8 $spacing-16;
  padding-right: $spacing-8;
  color: $graphite;
  font-family: $font-default;
  font-size: $font-size-16;
  font-weight: $font-weight-regular;
  line-height: $line-height-24;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-4;
}
