@import '~@zf/stella-react/variables.scss';

.dialog-layout * {
  text-align: left !important;
}

.inputs {
  padding-left: 0rem;
  margin-bottom: $spacing-12;
}

.searchbar {
  width: 40rem;
}

.selected-list {
  width: 50rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.locations-displayAdress {
  margin-bottom: $spacing-32;
}
