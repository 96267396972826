@import '~@zf/stella-react/variables.scss';

.reference-measurements {
  padding: 0 $spacing-16;
  margin-top: -$spacing-24;
}

.reference-measurements > div {
  max-width: 15rem;
}

.reference-measurements span {
  text-align: center;
}
