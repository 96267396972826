@import '~@zf/stella-react/variables.scss';

.wrapper {
  position: relative;
}

.unit-tariff {
  padding-left: 1rem;
  flex-wrap: wrap;
}

.tier {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 1rem;
}

.tier p:nth-child(2) {
  text-align: right;
}

.default-tariff {
  width: fit-content;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.flex-div {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
}

.flex-div > div:last-of-type {
  margin-left: $spacing-16;
}

.flex-div > div:last-of-type > div {
  width: 50%;
}

.billing-tariff-header {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.billing-tariff-header-icon,
.billing-tariff-header-heading {
  font-size: $font-size-25;
}

.tree-wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
}
