@import '~@zf/stella-react/variables.scss';

.config-column {
  position: relative;
  height: 100%;
  padding: 2.4rem;
  display: grid;
  grid-auto-rows: min-content;
}

.column-body {
  margin-top: $spacing-16;
  height: 65vh;
  overflow-y: auto;
}

.column-body-no-header {
  margin-top: 0rem;
}

.column-body-w-subtitle {
  height: 61vh; // compensate for subtitle spacing (ca 3.2rem)
}

.border {
  border-right: 0.2rem solid $silver-200;
}

.border-dashed {
  border-right: 0.3rem dashed $silver-200;
}

.border-btn {
  position: absolute;
  right: -1.85rem;
  top: 50%;
}
