@import '~@zf/stella-react/variables.scss';

.wizard-header {
  font-family: $font-default;
  font-size: $font-size-16;
  line-height: $line-height-32;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: .08rem;
  color: $silver-600;
  text-align: center;
  margin-bottom: $spacing-24;
}
