@import '~@zf/stella-react/variables.scss';

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0rem $spacing-16;
}

.calendar-header > button {
  font-size: $font-size-16;
  padding: $spacing-24;
}
