@import '~@zf/stella-react/variables.scss';

.location {
  background-color: $silver-200;
}

.contract-list {
  min-height: 32rem;
}

.table {
  padding-bottom: $spacing-12;
}

.utility-type-icon {
  margin-right: 0.3rem;
  margin-top: 0.5rem;
}

.contract-card-body {
  overflow-y: auto;
}
