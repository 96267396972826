.house-img {
  width: 100%;
  height: 35rem;
  padding-top: 4rem;
  box-sizing: 'border-box';
}

.no-border {
  border: none;
}

.crumb-text {
  text-align: center;
}
