@import '~@zf/stella-react/variables.scss';

.subtitle {
  font-size: $font-size-16;
  line-height: $line-height-24;
  display: flex;
}

.subtitle > a {
  color: $silver-600;
  margin-left: $spacing-4;
  margin-right: 0;
  text-decoration: underline;
}

.subtitle > a:hover {
  color: $graphite;
}
