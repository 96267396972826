@import '~@zf/stella-react/variables.scss';

.config-card {
  position: relative;
  padding: 2rem;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-8;
  background-color: $white-200;
  outline: none;
  display: flex;
  flex-direction: column;
}

.config-card-hover:hover {
  cursor: pointer;
  border: 0.1rem solid $blue-400;
}

.active {
  border: 0.1rem solid $blue-600;
}

.radio-btn {
  position: absolute;
  left: $spacing-8;
  top: $spacing-4;
  font-size: $font-size-16;
  line-height: $line-height-24;
  color: $blue-600;
}
