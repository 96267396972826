@import '~@zf/stella-react/variables.scss';

.add-msmt {
  margin-top: $spacing-24;
}

.measurement {
  padding: $spacing-32 $spacing-24;
  background-color: $silver-50;
  border-radius: $border-radius-8;
}
