@import '~@zf/stella-react/variables.scss';

.selection-incomingBankingTransaction {
  height: 30vh;
  margin-bottom: $spacing-32;
}

.manual-matching-extra-info {
  display: flex;
  flex-direction: column;
}
