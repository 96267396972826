@import '~@zf/stella-react/variables.scss';

.card {
  height: auto;
  margin: 0;
  overflow: hidden;
}

.card-body {
  padding: $spacing-16 $spacing-32;
  flex-direction: column;
  display: flex;
  gap: $spacing-8;
  overflow: hidden;
}

.info-grid {
  width: 100%;
}

.settlement-header {
  display: flex;
  flex-direction: row;
  gap: $spacing-8;
}

.cash-position {
  display: flex;
  gap: $spacing-8;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.prepayment-device-balance {
  display: flex;
  justify-content: flex-end;
}

.cash-position > p {
  width: min-content;
  white-space: nowrap;
}

.icon-amount {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-bold;
  font-family: $font-default;
  font-size: $font-size-25;
  line-height: $line-height-40;
}

.icon-amount > span {
  font-size: $font-size-25;
}

.icon-amount > p {
  font-size: $font-size-25;
  width: fit-content;
  margin-left: $spacing-8;
}

.locked-info {
  width: 100%;
}
