@import '~@zf/stella-react/variables.scss';

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-single p {
  font-size: $font-size-12;
}

.details-btn {
  cursor: pointer;
}

.details-btn:hover {
  text-decoration: underline;
}

.iban-text:hover {
  text-decoration: underline;
}
