@import '~@zf/stella-react/variables.scss';

.summary {
  display: flex;
  font-family: $font-default;

  .content {
    display: inline-block;
    box-sizing: border-box;
    font-size: $font-size-16 !important;
    width: calc(100% - 5rem);
  }

  .content p {
    margin: 0rem;
  }

  .remove {
    display: inline-block;
    box-sizing: border-box;
    width: 5rem;
    height: 100%;
    text-align: right;
    color: $silver-600;
    font-size: $font-size-20;

    &:hover {
      color: $red-600;
    }
  }
}
