@import '~@zf/stella-react/variables.scss';

.multivalue-item {
  display: flex;
  flex-direction: column;
  padding: $spacing-16 $spacing-24;
  background-color: $silver-50;
  border-radius: $border-radius-8;
}

.actions {
  display: flex;
  gap: $spacing-12;
  justify-content: flex-end;
}

.with-border {
  border: $border-one $silver-400;
}
