@import '~@zf/stella-react/variables.scss';

.detail-line {
  display: flex;
  gap: $spacing-16;
  align-items: center;
  line-height: $line-height-24;
}

.detail-line > p:first-of-type {
  font-weight: $font-weight-bold;
  color: $silver-600;
}

.detail-line > p:nth-of-type(2) {
  text-align: right;
}

.disabled {
  pointer-events: none;
}

.disabled * {
  opacity: 25% !important;
  pointer-events: none !important;
}
