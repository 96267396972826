@import '~@zf/stella-react/variables.scss';

.header {
  margin-left: 0rem !important;
  display: flex;
  flex-direction: row;
}

.header * {
  color: $red-600;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
}

.active-case {
  margin-left: 0 $spacing-8;
  display: flex;
  align-items: center;
  line-height: $line-height-32;
}

.active-case > a {
  margin-left: $spacing-8;
  font-style: italic;
}
