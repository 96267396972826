@import '~@zf/stella-react/variables.scss';

.label {
  display: flex;
  align-items: center;
  gap: $spacing-4;
  font-family: $font-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-16 !important;
  line-height: $line-height-24;
  margin: auto 0rem;
  color: $silver-600;

  &.hidden-label {
    display: none;
  }
}

.text-bold {
  font-weight: $font-weight-bold;
}

.label-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.label-tooltip-wrapper {
  padding: $spacing-16;
}
