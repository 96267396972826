@import '~@zf/stella-react/variables.scss';

.meter-completeness {
  display: flex;
  padding: $spacing-16;
}

.meter-completeness:nth-child(even) {
  background-color: $silver-50;
  border-radius: $border-radius-8;
}

.meter-completeness > div:first-of-type {
  height: fit-content;
}

.dates-and-msmts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: auto;
}

.dates-and-msmts > div:last-of-type {
  margin-top: 1rem;
}

.date-msmt {
  display: grid;
  grid-template-columns: 10rem auto;
  white-space: nowrap;
  gap: 2rem;
}

.date-msmt > p:first-of-type {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.date-msmt > p:last-of-type {
  text-align: right;
}

.date-msmt li {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
