@import '~@zf/stella-react/variables.scss';

.card-footer {
  grid-area: card-footer;

  grid-template-columns: auto;
  grid-template-rows: 5.5rem;

  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}
