@import '~@zf/stella-react/variables.scss';

.breadcrumb {
  display: flex;
  align-items: center;
  padding: $spacing-8;
  gap: $spacing-24;
  color: $silver-600;
  z-index: 51;
}
