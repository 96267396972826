@import '~@zf/stella-react/variables.scss';

.tariffs {
  margin-top: $spacing-16;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.no-tariffs-yet {
  padding-left: 2rem;
}

.add-tariff {
  position: relative;
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.add-tariff > button {
  margin-left: auto;
}

.add-tariff h3 {
  margin-bottom: 2rem;
}

.add-tariff span {
  font-size: $font-size-16 !important;
}
