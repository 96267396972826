@import '~@zf/stella-react/variables.scss';

.right-body {
  height: 100%;
  padding: $spacing-24;
  overflow-y: auto;
  position: relative;
}

.collapsable > div:nth-of-type(2) {
  padding: 0rem 2rem;
}

.font-size-medium {
  font-size: $font-size-20;
}

.generate-advance-button {
  display: flex;
  justify-content: flex-end;
}

.billing-items {
  margin-top: 2rem;
}

.estimated-invoice-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-24;
}

.right-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cost-type-title {
  font-size: $font-size-16;
  font-weight: $font-weight-extra-bold;
  line-height: $line-height-32;
  text-transform: uppercase;
  color: $silver-600;
  letter-spacing: 0.08rem;
}
