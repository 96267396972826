@import '~@zf/stella-react/variables.scss';
.wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
}

.action-btn {
  margin-right: 1rem;
}

.calculation-output-card {
  margin-top: 2rem;
  height: 65vh;
}

.filters {
  display: flex;
  flex-direction: row;
}

.range-picker {
  margin-right: $spacing-16;
}

.loading-card {
  height: 65vh;
}

.loading-card > div {
  height: 65vh;
}

.loading-wrapper {
  margin-top: 25vh;
}

.loading {
  font-size: 0.52rem !important;
}
