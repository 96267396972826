.property-error-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  row-gap: 2rem;
}

.property-label {
  margin-top: 0;
}

.errors {
  text-align: left;
}
