@import '~@zf/stella-react/variables.scss';

.heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
}

.heading-info-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.with-info {
  width: fit-content;
}

h1 {
  font-size: $font-size-31;
  font-weight: $font-weight-extra-bold;
  color: $graphite;
}

h2 {
  font-size: $font-size-25;
  line-height: $line-height-40;
  color: $graphite;
}

h3 {
  font-size: $font-size-20;
  line-height: $line-height-32;
  font-weight: $font-weight-bold !important;
  color: $graphite;
}

h4 {
  font-size: $font-size-16;
  line-height: $line-height-24;
  color: $graphite;
}

h5 {
  font-size: $font-size-16;
  color: $silver-600;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-default;
  margin: 0;
  white-space: nowrap;

  &.regular {
    font-weight: $font-weight-regular;
  }

  &.light {
    font-weight: $font-weight-regular;
  }

  &.semi-bold {
    font-weight: $font-weight-regular;
  }
  &.bold {
    font-weight: $font-weight-bold;
  }
}

.info {
  height: fit-content;
  margin-left: 1rem;
}

.heading-info-wrapper-paragraph {
  padding: 1rem;
}
