@import '~@zf/stella-react/variables.scss';

.collection-sending-details {
  padding: 2rem;
  display: flex;
  white-space: nowrap;
  gap: 0.5rem;
  width: min-content;
}

.collection-sending-details > a {
  font-weight: $font-weight-bold !important;
}
