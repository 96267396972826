@import '~@zf/stella-react/variables.scss';

.wrapper {
  height: 72rem;
  width: 80vw;
  margin-top:$spacing-32;
  padding: 2rem 5rem 2rem 5rem;
  background-color: $silver-50;
  display: grid;
  grid-template-columns: 3.4fr 1fr;
  border-radius: $border-radius-4;
  gap: 2rem;
}

.with-top-inputs {
  grid-template-rows: 8rem auto;
}

.items {
  position: relative;
}

.items-grid {
  height: 68rem;
  margin-top: 1rem;
  overflow-y: auto;
}

.items-grid-with-top-inputs {
  height: 58rem;
}

.items h2 {
  white-space: nowrap;
  text-align: left;
}

.items-list {
  padding: 2rem;
  text-align: left;
  border-left: 0.1rem solid $silver-200;
}

.items-list h3 {
  color: $silver-700;
}

.items-list p {
  line-height: $line-height-24;
  padding-left: 2rem;
}

.items-filter {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0.5rem;
  right: 0rem;
}

.items-filter * {
  margin: auto;
  margin-right: 0rem;
}

.items-header {
  height: 5rem;
  display: flex;
  flex-direction: row;
}

.items-list-entry {
  display: flex;
  flex-direction: row;
}

.items-list-entry span {
  margin-left: 0.5rem;
}

.placeholder-div {
  width: 3.5rem;
}
