@import '~@zf/stella-react/variables.scss';

.subtext {
  font-family: $font-default;
  font-size: $font-size-12;
  line-height: $line-height-20;
  color: $silver-600;
  text-align: left;
  margin: 0rem;
}

.subtext ul {
  margin: 0rem;
  padding-left: $spacing-24;
}

.text-bold {
  font-weight: $font-weight-bold;
}
