@import '~@zf/stella-react/variables.scss';

.wrapper {
  height: 70vh;
  margin-top: 4rem;
  padding: 0rem 4rem 0rem 4rem;

  display: flex;
  flex-direction: column;
  gap: $spacing-24;
}

.intro {
  width: 66%;
  margin-left: 0rem;
}

.add-btn {
  margin: 0rem 4rem 0rem 4rem;
}
