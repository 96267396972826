@import '~@zf/stella-react/variables.scss';

.permission-multi-value {
  margin-top: $spacing-40;
}

.permission-value {
  display: flex;
}

.permission-value > * {
  padding: 1rem;
}

.input {
  background-color: $silver-200;
}
