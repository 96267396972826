@import '~@zf/stella-react/variables.scss';

.tree-item {
  margin: 0;
  font-weight: $font-weight-regular;
  color: $silver-600;
  cursor: pointer;
}

.tree-item:hover {
  color: $blue-600;
}
