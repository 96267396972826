@import '~@zf/stella-react/variables.scss';

.setting-description {
  display: flex;
  justify-content: space-between;
  gap: $spacing-32;
  line-height: $font-size-25;
  padding: $spacing-8 0rem;
}

.setting-description > p {
  width: 100%;
}

.setting-description > div:last-of-type,
.setting-description > button {
  display: flex;
  align-items: center;
  max-width: 50%;
}

.sub {
  border-left: 0.2rem solid $silver-400;
  padding-left: $spacing-16;
}

.double-sub {
  margin-left: $spacing-16;
}

.invert {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
