@import '~@zf/stella-react/variables.scss';

.translations-grid {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}

.field-translations {
  margin-top: 2rem;
  padding: 0rem $spacing-8 0rem $spacing-8;
  border-radius: $border-radius-16;
}

.field-translations span {
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
}

.no-fields {
  white-space: nowrap;
}

.translation-wrapper-input-label {
  margin-bottom: 4rem;
}

.default-translation {
  margin-bottom: 2rem;
  text-align: left;
}

.translation-label {
  margin-left: 20rem;
}

.language-header {
  display: flex;
}

.translation-options {
  display: flex;
  justify-content: space-between;
}
