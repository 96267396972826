@import '~@zf/stella-react/variables.scss';

.filter-section {
  width: 100%;
  display: flex;
  align-items: center;
}

.filter-section > div:first-of-type {
  width: fit-content;
  max-width: 25%;
  margin-right: auto;
}

.filters {
  display: flex;
  align-items: center;
  gap: $spacing-16;
}
