@import '~@zf/stella-react/variables.scss';

.prod-config {
  grid-template-columns: 1fr 1fr 1fr;
}

.two-column {
  grid-template-columns: 1fr 2fr;
}

.hidden {
  display: none;
}
