@import '~@zf/stella-react/variables.scss';

.cardbody {
  grid-area: card-body;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  &.max-height {
    max-height: 40rem;
  }

  &.fixed-height {
    height: 40rem;
  }

  &.cardbody-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;
    padding: 2rem;
    grid-auto-rows: max-content;
  }

  &.cardbody-table {
    overflow-y: auto;
    overflow-x: auto;
  }

  &.cardbody-index-table {
    padding: 0.5rem 0 0 0;
    overflow: hidden !important;
  }

  &.detail-line {
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
    padding: $spacing-16 $spacing-24;
  }
}

.error {
  border: 0.1rem solid $red-600;
}
