@import '~@zf/stella-react/variables.scss';

.invoice-moment {
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
}

.divider {
  font-size: $font-size-16;
  margin: auto;
}

.icon {
  margin-right: 0.5rem;
}

.location-dropdown {
  margin-top: $spacing-32;
}
