@import '~@zf/stella-react/variables.scss';

.inputs h3 {
  text-align: left;
}

.inputs h3:not(:first-child) {
  padding-top:$spacing-32;
}

.two-column {
  display: flex;
  gap: $spacing-24;
  text-align: left;
}
