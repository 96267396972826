@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.wrapper div {
  background-color: rgba(255, 255, 255, 0.65);
  grid-row-start: 1;
  grid-column-start: 1;
}

.overlay {
  z-index: $disabled-z-index;
}
