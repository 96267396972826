@import '~@zf/stella-react/variables.scss';

.measurement {
  padding: 2rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: #f9fbfc;
  border-radius: $border-radius-4;
}

.measurement > div {
  padding-bottom: 0rem;
  padding-left: 0rem;
}
