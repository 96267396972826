.translation-wrapper-input-label {
  margin-bottom: 4rem;
}

.culture-flag {
  display: flex;
  margin-bottom: 1rem;
}

.translation-label {
  margin-left: 1rem;
}

.reset-translation {
  display: flex;
  justify-content: center;
  width: 100%;
}

.reset-translation {
  margin-top: 2rem;
}
