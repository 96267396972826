@import '~@zf/stella-react/variables.scss';

.output-channel-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  font-size: $font-size-16;
}

.output-channel-wrapper > button:hover {
  color: $blue-600;
}

.output-channel-wrapper span {
  font-size: $font-size-16;
}

.delete-icon {
  position: absolute;
  right: 0rem;
}

.selected {
  color: $blue-600 !important;
}

.error {
  color: $red-600;
}
