$silver-600: #8c98ab;
$silver-700: #6d7c93;
$silver-400: #bfcbdc;
$silver-200: #e8eef4;
$silver-50: #f2f8fa;

$blue-600: #1477ff;
$blue-700: #0058d1;
$blue-400: #71adff;
$blue-200: #d5e6ff;
$blue-50: #f3f7fe;

$green-600: #33c85d;
$green-700: #29a34b;
$green-400: #7adf97;
$green-200: #d5f6de;
$green-50: #f2fcf5;

$yellow-600: #f8c206;
$yellow-700: #d1ac28;
$yellow-400: #fbe7a2;
$yellow-200: #fff6d6;
$yellow-50: #fffbef;

$red-600: #fd4949;
$red-700: #c72929;
$red-400: #ff8080;
$red-200: #ffe3e3;
$red-50: #fef7f7;

$orange-600: #ff993c;
$orange-700: #d87f2d;
$orange-400: #ffbd81;
$orange-200: #ffe8d3;
$orange-50: #fff8f1;

$white-200: #ffffff;
$graphite: #32333a;

$font-size-61: 6.1rem;
$font-size-48: 4.88rem;
$font-size-39: 3.9rem;
$font-size-31: 3.125rem;
$font-size-25: 2.5rem;
$font-size-20: 2rem;
$font-size-16: 1.6rem;
$font-size-12: 1.28rem;
$font-size-10: 1.024rem;

$font-default: 'Mulish';
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$line-height-16: 1.6rem;
$line-height-20: 2rem;
$line-height-24: 2.4rem;
$line-height-32: 3.2rem;
$line-height-40: 4rem;
$line-height-48: 4.8rem;

$border-radius-2: 0.2rem;
$border-radius-4: 0.4rem;
$border-radius-8: 0.8rem;
$border-radius-16: 1.6rem;

$spacing-1: 0.1rem;
$spacing-2: 0.2rem;
$spacing-4: 0.4rem;
$spacing-8: 0.8rem;
$spacing-12: 1.2rem;
$spacing-16: 1.6rem;
$spacing-24: 2.4rem;
$spacing-32: 3.2rem;
$spacing-40: 4rem;
$spacing-48: 4.8rem;
$spacing-56: 5.6rem;

$large-box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.06), 0 1.2rem 2.4rem rgba(109, 124, 147, 0.12);
$medium-box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.04), 0 0.6rem 1.2rem rgba(109, 124, 147, 0.1);
$small-box-shadow: 0 0.2rem 4px rgba(0, 0, 0, 0.04), 0 0.4rem 0.8rem rgba(0, 0, 0, 0.06);

$input-popup-z-index: 99000;
$highest-z-index: 2147483647;
$popup-z-index: 2147483646;
$stacked-first-z-index: 2147482998;
$stacked-second-z-index: 2147482999;
$disabled-z-index: 2147483644;
$overlay-z-index: 98000;

$border-one: 0.1rem solid;
$border-two: 0.2rem solid;

$tab-body-height: calc(100vh - 8rem - 14rem - 2.5vh);
