@import '~@zf/stella-react/variables.scss';

.change-payment-method-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-to-transaction-checkbox {
  width: fit-content;
}

.assign-section {
  width: 70rem;
  padding-top: 4rem;
  border-radius: $border-radius-4;
  border: 0.1rem solid $silver-200;
}
