.tag {
  height: 2.8rem;
}

.tag span {
  margin-top: 0.2rem;
}

.tag * {
  height: fit-content !important;
}
