@import '~@zf/stella-react/variables.scss';

.muted-rule-box {
  display: block;
  border: 1px solid $silver-200;
  border-radius: $border-radius-4;
  padding: 0.5rem 1rem;
}

.text-part {
  background-color: salmon !important;
}

.show-issues-link {
  justify-content: flex-end;
  text-align: right !important;
  color: $silver-600;
  font-weight: bold;
}

.link-container {
  display: flex;
  text-align: end;
  justify-content: flex-end;
  padding-bottom: 1rem !important;
  margin-left: auto;
  list-style: none;
}

.date-container {
  display: flex;
  text-align: end;
  justify-content: flex-end;
  margin-left: auto;
}

.toggle-expired-rules-btn {
  width: fit-content;
  padding-right: 0;
}
