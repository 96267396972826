@import '~@zf/stella-react/variables.scss';

.error-container {
  display: flex;
  font-family: $font-default;
  padding-left: 0.4rem;

  .error-exclamation {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-20;
    padding: 1rem $spacing-16 1rem 0rem;
    width: 2rem;

    .error-inner-circle {
      display: flex;
      justify-content: center;
      align-items: center;

      width: $spacing-16;
      height: $spacing-16;
      border-radius: $border-radius-8;
      z-index: 1002;

      color: $white-200;
      font-family: $font-default;
      font-size: $font-size-10;
    }

    .error-inner-circle span {
      font-size: $font-size-10;
    }

    .error-outer-circle {
      position: absolute;
      width:$spacing-32;
      height:$spacing-32;
      border-radius: $border-radius-16;
      z-index: 1001;
      background-color: rgba($red-600, 0.2);
      animation: notification-pulse 3s infinite;
      animation-timing-function: linear;
    }
  }

  .error-content {
    .error-header {
      display: block;
      font-size: $font-size-16;
      //font-weight: $font-weight-bold;
    }

    .error-description {
      display: block;
      font-size: $font-size-16;
      font-weight: $font-weight-regular;
      color: $silver-700;
    }
  }

  .error-content-center {
    width: fit-content;
    white-space: nowrap;
    margin: auto;
    margin-left: 0rem;
  }

  .tooltip p {
    max-width: 20rem !important;
  }
}

@keyframes notification-pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(0.5, 0.5, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.tooltip-text {
  padding: 1rem;
  max-width: 30rem;
  line-height: $line-height-16;
}
