@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
}

.action-btn {
  margin-right: 1rem;
}

.formula-builder-wrapper {
  display: grid;
  grid-template-rows: min-content auto;
}

.formula-toggle {
  margin-top: $spacing-16;
}
