@import '~@zf/stella-react/variables.scss';

.filter-bar {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-16;
  border: $border-one $silver-200;
  border-radius: $border-radius-8;
  background-color: $white-200;
  padding: $spacing-16;
  margin-top: $spacing-24;
  margin-bottom: $spacing-8;

  div {
    display: flex;
    align-items: center;
  }
}
