@import '~@zf/stella-react/variables.scss';

.icon-label {
  display: flex;
  gap: $spacing-8;
  align-items: center;
  font-size: $font-size-16;
}

.icon {
  font-size: $font-size-16;
  margin: auto;
  width: fit-content;
  height: fit-content;
  line-height: $line-height-16;
  color: inherit;
}

.label {
  line-height: $line-height-16;
  color: $silver-600;
  text-align: left;
  white-space: nowrap;
}
