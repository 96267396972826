@import '~@zf/stella-react/variables.scss';

.primary-contact-details {
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
}

.multivalue-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding-top: $spacing-8;
  gap: $spacing-16;
}

.icon-input {
  display: grid;
  grid-template-columns: 2rem 1fr;
  gap: 2rem;
}

.icon-input span {
  margin: auto;
  color: $silver-700;
  font-size: $font-size-16;
}

.description {
  margin-left: 4rem;
  margin-top: 1rem;
}

.description-input {
  margin: 2rem;
}

.check-wrapper {
  display: flex;
}

.check-wrapper div:nth-of-type(2) {
  padding-left: 1rem;
}

.optional-description {
  padding: 2rem 0rem 2rem 4rem;
}

.deviating-language {
  margin-top: 4rem;
}
