@import '~@zf/stella-react/variables.scss';

.wrapper1 {
  position: relative;
  margin-top: $spacing-16;
}

.wrapper2 {
  display: flex;
  flex-direction: row;
}

.wrapper2 span:nth-child(2) {
  color: $silver-600;
  font-size: $font-size-31;
  font-weight: $font-weight-bold;
  margin: auto;
  margin-left: 2rem;
}

.header {
  font-size: $font-size-16 !important;
  display: flex;
  align-items: center;
}

.header-and-info {
  display: flex;
  margin-right: 1rem;
}

.actions {
  margin-left: auto;
  display: flex;
}

.info {
  margin-left: 1rem !important;
}

.collection-flow-step {
  padding: 2rem;
  border: $border-one $silver-200;
  border-radius: $border-radius-4;
  box-sizing: border-box;
  background-color: $white-200;
}

.cost-communication {
  margin-top: 1rem;
  display: grid;
  grid-auto-rows: min-content;
}

.info-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.info-row p {
  text-align: right;
}

.actions span {
  font-size: $font-size-16;
}

.step-placeholder {
  margin-top: 2rem;
}
