.image {
  width: 20rem;
}

.select-logo {
  display: flex;
}

.select-logo > div:nth-of-type(2) {
  margin-left: 1.7rem;
}
