@import '~@zf/stella-react/variables.scss';

.list-divider {
  height: 0.1rem;
  width: 100%;
  margin: $spacing-8 0rem;
  background-color: $silver-200;
}

.no-margin {
  margin: 0rem;
}
