@import '~@zf/stella-react/variables.scss';

.card-body {
  padding: 2rem;
}

.card-body h3 {
  margin-bottom: $spacing-16;
}

.section {
  margin-bottom:$spacing-32;
}

.dialog-wrapper {
  text-align: left;
}

.description {
  padding: $spacing-8;
}
