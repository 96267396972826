@import '~@zf/stella-react/variables.scss';

.comment-input {
  display: flex;
  align-items: center;
  margin-left: $spacing-12;
  margin-right: $spacing-12;
  color: $silver-400;
}

.comment-icon {
  margin-right: $spacing-12;
  color: $silver-600;
  font-size: $font-size-25;
}

.comment-card-footer {
  padding: $spacing-32 $spacing-12 $spacing-12 $spacing-12;
}

.delete-action {
  outline: none;
  cursor: pointer;
  font-size: $font-size-16;

  &:hover,
  &:focus {
    box-shadow: $large-box-shadow;
  }
}

.comment-input-field {
  width: 100%;
}
