.map {
  width: 100%;
  height: 100%;
}

.marker-icon {
  height: 10rem;
  width: 10rem;
}

.map-card-body {
  min-height: 40rem;
  overflow: hidden;
}

.map-card-body * {
  outline: none !important;
}
