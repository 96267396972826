@import '~@zf/stella-react/variables.scss';

.list {
  ul {
    margin: 0rem;
  }

  ul li {
    list-style: disc;
  }
}
