@import '~@zf/stella-react/variables.scss';

.inputs {
  padding: 2rem;
}

.input-entry {
  margin-bottom: 2rem;
}

.inputs > div:nth-of-type(2) {
  width: 75%;
}

.inputs > div:nth-of-type(3) {
  width: 75%;
}

.mandate-and-history {
  display: flex;
}

.mandate-and-history > button {
  margin-left: auto;
}
