@import '~@zf/stella-react/variables.scss';

.popup {
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: 1rem;
  border-radius: 0;

  &.enter {
    opacity: 1;
    margin-top: 0;
  }

  &.exit {
    opacity: 0;
    margin-top: 0;
  }
}
