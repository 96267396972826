@import '~@zf/stella-react/variables.scss';

.spacer {
  width: $spacing-24;
}

.culture-default-container {
  display: flex;
  width: fit-content;
  gap: $spacing-8;
}
