@import '~@zf/stella-react/variables.scss';

.tree {
  display: flex;
  flex-direction: column;
}

.tree > div {
  border-radius: $border-radius-4;
  overflow: hidden;
}
