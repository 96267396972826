@import '~@zf/stella-react/variables.scss';

.autogenerated-bank-account {
  height: 5rem;
  background-color: $blue-50;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
}

.autogenerated-bank-account > div:last-of-type {
  display: flex;
  gap: 2rem;
  margin-left: auto;
}
