@import '~@zf/stella-react/variables.scss';

.label-item-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: max-content;
  padding: 0rem 2rem 0rem 2rem;
}

.with-padding {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.label-item-grid > div > span {
  text-align: left !important;
}

.label-item-grid > div > p {
  text-align: right !important;
}
