.channel-table {
  height: 30rem;
  overflow-y: hidden;
}

.issues-link {
  text-decoration: none !important;
}

.tooltip-content {
  font-weight: bold !important;
}
