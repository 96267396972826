@import '~@zf/stella-react/variables.scss';

.cards-container {
  position: relative;
  $gap: $spacing-32;

  display: grid;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-right: $spacing-8;
  padding-bottom: $spacing-40;
  grid-gap: $gap;
  grid-template-columns: repeat(4, auto);
  grid-auto-flow: dense;
}
