@import '~@zf/stella-react/variables.scss';

.loader-wrapper {
  padding: 1rem;
}

.loading-wrapper {
  min-height: 6rem;
}

.loading-text {
  text-align: center;
}

.loader,
.loader:before,
.loader:after {
  background: $silver-700;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: $silver-700;
  text-indent: -9999em;
  margin: 2rem auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 4em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 4em;
  }
}
