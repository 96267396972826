@import '~@zf/stella-react/variables.scss';

.details-grid {
  font-size: $font-size-16;
  margin-top: 2rem;
  display: grid;
  gap: 2rem;
  max-height: 40rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.details-grid :first-child p {
  font-weight: $font-weight-regular;
  padding-left: 2.5rem;
}

.details-grid p {
  font-weight: $font-weight-bold;
}

.details-grid span {
  color: $silver-700;
}

.details-grid * {
  outline: none !important;
}
