@import '~@zf/stella-react/variables.scss';

.wizard-container {
  height: inherit;
  box-sizing: border-box;
  padding: $spacing-24;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.wizard-container > div:first-of-type {
  max-width: 140rem;
}

.wizard-container > div:last-of-type {
  width: 100%;
  max-width: 35rem;
}
