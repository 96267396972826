@import '~@zf/stella-react/variables.scss';

.billing-item {
}

.header {
  display: flex;
  max-width: 90%;
}

.lang-icon-wrapper p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.header h3 {
  color: $graphite;
}

.header p {
  color: $silver-600;
  font-size: $font-size-16;
  text-align: left;
}

.header img {
  width: 4.5rem;
  height: 4.5rem;
}

.header > div {
  margin-left: 2rem;
}

.name-lang-icon {
  display: flex;
  align-items: center;
  gap: $spacing-8;
  width: fit-content;
}

.collapse-btn {
  position: absolute;
  top: 2rem;
  right: 2.3rem;
}

.collapse-btn span {
  font-size: $font-size-20;
}
