@import '~@zf/stella-react/variables.scss';

.dropzone {
  display: flex;
  gap: $spacing-24;
  padding: $spacing-24;
  border-radius: $border-radius-8;
  color: $silver-600;
  font-family: $font-default;
  outline: none;
  text-align: center;
  align-items: center;
  justify-content: center;

  &:focus,
  &:hover,
  &.dropzone-dragged {
    border-color: $graphite;
  }

  &.dropzone-dragged {
    background-color: $silver-50;
  }

  .dropzone-heading {
    font-family: $font-default;
    font-weight: $font-weight-regular;
  }
}

.dropzone {
  font-size: $font-size-16;
}

.dropzone h3 {
  color: $silver-600;
}

.dropzone span {
  color: $silver-600;
  font-size: $font-size-39;
}

.dropzone:hover * {
  color: $silver-700 !important;
}

.error {
  border-color: $red-600;
}

.text {
  white-space: nowrap;
}

.content {
  font-size: $font-size-12;
  text-align: left;
}
