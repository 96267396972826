@import '~@zf/stella-react/variables.scss';

.header-section {
  display: flex;
  flex: 0 1 auto;
  gap: $spacing-40;
  padding: $spacing-16 0rem;
  margin: 0rem $spacing-24;
  border-bottom: 0.2rem solid $silver-200;
}
