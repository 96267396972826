.card {
  height: 73vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: inherit;
}

.empty-body {
  flex: 1 1 auto;
}
