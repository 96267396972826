@import '~@zf/stella-react/variables.scss';

.details-wrapper {
  min-width: 70rem;
}

.enum-blocks {
  padding: 0rem $spacing-24;
}

.enum-block {
  margin-top: $spacing-16;
}

.enum-block > p {
  text-align: left;
  margin-bottom: $spacing-16;
}

.enum-block > div {
  margin-bottom: $spacing-8;
}

.divider {
  margin-top: $spacing-16;
}

.balance-date {
  display: flex;
  white-space: nowrap;
  margin-left: auto;
  align-items: center;
  justify-content: center;
}

.balance-date > p:last-of-type {
  margin-left: $spacing-4;
  color: $silver-600;
  font-size: $font-size-16;
}
