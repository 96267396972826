@import '~@zf/stella-react/variables.scss';

.wizard-step {
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  font-size: $font-size-16;
  font-weight: $font-weight-extra-bold;
  line-height: $line-height-32;
  text-transform: uppercase;
  color: $silver-600;
  letter-spacing: 0.08rem;
  text-decoration: none;
  padding: $spacing-8 $spacing-16;
  background-color: $white-200;
  border-radius: $border-radius-4;
}

.wizard-step:hover {
  color: $silver-700;
  box-shadow: $small-box-shadow;
}

.active {
  color: $graphite;
  border: $border-one $blue-600;
}

.errors {
  margin: 0;
  padding: 0 $spacing-32;
  font-size: $font-size-16;
  line-height: $line-height-24;
  color: $red-600;
}
