@import '~@zf/stella-react/variables.scss';

.formula-builder-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  height: 60vh;
  overflow: hidden;
}

.formulas-div {
  background-color: $white-200;
  padding: 2rem;
  padding-left:$spacing-32;
  border-radius: $border-radius-4;
  height: 55vh;

  -webkit-box-shadow: 0px 0.2rem 1rem 0.2rem $silver-200;
  -moz-box-shadow: 0px 0.2rem 1rem 0.2rem $silver-200;
  box-shadow: 0px 0.2rem 1rem 0.2rem $silver-200;
}

.formulas-div h2 {
  margin-bottom: 2rem;
}

.formula-trees {
  margin-left: 1rem;
}

.formula {
  margin-right: 4rem;
  margin-bottom: 4rem;
}

.header {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid;
  border-bottom: none;
  border-radius: $border-radius-4 $border-radius-4 0rem 0rem;
  font-size: $font-size-16;
}

.header > *:first-of-type {
  margin-right: auto;
}

.header > *:last-of-type {
  margin-left: auto;
}

.header p {
  text-align: center;
}

.error {
  border: 0.2rem solid $red-600 !important;
}

.parameters {
  border: 0.2rem solid;
  border-top: none;
  border-bottom: none;
}

.output {
  display: flex;
  padding: 2rem;
  border: 0.2rem solid;
  border-top: none;
  border-radius: 0rem 0rem $border-radius-4 $border-radius-4;
  font-size: $font-size-16;
}

.output span {
  white-space: nowrap;
}

.output > div {
  margin-left: 4rem;
}

.empty-formula-wrapper {
  position: relative;
  height: 60vh;
}

.formula-functions-list {
  overflow-y: auto;
}

.function {
  margin-bottom: 0.75rem;
}
