@import '~@zf/stella-react/variables.scss';

.blocked-icon {
  font-size: $font-size-48;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.contract-details-card-container {
  height: 100%;
}
