@import '~@zf/stella-react/variables.scss';

.preview-wrapper {
  display: flex;
  justify-content: center;
  flex: 2;
  box-sizing: border-box;
  border: $border-one $silver-200;
  border-radius: $border-radius-8;
}

.preview-wrapper > embed {
  display: flex;
  flex: 2;
  border-radius: $border-radius-8;
}
