.container {
  padding: '0rem 4rem 0rem 4rem';
}

.checkbox {
  height: fit-content;
  margin: auto;
}

.advance-until {
  width: 24rem;
}
