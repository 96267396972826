@import '~@zf/stella-react/variables.scss';

.info > * {
  text-align: left;
}

.width-limited {
  max-width: 66%;
}

.info > p:last-of-type {
  color: $silver-600;
  font-size: $font-size-16;

}

.info-wrapper {
  display: flex;
  gap: $spacing-8;
  width: fit-content;
  align-items: center;
}

.info-wrapper > p:first-of-type {
  font-weight: $font-weight-bold;
}

.info-wrapper-title {
  width: fit-content;
  white-space: nowrap;
}

.mandatory-indicator {
  color: $red-600;
  font-size: $font-size-16;
}
