@import '~@zf/stella-react/variables.scss';

.listpage-wrapper {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.list-page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc($tab-body-height + 10rem);
  width: 100%;
  border-radius: $border-radius-4;
  padding: $spacing-48;
  padding-bottom: 0;

  .top-bar {
    width: 100%;
  }

  .list {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100vh;
    gap: $spacing-24;
    overflow: auto;

    .table {
      display: flex;
      flex-direction: column;
      max-height: 88%;
      width: 60%;
    }

    .table-50 {
      display: flex;
      flex-direction: column;
      max-height: 88%;
      width: 50%;
    }

    .table-full-width {
      width: 100%;
    }

    .side-panel-50 {
      box-sizing: border-box;
      width: 50%;
      overflow: auto;
    }
  }
}

.embedded-in-detail-page {
  padding: 0;
  height: calc($tab-body-height - 4rem); // Subtract filter bar height & padding tab container
  // max-height: 122rem; //122rem
}
