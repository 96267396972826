@import '~@zf/stella-react/variables.scss';

.closed-dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.closed-dropdown,
.closed-dropdown > span {
  color: $silver-600;
  font-weight: $font-weight-bold;
  white-space: nowrap;
}
