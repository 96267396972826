@import '~@zf/stella-react/variables.scss';

.results-counter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: $spacing-8 $spacing-16;
}

.results-counter p {
  color: $silver-600;
  font-weight: $font-weight-bold;
}

.darker {
  color: $silver-700 !important;
}

.paragraphs {
  display: grid;
  grid-template-columns: min-content min-content min-content;
  gap: 0.5rem;
}

.align-right {
  text-align: right;
}
