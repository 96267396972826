@import '~@zf/stella-react/variables.scss';

.graph {
  font-size: $font-size-16;
}

.import-jobs-pie-chart-card {
  min-height: 40rem;
}

.import-job-detail-card {
  height: 100%;
}

.import-job-values-card {
  min-height: 40rem;
}
