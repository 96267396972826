@import '~@zf/stella-react/variables.scss';

@keyframes moveIn {
  from {
    transform: translateX(-20rem);
  }
  to {
    transform: translateX(0rem);
  }
}

.side-bar-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
}

.side-bar {
  width: 10rem;
  min-width: 10rem;
  height: calc(100vh - 8rem);
  background-color: white;
  border-right: $border-one $silver-200;
  color: $white-200;
  z-index: 2;
  overflow-y: auto;

  .side-bar-content {
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr 7rem 7rem;
    grid-template-columns: 1fr;
    grid-template-areas: 'nav' 'actions' 'footer';
    transition: all 0.2s ease;
    min-height: 100%;
    width: 100%;

    .side-bar-org {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      text-align: center;
      outline: none;
      padding-top: $spacing-24;

      &:hover,
      &:focus {
        .org-heading {
          color: $blue-400;
        }
      }

      .org-heading {
        width: 75%;
        margin-top: $spacing-16;
        color: $white-200;
        font-size: $font-size-12;
      }

      .org-divider {
        margin-top: $spacing-24;
        height: 0.1rem;
        width: 70%;
        background-color: $silver-400;
      }
    }

    .side-bar-logo {
      grid-area: logo;
      padding: 1rem 1rem 0 2rem;
    }

    .side-bar-nav {
      margin-top: 10rem;
      grid-area: nav;
    }

    .side-bar-actions {
      grid-area: actions;
    }

    .side-bar-footer {
      grid-area: footer;
      width: 100%;
      margin: auto;
    }
  }
}

.side-bar-config {
  text-align: left;
  animation: moveIn 0.3s ease-out;
  width: 30rem !important;
  height: calc(100vh - 8rem);
  overflow-y: auto;
  background-color: white;
  z-index: 1;
}

.config-nav-list {
  margin-top: 30rem;
}
