@import '~@zf/stella-react/variables.scss';

.info-grid {
  width: 100%;
  grid-column-end: span 4;
}

.card {
  height: 61vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.balance-date {
  display: flex;
  white-space: nowrap;
  margin-left: auto;
  align-items: center;
  justify-content: center;
}

.balance-date > p:last-of-type {
  margin-left: $spacing-4;
  color: $silver-600;
  font-size: $font-size-16;
}

.errors {
  margin-left: auto;
}

.file-name {
  margin-right: 0rem;
  white-space: normal;
  max-width: 75%;
}

.incoming-banking-transaction-card-container {
  height: 100%;
}

.matching-transaction-list-item {
  margin: $spacing-12 0;
}

.outgoing-bakking-transaction-cards-container {
  height: 100%;
}

.detail-switcher {
  margin: $spacing-32 0rem;
}

.account-error {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.account-error > p {
  white-space: nowrap;
}

.account-error > div:last-of-type {
  display: flex;
  align-items: center;
}
