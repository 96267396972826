@import '~@zf/stella-react/variables.scss';

.service-header {
  display: flex;
  flex-direction: row;
  width: 100%;

  .icon {
    font-size: $font-size-31;
    flex: 1;
    margin-right: $spacing-4;
  }

  .content {
    flex: 10;
    display: flex;
    flex-direction: column;

    .name {
      font-size: $font-size-16;
      text-align: left;
      margin-bottom: $spacing-4;
    }

    .range {
      font-size: $font-size-12;
      color: $silver-600;
      text-align: left;
      margin-top: $spacing-4;
    }
  }

  .status {
    float: right;
  }
}

.contract-icon-p {
  width: fit-content;
  position: absolute;
  right: 0rem;
}

.link {
  width: fit-content;
  margin-right: 0rem;
}

.icon-utilitytype {
  font-size: $font-size-31;
}
