@import '~@zf/stella-react/variables.scss';

.timeline-wrapper {
  padding: 1rem 2rem 2rem 2rem;
}

.time-line-heading {
  padding: 2rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: row;
}

.time-line-heading p {
  color: $silver-600;
  margin-right: $spacing-16;
  white-space: nowrap;
  width: fit-content;
}
.h-line {
  margin: auto;
}
