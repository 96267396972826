@import '~@zf/stella-react/variables.scss';

.list-item {
  display: grid;
  margin: 0;
  height: 7rem;
  width: 100%;
}

.list-item > a {
  text-decoration: none;
}

.setting-list-item {
  margin-left: 2rem;
  padding: 0rem 2rem;
  height: 90%;
  user-select: none;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  color: $silver-700;

  &:hover {
    color: $blue-600;
    background-color: $silver-200;
    border-radius: $border-radius-8 0rem 0rem $border-radius-8;
  }
  &.active,
  &:focus {
    color: $blue-600;
    background-color: $silver-50;
    border-radius: $border-radius-8 0rem 0rem $border-radius-8;
  }

  &.active {
    font-weight: $font-weight-regular;
    color: $blue-600;
  }
}

.setting-list-item-text {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: $line-height-24;
}
