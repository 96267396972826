@import '~@zf/stella-react/variables.scss';

.list-item {
  padding: $spacing-8 $spacing-16;
  font-family: $font-default;
  font-size: $font-size-16;
  line-height: $line-height-24;
  font-weight: $font-weight-bold;
  color: $graphite;
  text-align: left;
  cursor: pointer;
}

.list-item:hover {
  color: $graphite;
}

.selected {
  color: $graphite;
  background-color: $silver-50;
}

.option-item {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: $font-weight-regular;
  font-size: $font-size-16;
}
