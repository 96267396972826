@import '~@zf/stella-react/variables.scss';

.custom-entity-property {
  margin-bottom: $spacing-16;
}

.custom-entity-property-dropdown {
  max-width: 30rem;
  margin-left: auto;
}
