@import '~@zf/stella-react/variables.scss';

.filter-bar {
  display: grid;
  grid-template-columns: min-content auto;
  gap: $spacing-8;
  margin-bottom: 1.6rem;
  align-items: center;
}

.list-page-header {
  margin-left: 0;
}

.main-grid {
  display: grid;
  grid-template-columns: auto min-content min-content;
  width: 100%;
  margin: 0rem auto;
  gap: $spacing-16;
}

.search-bar {
  width: 70rem;
  background-color: $silver-200;
}

.filter-icon {
  font-size: $spacing-24;
  color: $silver-600;
  cursor: pointer;
  padding: $spacing-4 $spacing-8;
  border-radius: $border-radius-4;
  transition: 0.1s ease-out;
}

.filter-icon:hover {
  cursor: pointer;
  background-color: #deecff;
  color: $blue-600;
}

.filter-icon-selected {
  font-size: $font-size-31;
  background-color: #deecff;
  color: $blue-600;
}

.filter-inputs {
  display: grid;
  gap: $spacing-4;
  width: min-content;
  padding: $spacing-8 $spacing-16;
  min-width: 30rem;
  margin: auto;
}

.clear-all {
  padding: $spacing-12 $spacing-24;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-bold;
  color: $silver-600;
  background-color: $silver-50;
  width: 100%;
  border-bottom-left-radius: $border-radius-8;
  border-bottom-right-radius: $border-radius-8;
}

.clear-all:hover {
  background-color: $silver-400;
  color: $graphite !important;
}

.search-actions {
  position: relative;
  display: flex;
  gap: 2rem;
}

.less-margin {
  margin-top: $spacing-16 !important;
}
