@import '~@zf/stella-react/variables.scss';

.wizard-wrapper {
  position: relative;
  height: 65vh;
  overflow: auto;
}

.wizard > div {
  position: absolute;
  width: 90%;
  top: 0rem;
  left: 0rem;
  padding: 2rem;
}

.header {
  display: flex;
}

.status-and-save-btn {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.status-and-save-btn > div:first-of-type {
  height: fit-content;
}

.overlay {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 65vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: $overlay-z-index;
}
