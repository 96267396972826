@import '~@zf/stella-react/variables.scss';

.table-wrapper {
  position: relative;
  height: 64vh;
  width: 100%;
}

.table-content {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow-y: hidden;
}
