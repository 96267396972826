@import '~@zf/stella-react/variables.scss';

.dashboard-container {
  height: 100%;
}

.revenue-chart-extra-right-paragraph {
  padding-top: $spacing-12;
  padding-right: $spacing-24;
}
