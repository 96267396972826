@import '~@zf/stella-react/variables.scss';

.toggle {
  display: flex;
  gap: $spacing-12;
  align-items: center;
}

.toggle > div:last-of-type {
  margin-top: $spacing-4;
}

.full-width {
  grid-column: 1 / 4;
}
