@import '~@zf/stella-react/variables.scss';

.action-description {
  display: flex;
  width: 100%;
  line-height: $font-size-25;
}

.action-description > p {
  width: 100%;
}

.action-description > div:last-of-type,
.action-description > button {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info > p:last-of-type {
  color: $silver-600;
  font-size: $font-size-16;
}
