@import '~@zf/stella-react/variables.scss';

.mandate {
  padding: $spacing-16;
  border-radius: $border-radius-4;
  border: 0.2rem solid $silver-200;
  margin-top: 2rem;
}

.mandate > div:first-of-type {
  margin-bottom: 2rem;
}

.mandate-top {
  display: flex;
  text-align: left;
}

.mandate-top > div:last-of-type {
  margin-left: auto;
}

.mandate-details {
  display: flex;
  gap: 30%;
}

.mandate-details > div > span {
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
}

.mandate-details > div > p {
  width: 100%;
  text-align: left;
}
