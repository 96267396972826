@import '~@zf/stella-react/variables.scss';

.paragraph {
  font-family: $font-default;
  font-weight: $font-weight-regular;
  font-size: $font-size-16;
  line-height: $line-height-24;
  color: $graphite;
  margin: auto;
  width: 100%;
  overflow-wrap: break-word;
  outline: none !important;
}

.deleted {
  color: $silver-600;
}

.text-bold {
  font-weight: $font-weight-bold;
}

.overflow * {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.info-wrapper {
  display: flex;
}

.info-wrapper p {
  margin-left: 0rem;
  margin-right: 1rem;
  width: fit-content;
}
