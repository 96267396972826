.sending-options {
  padding: 2rem;
  display: flex;
  gap: 3.5rem;
  justify-content: center;
  align-items: center;
}

.sending-options > div > div:first-of-type {
  margin-bottom: 0.5rem;
}
