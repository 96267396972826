@import '~@zf/stella-react/variables.scss';

.dropdown-action {
  list-style-type: none;
}

.dropdown-action > button,
.dropdown-action > div {
  background-color: transparent;
  color: $graphite;
  justify-content: start;
  padding: $spacing-8 $spacing-24;
}

.dropdown-action > button:hover,
.dropdown-action > div:hover {
  background-color: $silver-50;
}

.dropdown-action > button:active,
.dropdown-action > div:active {
  background-color: $blue-50;
}

.disabled > button {
  color: $silver-400;
}

.disabled > button:hover {
  color: $silver-400;
}

.dropdown-action > div > a {
  font-weight: $font-weight-bold;
}

.max-width > * {
  width: 100%;
}
