@import '~@zf/stella-react/variables.scss';

.supply-date-wrapper {
  margin-top: $spacing-16;
}

.invoice-period {
  max-width: 50%;
}

.custom-date {
  margin-top: $spacing-8;
  max-width: 50%;
}

.custom-date p {
  margin-top: $spacing-4;
}
