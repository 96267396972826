@import '~@zf/stella-react/variables.scss';

.icons {
  font-size: $font-size-16;
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 0.5rem;
  position: absolute;
  right: $spacing-8;
  top: $spacing-8;
  width: fit-content;
}
