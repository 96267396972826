@import '~@zf/stella-react/variables.scss';

.right-filter-bare-dropdowns {
  *:hover {
    font-weight: 700;
    color: $silver-700;
    background-color: $silver-50;
    border-radius: $border-radius-4;
  }
}
