@import '~@zf/stella-react/variables.scss';

.inputs {
  margin-top: 4rem;
  display: flex;
  gap: 7rem;
}

.inputs span {
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
}

.inputs p {
  line-height: $line-height-40;
}
