@import '~@zf/stella-react/variables.scss';

.collapsable-info-banner {
  padding: $spacing-8 $spacing-24;
  padding-right: 0rem;
  border: $border-one;
  border-radius: $border-radius-8;
}

.collapsable-info-banner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
