@import '~@zf/stella-react/variables.scss';

.filter-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: $spacing-12;
  gap: $spacing-8;
  flex-wrap: wrap;
  //background-color: saddlebrown;

  div {
    margin-top: 0rem !important;
  }
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.filter-bar {
  //border: 1px solid blue;
}

.main-grid {
  display: flex;
  justify-content: end;

  //border: 1px solid red;
}

.quick-filters {
  //background-color: aquamarine;
  //width: 100%;
}

.filter-dropdown-container {
  background-color: dodgerblue;
}

.right-actions {
  gap: $spacing-16;
  display: inline-flex;
}
