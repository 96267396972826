@import '~@zf/stella-react/variables.scss';

.side-panel {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: $spacing-16 $spacing-32;
  background-color: $white-200;
  border-radius: $border-radius-8;
  box-shadow: $small-box-shadow;
  box-sizing: border-box;
}
