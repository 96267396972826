@import '~@zf/stella-react/variables.scss';

.total {
  display: flex;
}

.total h3:last-of-type {
  margin-left: auto;
}

.estimation-calculation {
  display: flex;
}

.estimation-calculation div:last-of-type {
  margin-left: auto;
  text-align: right;
}

.accuracy-label {
  display: flex;
}

.billing-items {
  margin-top: $spacing-16;
  background-color: $silver-50;
  padding: $spacing-24;
  padding-bottom: $spacing-16;
  border-radius: $border-radius-8;
}

.billing-items > div:first-of-type {
  margin-bottom: $spacing-16;
}

.cost-type-title {
  width: fit-content;
  white-space: nowrap;
  font-size: $font-size-16;
  font-weight: $font-weight-extra-bold;
  line-height: $line-height-32;
  text-transform: uppercase;
  color: $silver-600;
  letter-spacing: 0.08rem;
}

.locations-block {
  margin-top: $spacing-32;
}

.address {
  color: $silver-600;
  margin-top: $spacing-24;
  margin-bottom: $spacing-12;
}
