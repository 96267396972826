@import '~@zf/stella-react/variables.scss';

.lang-icon-btn {
  color: $silver-600;
  font-size: $font-size-20;
  line-height: $line-height-32;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang-icon-btn:hover {
  color: $blue-400;
}
