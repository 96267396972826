@import '~@zf/stella-react/variables.scss';

.info-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $spacing-16 $spacing-24;
  box-sizing: border-box;
}

.info-banner > div {
  width: fit-content;
}

.rounded {
  border-radius: $border-radius-8;
}
