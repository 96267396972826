@import '~@zf/stella-react/variables.scss';

.date-range-picker-label {
  padding: $spacing-8 $spacing-16;
}

.period-filter {
  position: relative;
  display: grid;
  grid-template-columns: minmax(6rem, 1fr) $spacing-24 minmax(6rem, 1fr);
}

.label {
  padding: $spacing-8 0 0 $spacing-16;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: $line-height-24;
  color: $silver-700;
}

.date-range {
  display: flex;
  justify-content: space-between;
  padding: $spacing-16 $spacing-16;
  gap: $spacing-16;
}
