@import '~@zf/stella-react/variables.scss';

.wizard-column {
  min-width: 30vw;
  padding: 0rem;
  padding-left: 2rem;
}

.wizard-column-body {
  overflow: hidden;
  height: auto;
}
