@import '~@zf/stella-react/variables.scss';

.info-paragraph {
  margin-bottom: 6rem;
}

.two-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

.month-day {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: $spacing-16 1rem 1rem 1rem;
  margin-top: $spacing-16;
}

.btn {
  margin-top: 1rem;
  float: right;
}

.padding-top {
  padding-top: 4rem;
}
