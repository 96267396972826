@import '~@zf/stella-react/variables.scss';

.cards-container {
  position: relative;
  $gap: $spacing-32;

  display: grid;
  box-sizing: border-box;
  width: 100%;

  padding-right: $spacing-8;
  padding-bottom: $spacing-40;
  grid-gap: $gap;
  grid-template-columns: repeat(auto-fill, minmax(#{(50rem - $gap)}, 1fr));
  grid-auto-flow: dense;
}

.full-height {
  height: $tab-body-height;
}
