@import '~@zf/stella-react/variables.scss';

.actions {
  margin-top: $spacing-16;
  column-gap: 2.4rem !important;

  button {
    flex-grow: 1;
  }

  button:nth-child(3) {
    flex-grow: 0;
  }
}

.resolved button {
  flex-grow: 0;
}
