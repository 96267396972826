@import '~@zf/stella-react/variables.scss';

.active-period {
  display: flex;
  gap: $spacing-12;
  align-items: center;
}

.active-period > div > p {
  font-size: $font-size-12;
}

.active-period > span {
  margin-left: -0.7rem;
  width: fit-content;
  font-size: $font-size-20;
}
