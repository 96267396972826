@import '~@zf/stella-react/variables.scss';

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}

.filter-buttons-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}

.reference-div {
  position: relative;
  animation: rotation 2s infinite linear;
}

.refresh-icon {
  transition: all 0.2s ease-in-out;
}

.refresh:hover .refresh-icon {
  color: $silver-700;
}
.refresh:active .refresh-icon {
  animation: spin 0.5s ease;
}

.refresh {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $silver-600;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  position: absolute;
  bottom: 0;
  right: 0;
  width:$spacing-32;
  height:$spacing-32;
  padding: $spacing-24;
  background-color: white;
  border-radius: 0.6rem 0.6rem 0 0;
  border: 0.1rem solid $silver-200;
  border-bottom: white;
  margin-bottom: -0.1rem;
}
