@import '~@zf/stella-react/variables.scss';

.graph-compare-filters-wrapper > div:last-of-type {
  margin-top: -1rem;
}

.graph-compare-filters {
  border: 0.2rem solid $silver-200;
  border-radius: $border-radius-4;
  width: fit-content;
  display: flex;
  flex: 0 1 auto;
  gap:$spacing-32;
  margin-left: 2rem;
  align-items: center;
  justify-content: center;
}

.graph-compare-filters > div {
  display: flex;
}

.graph-compare-filters > div:last-of-type {
  margin-left: auto;
  padding-right: 2rem;
}

.icon-compare {
  color: $silver-600 !important;
}

.time-filters {
  display: flex;
}

.line {
  width: 6rem;
  height: 0.2rem;
  background-color: $silver-200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: $font-size-20;
  color: $silver-700;
}

.icon-quit-compare {
  height: fit-content;
}
