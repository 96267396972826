@import '~@zf/stella-react/variables.scss';

.actions {
  display: flex;
}

.actions :first-child {
  margin-right: 1rem;
}

.dashboard-customer-aging-card-content {
  min-height: 32rem;
}

.aging-reports-filter {
  margin-right: 0;
  display: flex;
  align-items: center;
}

.customer-aging-chart {
  padding: $spacing-16 0rem;
  display: flex;
}

.customer-aging-chart-open-credits {
  width: 10%;
}

.export-to-csv-aging-reports-button {
  display: flex;
  gap: $spacing-8;
  align-items: center;
}

.customer-aging-chart-buckets {
  width: 70%;
}

.customer-aging-total-info {
  width: 30%;
}

.export-icon {
  margin-right: 0;
}

.customer-aging-filters {
  display: flex;
  align-items: center;
}

.customer-aging-filter-content {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  width: 30rem;
}

.customer-aging-filter-content-toggle {
  display: flex;
  margin-top: $spacing-12;
  margin-bottom: $spacing-12;
}

.export-dialog {
  width: 70rem;
}
