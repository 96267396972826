@import '~@zf/stella-react/variables.scss';

.type-input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;

  .input {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.channel-input-wrapper {
  display: grid;
  box-sizing: border-box;
  grid-gap: $spacing-16;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  padding-top: $spacing-16;
}
