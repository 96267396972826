@import '~@zf/stella-react/variables.scss';

.utility-type-btn {
  font-size: $font-size-16;
  padding: $spacing-8 $spacing-8 0.5rem $spacing-8;
  border-radius: $border-radius-4;
}

.disabled {
  border: 0.25rem solid $silver-200 !important;
}

.disabled span {
  color: $silver-200 !important;
}
