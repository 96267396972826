@import '~@zf/stella-react/variables.scss';

.simple-tooltip {
  border: 0.1rem solid $silver-200 !important;
  border-radius: $border-radius-4 !important;
  font-family: $font-default !important;
  font-size: $font-size-16 !important;
  box-shadow: $small-box-shadow !important;
  overflow: visible !important;
  opacity: 1 !important;
  z-index: $highest-z-index;
}

.wrapper-div {
  height: fit-content;
}
