@import '~@zf/stella-react/variables.scss';

.advance-btns {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom:$spacing-32;
}

.advance-btns > div {
  margin-right: $spacing-16;
  margin-top: $spacing-16;
}

.deleted {
  text-decoration: line-through;
}
