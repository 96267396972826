@import '~@zf/stella-react/variables.scss';

.multi-issue-panel-body {
  height: inherit;
}

.issues {
  margin-top: $spacing-16;
}

.grouped-issue-panel-link {
  color: $silver-600;
  padding: $spacing-4 $spacing-16;
  text-decoration: none !important;
  text-align: center;
}
