@import '~@zf/stella-react/variables.scss';

.weekdays {
  font-size: $font-size-16;
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  .weekday {
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    color: $silver-700;
  }
}
