@import '~@zf/stella-react/variables.scss';

.groupby-filter {
  padding-left: 1.6rem;
  display: flex;
}

.groupby-filter > p {
  font-weight: $font-weight-bold;
  color: $silver-600;
}

.groupby-filter div {
  color: $graphite;
}
