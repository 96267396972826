@import '~@zf/stella-react/variables.scss';

.variable {
  padding: 0.5rem;
  margin: $spacing-16;
  font-size: $font-size-16;
  box-shadow: $large-box-shadow;
  background-color: $white-200;
  height: 3.5rem;
  outline: none;

  &:hover,
  &:focus {
    box-shadow: $small-box-shadow;
  }
}

.remove {
  position: absolute;
  top: $spacing-8;
  right: $spacing-16;
  font-size: $font-size-20;
  color: $red-600;
}
