@import '~@zf/stella-react/variables.scss';

.user-card {
  position: relative;
  display: grid;
  grid-template-rows: 10rem min-content 1fr;
  width: 35rem;
  font-family: $font-default;
  border-radius: $border-radius-4;
  background-color: $white-200;
  box-shadow: $medium-box-shadow;
  overflow-y: none;
}

.height-style {
  height: 19.75rem;
}

.actions {
  position: absolute;
  right: $spacing-8;
  top: $spacing-8;
  display: flex;
  gap: $spacing-8;
}

.actions li div {
  padding: 0.1rem;
}

.user-name {
  color: $graphite;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
}

.role {
  width: fit-content;
  margin: auto;
}

.org-roles {
  margin-top: 6rem;
  margin-bottom:$spacing-32;
}

.role-dropdown {
  padding: 0.5rem;
  margin: auto;
  width: 80%;
}

.org-role-grid {
  display: grid;
  grid-template-columns: 6rem min-content;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.org-role-grid p {
  line-height: $line-height-32;
}

.org-code {
  color: $white-200;
  text-align: center;
  height: 3.4rem;
  line-height: $line-height-32;
  width: 100%;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  border-radius: $border-radius-4;
}
