@import '~@zf/stella-react/variables.scss';

.move-out-date-wrapper {
  margin-bottom: $spacing-24;
}

.move-out-date {
  max-width: 50%;
}

.move-out-date > p {
  margin-top: $spacing-4;
}
