@import '~@zf/stella-react/variables.scss';

.activity {
  display: grid;
  grid-template-columns: auto min-content;
  margin-bottom: 2rem;
}

.btn {
  margin-right: 1rem;
}

.activity-completed {
  position: relative;
  display: flex;
  white-space: nowrap;
  font-weight: $font-weight-regular;
  border-radius: $border-radius-4;
  font-size: $font-size-16;
  padding: 1rem;
  margin-bottom: $spacing-16;
}

.activity-completed p {
  position: absolute;
  width: fit-content;
  right: 1rem;
}
