@import '~@zf/stella-react/variables.scss';
.input-container {
  max-width: 40rem;
  margin: auto;
  margin-top: $spacing-32;
}

.input-container > div {
  margin: auto;
}

.progress-bar {
  margin-top: 5rem;
}
