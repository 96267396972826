@import '~@zf/stella-react/variables.scss';

@keyframes borderExpand {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.logo {
  margin: 0rem auto;
  cursor: pointer;
  outline: 'none';
}

.logo > a {
  text-decoration: none;
}

.icon {
  color: $silver-700;
  font-size: $font-size-48;
  transition: all 0.1s ease-in-out;
}

.icon:hover {
  color: $silver-700;
}

.active-icon {
  color: $blue-600;
  font-size: $font-size-48;
  transition: all 0.1s ease-in-out;
  margin-top: -0.4rem;
  height: 6.5rem;
}

.active-icon::after {
  animation: borderExpand 0.1s;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  content: ' ';
  height: 0.5rem;
  width: 7.5rem;
  background-color: $blue-600;
  margin-top: $spacing-8;
  border-radius: 100rem;
  margin-left: -1.2rem;
}
