@import '~@zf/stella-react/variables.scss';

.stacked-bar {
  height: 7rem;
  grid-column: 1/4;
  display: flex;
  width: 100%;
}
.stacked-bar > div {
  transition: all .3s ease;
}

.bar-stack {
  height: 100%;
  margin-left: $spacing-8;
  margin-right: $spacing-8;
  padding-left: $spacing-24;
  padding-right: $spacing-24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-8;
  white-space: nowrap;
}

.can-click {
  cursor: pointer;
}

.isSelected {
  border: $border-one;
}
