@import '~@zf/stella-react/variables.scss';

$gap: 2rem;

.input-container-grid {
  width: 100%;
  padding: $spacing-16 1rem 2rem 1rem;
  margin-bottom: 2rem;
}

.without-sections {
  display: grid;
  box-sizing: border-box;
  padding: $spacing-16 1rem 2rem 1rem;
  grid-gap: $gap;
  grid-template-columns: repeat(auto-fill, minmax(#{(30rem - $gap)}, 1fr));
  margin-top: $spacing-16;
}

.dense {
  grid-auto-flow: dense;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  padding: $gap;
}

.input-double {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  grid-gap: $gap;
  grid-template-columns: repeat(auto-fill, minmax(#{(14rem - $gap)}, 1fr));
  grid-auto-flow: dense;
}

.input-section {
  display: grid;
  margin-top: $gap;
  box-sizing: border-box;
  grid-gap: $gap;
  grid-template-columns: repeat(auto-fill, minmax(#{(28rem - $gap)}, 1fr));
  grid-auto-flow: dense;
}
