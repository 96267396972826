@import '~@zf/stella-react/variables.scss';

.card-footer {
  background-color: $silver-50;
  display: flex;
  align-items: center;
  grid-gap: $spacing-16;
  padding: $spacing-24 $spacing-12;
}

.name {
  font-weight: 600;
}
