@import '~@zf/stella-react/variables.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputs {
  width: 75%;
  margin-top: 4rem;
  margin-bottom: $spacing-32;
}
