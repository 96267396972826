@import '~@zf/stella-react/variables.scss';

.error-list {
  padding-right: 2rem;
  font-size: $font-size-16;
}

.error-list > li {
  list-style-type: disc !important;
}
