@import '~@zf/stella-react/variables.scss';

.calendar {
  font-family: $font-default;
}

.title {
  display: flex;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  color: $silver-700;
}

.title * {
  font-size: $font-size-20;
  color: $silver-700;
}

.container {
  cursor: pointer;

  &:hover,
  &:focus {
    color: $silver-600;
  }
}
