@import '~@zf/stella-react/variables.scss';

.wizard-step {
  width: 100%;
  white-space: nowrap;
  font-size: $font-size-16;
  font-weight: $font-weight-extra-bold;
  line-height: $line-height-32;
  text-transform: uppercase;
  color: $silver-600;
  letter-spacing: 0.08rem;
  text-align: center;
  margin-bottom: $spacing-24;
}
