@import '~@zf/stella-react/variables.scss';

.year-input {
  padding: 0.5rem 1rem;
  font-weight: $font-weight-regular !important;
  cursor: pointer;
}

.title {
  font-weight: $font-weight-regular !important;
}
