@import '~@zf/stella-react/variables.scss';

.table-wrapper {
  display: flex;
  flex-direction: column;
  height: 25vh;
  width: 100%;
}

.table-content {
  box-sizing: border-box;
  height: 100%;
  overflow-y: hidden;
}

.payment-wrapper {
  display: grid;
  width: 70%;
  margin: auto;
}

.payment-amount {
  margin: $spacing-8 auto $spacing-16 auto;
  display: grid;
  gap: $spacing-4 $spacing-16;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  text-align: center;
}

.chars-left {
  text-align: left;
  font-size: $font-size-16;
  line-height: $line-height-24;
}

.payments-undo-heading {
  margin-bottom: $spacing-32;
}

.payment-undo-info {
  margin-bottom: $spacing-16;
}

.payents-refund-enabled-info {
  margin-bottom: $spacing-16;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
