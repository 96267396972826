@import '~@zf/stella-react/variables.scss';

.list-item {
  display: grid;
  margin: 0;
  height: 7rem;
  width: 100%;
}

.list-item > a {
  text-decoration: none;
}

.nav-list-item {
  margin: auto;
  height: 90%;
  width: 70%;
  user-select: none;
  cursor: pointer;
  outline: none;
  font-weight: $font-weight-regular;
  font-size: $font-size-25;
  color: $silver-600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-4;

  &:hover {
    color: $graphite;
    background-color: $silver-50;
    border-radius: $border-radius-4;
  }

  &.active,
  &:focus {
    color: $graphite;
    background-color: $silver-50;
    border-radius: $border-radius-4;
  }

  &.active {
    font-weight: $font-weight-regular;
    color: $blue-600;
  }
}

.label {
  letter-spacing: 0.08rem;
  font-weight: $font-weight-bold;
  padding: 1rem 2rem;
  color: $graphite;
}
