@import '~@zf/stella-react/variables.scss';

.dialog-wrapper {
  padding: 0rem 6.4rem;
  width: 80rem;
}

.divider {
  margin: $spacing-24 0rem;
}
