@import '~@zf/stella-react/variables.scss';

.wrapper > div:last-of-type {
  flex: 1;
}

.cards {
  padding-right: $spacing-32;
}

.integration-logging {
  margin-top: $spacing-32;
  padding-right: $spacing-32;
  padding-bottom: $spacing-32;
}
