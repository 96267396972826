@import '~@zf/stella-react/variables.scss';

.add-btn {
  width: 3.6rem;
  height: 3.6rem;

  width: fit-content;
  border-radius: $border-radius-4;
  margin-right: 0rem !important;
}

.add-btn span {
  font-size: $font-size-16 !important;
}

.active {
  background-color: $silver-200;
}

.active:hover {
  background-color: $silver-200;
}

.active span {
  color: $silver-700 !important;
}

.disabled {
  background-color: $silver-50;
  cursor: default !important;
}

.disabled span {
  color: $silver-400 !important;
}

.text {
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: row;
}

.text * {
  width: min-content;
  line-height: normal;
}

.text p {
  white-space: nowrap;
  color: $silver-700;
  margin-left: 1rem;
}
