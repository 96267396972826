@import '~@zf/stella-react/variables.scss';

.info-grid {
  display: flex;
  gap: $spacing-8;
  padding: $spacing-16 0;
  border: 0.2rem solid $silver-400;
  border-radius: $border-radius-8;
}

.info-grid > div:not(:last-of-type) {
  border-right: 0.2rem solid $silver-400;
}

.grey {
  background-color: $silver-50;
  border: none;
}
