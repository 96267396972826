@import '~@zf/stella-react/variables.scss';

.card {
  display: grid;
  grid-template-areas: 'card-header' 'card-body' 'card-footer';
  grid-template-rows: auto 1fr auto;

  box-sizing: border-box;
  background-color: $white-200;
  width: 100%;
  position: relative;

  &.transparent {
    background-color: transparent;
    box-shadow: none;
  }

  &.dark {
    background-color: $silver-700;
    box-shadow: none;
    border: $silver-400;
    color: $silver-200 !important;
  }
}

.border {
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-8;
}

@for $i from 1 through 4 {
  .card-#{$i} {
    grid-column-end: span $i;
  }
}

@media (max-width: 1100px) {
  .card-2 {
    grid-column-end: span 1;
  }

  .card-3 {
    grid-column-end: span 1;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .card-3 {
    grid-column-end: span 2;
  }
}

.overlay-wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100%;
  background-color: $silver-200;
  opacity: 0.65;
  z-index: $disabled-z-index;
}
