@import '~@zf/stella-react/variables.scss';

.parameters {
  padding: $spacing-24;
}

.spinner-parent {
  position: relative;
  padding: $spacing-24;
}

.calculation-type {
  border-left: $border-one $silver-200;
}

.tier-inputs {
  margin-bottom: 0rem;
}

.calculation-type-radio-button {
  margin: 0;
}

.calculation-type-input-container {
  display: flex;
  flex-direction: column;
  padding-left: $spacing-16;
  gap: $spacing-24;
}

.tariff-period-tab-slider {
  width: 100%;
  margin-top: $spacing-8;
  margin-bottom: $spacing-16;
  padding-inline: 0rem;
}

.add-tariff-spacer {
  margin-top: $spacing-24;
  margin-bottom: $spacing-24;
}

.add-tariff-wrapper {
  width: 70rem;
}

.conditions-info {
  text-align: left;
  margin-top: $spacing-32;
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}
