@import '~@zf/stella-react/variables.scss';

.attachment-signatures {
  margin-top:$spacing-32;
}

.attachment-signatures h2 {
  margin-bottom: 1rem;
}

.attachment-signature {
  margin-bottom: 1rem;
}

.attachment-signature > li > div {
  margin-left: 1rem;
  font-size: $font-size-16;
  padding: 0rem;
}

.attachment-signature > li > div > div span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  font-size: $font-size-16;
}

.attachment-signature p {
  width: fit-content;
}

.attachment-signature > p:last-of-type {
  margin-left: 3.2rem;
  font-size: $font-size-16;
}

.error {
  color: $red-600;
  margin-left: 1rem;
}
