@import '~@zf/stella-react/variables.scss';

.tooltip {
  background-color: $white-200;
  border-radius: $border-radius-4;
  border: 0.1rem solid $blue-400;
  padding: 0rem 2rem $spacing-32 2rem;
}

.tooltip-values {
  white-space: nowrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-24;
}
