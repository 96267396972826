@import '~@zf/stella-react/variables.scss';

.list-page {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8rem);
  padding: $spacing-48;
  padding-bottom: 0;
  border-radius: $border-radius-8;

  .top-bar {
    width: 100%;
  }

  .list {
    box-sizing: border-box;
    height: 100%;
    overflow-y: hidden;
    border-radius: $border-radius-8 0 $border-radius-8 $border-radius-8;
    border: $border-one $silver-200;
    background-color: white;
  }
}

.list-page-embedded {
  position: relative;
  display: flex;
  flex-direction: column;
  //based loosely on dialog.module.scss
  height: 65vh;
  width: 100%;
  border-radius: $border-radius-8;
  text-align: left;

  .top-bar {
    padding-top: 2.5vh;
    width: 100%;
  }

  .list {
    box-sizing: border-box;
    height: 100%;
    overflow-y: hidden;
    border-radius: $border-radius-8 0 $border-radius-8 $border-radius-8;
    border: $border-one $silver-200;
    background-color: white;
  }
}
