@import '~@zf/stella-react/variables.scss';
.flag {
  border-radius: 25%;
}

.small {
  height: 1.6rem;
  width: 1.6rem;
}

.medium {
  height:$spacing-32;
  width:$spacing-32;
}
