@import '~@zf/stella-react/variables.scss';

.crumb-header {
  position: absolute;
  left: 12rem;
  top: -6.1rem;
  width: 100%;
  display: grid;
  grid-template-columns: min-content min-content min-content;
  box-sizing: border-box;

  .crumb-wrapper {
    flex: 9;
  }
}
