@import '~@zf/stella-react/variables.scss';

.tooltip-detail {
  width: fit-content;
  font-size: $font-size-16 !important;
  z-index: -99;
  margin-inline: 0rem;
}

.tooltip-dialog-main {
  min-width: 30rem;
  max-width: 34rem;
  font-size: $font-size-16 !important;
  z-index: -99;
  margin-inline: 0rem;
}

.tooltip-dialog-line {
  min-width: 48rem;
  max-width: 56rem;
  font-size: $font-size-16 !important;
  z-index: -99;
  margin-inline: 0rem;
  gap: 200px;

  .title {
    padding-bottom: $spacing-16;
  }

  hr {
    border: 0.1rem solid $silver-200;
    margin-top: $spacing-16;
    margin-bottom: $spacing-16;
  }

  .parameters {
    .parameter-line {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .parameter-text {
        width: fit-content;
        margin-inline: 0rem;
        font-size: $font-size-20;
      }

      .parameter-percentage {
        width: fit-content;
        margin-right: 0rem;
        margin-left: $spacing-8;
        font-size: $font-size-20;
        font-weight: $font-weight-bold;
      }
    }

    .parameter-line:not(:first-child) {
      padding-top: $spacing-8;
    }
  }

  .checkmarks {
    display: flex;
    flex-direction: column;

    .checkmark-line,
    .checkmark-line-outdated {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-top: $spacing-4;
      padding-bottom: $spacing-4;
      flex-wrap: wrap;

      span {
        margin: 0rem;
        line-height: 0rem;
        padding-right: $spacing-8;
      }

      .checkmark-text {
        font-size: $font-size-20;
        line-height: $line-height-24;
        width: fit-content;
        margin-inline: 0rem;
      }

      .checkmark-link {
        width: fit-content;
        margin-right: 0rem;
        margin-left: $spacing-8;
        font-size: $font-size-20;
        font-weight: $font-weight-bold;
      }
    }

    .checkmark-line-outdated {
      order: 2 !important;
    }

    .checkmark-line {
      order: 1 !important;
    }

    .checkmark-cost-component {
      font-size: $font-size-20;
      padding-left: $spacing-32;
      padding-top: $spacing-8;
    }
  }
}

.accuracy-indicator-btn {
  padding-right: 0rem;
}

.info-paragraph {
  padding-top: $spacing-16;
  color: $silver-600;
  font-weight: $font-weight-regular;
}
