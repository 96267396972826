.consumptions-card-container {
  height: 100%;
}

.consumption-chart {
  height: 40rem;
}

.consumption-chart-extra-right {
  width: fit-content;
}
