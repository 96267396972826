@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: flex;
  padding: 1rem;
  margin-top: 4.5rem;
}

.input-wrapper {
  margin-top: 3.5rem;
  position: relative;
}

.add-margin-top {
  margin-top: $spacing-32;
}

.terminate-add-measurement {
  display: grid;
  grid-template-rows: min-content 1fr;
  background-color: $silver-50;
  padding: 1rem;
  border-radius: $border-radius-4;
  margin-top: 1rem;
  margin-bottom: 2rem;
  p {
    text-align: left;
  }
}

.move-in-wrapper {
  display: flex;

  > * {
    padding: 1rem;
  }
}

.move-in-wrapper-child {
  display: flex;
  margin-left: $spacing-24;

  > * {
    padding: 1rem;
  }
}

.popup-title {
  width: calc(100% - 2rem);
  font-weight: $font-weight-bold;
  font-size: $font-size-20;
  padding: 0.3rem 1rem 0.2rem 1rem;
  color: $blue-600;
}

.icon {
  margin: auto 0;
  color: $blue-600;
  font-size: $font-size-20;
  border-radius: 50%;
}

.item {
  padding: 0.5rem 1rem;
  border-right: 0.1rem solid $blue-200;
}

.item-end {
  padding: 0.5rem 1rem;
}

.channel {
  white-space: nowrap;
  display: grid;
  gap: 20px;
}

.address-meter {
  padding: $spacing-12;
  padding-bottom: 0rem;
  white-space: nowrap;
  display: flex;
}

.address-meter p:nth-child(2) {
  margin-left: $spacing-24;
}

.msmt-value {
  max-width: 15rem;
}

.new-address {
  padding: 0rem 0rem $spacing-40 $spacing-40;
}

.customer-select {
  margin-left: $spacing-24;
}

.new-cust {
  margin-left: $spacing-24;
}

.new-invoice-adress {
  padding-left: $spacing-24;
}

.terminate-add-measurement-input-container {
  margin-bottom: 0;
  padding-bottom: $spacing-16;
}
