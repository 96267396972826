@import '~@zf/stella-react/variables.scss';

.cust_group_link {
  margin-right: 0rem !important;
}

.sub-icon-pg,
.sub-icon-cg {
  margin-left: $spacing-8;
}
