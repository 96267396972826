@import '~@zf/stella-react/variables.scss';

.completeness {
  &.completeness-25 {
    color: $red-600;
  }

  &.completeness-50 {
    color: $yellow-600;
  }

  &.completeness-100 {
    color: $green-600;
  }
}

.contracts_changed_on_type {
  color: $silver-600;
  font-size: $font-size-12;
  margin-left: $spacing-8;
}

.estimated-invoice-amount-extra-info-button {
  padding: $spacing-8 !important;
}
