@import '~@zf/stella-react/variables.scss';

.avatar {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  margin: auto;
  border: none;
  border-radius: $border-radius-4;
  font-family: $font-default;
  user-select: none;

  &.xsmall {
    width: $spacing-24;
    height: $spacing-24;

    &.text-avatar {
      font-size: $font-size-12;
    }
  }

  &.small {
    width: 3.5rem;
    height: 3.5rem;

    &.text-avatar {
      font-size: $font-size-16;
      line-height: $line-height-24;
    }
  }

  &.medium {
    width: 5rem;
    height: 5rem;

    &.text-avatar {
      font-size: $font-size-20;
    }
  }

  &.large {
    width: 6.5rem;
    height: 6.5rem;

    &.text-avatar {
      font-size: $font-size-25;
    }
  }

  &.circle {
    border-radius: 50%;
  }

  &.text-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;

    // Some color defaults, will be overriden by a random color generated by the server on register?
    background-color: $blue-600;
    color: $white-200;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}
