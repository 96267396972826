@import '~@zf/stella-react/variables.scss';

.wrapper {
  grid-template-columns: 1fr 1fr;
  width: 85%;
}

.color-div {
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: $border-radius-2;
}

.legend-entry {
  display: grid;
  grid-template-columns: min-content auto;
}

.legend-entry p {
  color: $silver-600;
  margin-left: 1rem;
}

.data-key-values {
  white-space: nowrap;
  display: flex;
}

.data-key-values p:nth-of-type(1) {
  width: 9rem;
}

.data-key-values p:nth-of-type(2) {
  text-align: right;
}

.data-key-value-paragraph {
  color: $silver-700;
}
