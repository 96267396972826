@import '~@zf/stella-react/variables.scss';

.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters {
  display: flex;
  align-items: center;
  gap: $spacing-16;
}

.filters > button {
  white-space: nowrap;
}
