.assign-all-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.assign-all-dialog > p {
  margin-top: 2rem;
  max-width: 75%;
}

.existing-paragraph {
  padding-bottom: 4rem;
}

.collection-date {
  max-width: 30rem;
}
