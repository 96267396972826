@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: grid;
  height: 100%;
}

.min-height {
  min-height: 35rem;
}

.with-padding {
  padding: 1rem;
}

.emptycard-container {
  width: 100%;
  text-align: center;
  margin: auto;

  .emptycard-image {
    font-size: $font-size-48;
    color: $silver-600;
  }

  .emptycard-icon-loading {
    font-size: $font-size-31;
    color: $silver-600;
  }

  .emptycard-title {
    margin: 0.5rem;
  }

  .emptycard-description {
    margin-top: 1rem;
  }

  .emptycard-description > p {
    font-size: $font-size-12;
  }
}

.error {
  border: 0.1rem solid $red-600;
  border-radius: $border-radius-4;
}
