@import '~@zf/stella-react/variables.scss';

.billing-item-tree {
  cursor: default;
  margin-top: 1rem;
}

.billing-item-tree ul {
  font-weight: $font-weight-regular !important;
  margin-left: 1rem;
}

.billing-item-tree ul:hover {
  color: $blue-600;
}

.billing-item-tree li {
  padding-top: 0.5rem;
}

.selected ul {
  color: $blue-600;
}

.item-icon {
  line-height: normal;
  margin-right: 0.5rem;
}

.row-light {
  display: flex;
  flex-direction: row;
}

.row-dark {
  display: flex;
  flex-direction: row;
}

.row-light span {
  color: $silver-600 !important;
}

.row-light p {
  color: $silver-600 !important;
  margin-left: 1rem;
}

.row-dark span {
  color: $silver-700 !important;
}

.row-dark p {
  color: $silver-700 !important;
  margin-left: 1rem;
}

.p-q li:hover {
  color: $blue-600 !important;
}

.tariff {
  text-align: right !important;
}
