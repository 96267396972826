@import '~@zf/stella-react/variables.scss';

.icon {
  height: fit-content;
  width: fit-content;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  padding: $spacing-16 $spacing-24;
  font-size: $font-size-16;
  line-height: $line-height-24;
}
