@import '~@zf/stella-react/variables.scss';

.center {
  display: flex;

  &.horizontal,
  &.both {
    width: 100%;
    justify-content: center;
  }

  &.vertical,
  &.both {
    height: 100%;
    align-items: center;
  }
}
