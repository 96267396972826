@import '~@zf/stella-react/variables.scss';

.change-advance-amount-input {
  margin-top: $spacing-24;
  margin-bottom: $spacing-24;
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}

.change-advance-amount-limit {
  margin: auto;
  display: flex;
  gap: $spacing-8;
  align-items: center;
}

.change-advance-amount-limit-paragraph {
  width: fit-content;
  margin: 0;
  font-size: $font-size-12;
}

.change-advance-amount-dialog {
  width: 50rem;
  padding-left: $spacing-40;
  padding-right: $spacing-40;
}

.change-advance-amount-dialog > div:first-of-type {
  margin-top: $spacing-40;
}

.change-advance-amount-changed-by {
  font-size: $font-size-12;
}

.warning {
  display: flex;
  justify-content: center;
  gap: $spacing-8;
}

.warning > * {
  color: $orange-600;
}

.overruling-invoice-btn {
  padding: 0rem;
}

.overruling-invoice-btn:hover {
  text-decoration: underline;
}
