@import '~@zf/stella-react/variables.scss';

.introduction {
  position: relative;
  display: grid;
  grid-template-columns: 5rem 1fr auto;
  margin: auto;
  padding:$spacing-32;
  border: $border-one $silver-200;
  border-radius: $border-radius-4;
  box-sizing: border-box;
  background-color: $white-200;
  width: 100%;
}

.svg-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg {
  width: 5rem;
  height: 5rem;
  background-image: url('https://zfcdnstorage.blob.core.windows.net/shared/assets/info.svg');
  background-repeat: no-repeat;
}

.text {
  margin-left:$spacing-32;
  padding-right: $spacing-16;
  float: left;
  min-height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text h3 {
  white-space: normal !important;
}

.children {
  width: 100%;
  margin: auto;
}

@for $i from 1 through 3 {
  .card-#{$i} {
    grid-column-end: span $i;
  }
}

@media (max-width: 1100px) {
  .card-2 {
    grid-column-end: span 1;
  }

  .card-3 {
    grid-column-end: span 1;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .card-3 {
    grid-column-end: span 2;
  }
}
