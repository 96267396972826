@import '~@zf/stella-react/variables.scss';

.bar-chart-card {
  position: relative;
}

.cardbody {
  padding: $spacing-24 $spacing-32 $spacing-8 0rem;
  font-size: $font-size-16;
  overflow: hidden;
}

.cardbody span {
  color: $silver-600;
  padding: 0rem 0rem $spacing-24 $spacing-32;
}

.extra-right {
  position: absolute;
  right: $spacing-8;
  top: $spacing-8;
}
