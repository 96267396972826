@import '~@zf/stella-react/variables.scss';

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  font-size: 62.5%;
  font-family: $font-default;
}

::-webkit-scrollbar {
  height: $spacing-12;
  width: $spacing-12;
  border-radius: $border-radius-8;
  background-color: $white-200;
}

::-webkit-scrollbar-thumb {
  height: $spacing-8;
  width: $spacing-8;
  border-radius: $border-radius-8;
  background-color: $silver-200;

  &:hover {
    background-color: $silver-400;
  }
}

iframe > html ::-webkit-scrollbar {
  height: $spacing-12;
  width: $spacing-12;
  border-radius: $border-radius-8;
  background-color: $silver-50;
}

.root-div {
  height: 100vh;
  width: 100vw;
}

.app-root-heading {
  color: $red-700;
}

@media (max-width: 1440px) {
  html,
  body {
    font-size: 40%;
  }
}

@media (min-width: 1440px) and (max-width: 1920px) {
  html,
  body {
    font-size: 50%;
  }
}
