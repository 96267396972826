@import '~@zf/stella-react/variables.scss';

.dashboard-column {
  padding: 0rem 2rem 2rem 2rem;
  height: 90vh;
  overflow-y: auto;
}

.dashboard-column > div {
  margin-bottom: $spacing-16;
}

.with-border {
  border-right: 0.2rem dashed $silver-200;
}

.split-invoice-chart {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
}
