@import '~@zf/stella-react/variables.scss';

.cost-allocation-tab {
  height: 69vh;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.big-add-btn {
  margin-top: 1rem;
  width: 100%;
}

.allocations-disabled-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.group-column > div:nth-of-type(3) {
  margin-top: 0rem;
}

.group-column > div:nth-of-type(4) {
  margin-top: 0rem;
}

.icon span {
  line-height: $line-height-32;
  height: min-content;
  font-size: $font-size-20;
}

.billing-item {
  position: relative;
  border-radius: $border-radius-4;
  padding: 2rem;
  margin-top: 1rem;
  margin-right: 1rem;
  background-color: $silver-200;
}

.billing-item h3 {
  color: $graphite;
}

.column-body {
  padding: 0rem;
}

.billing-item-actions {
  position: absolute;
  right: $spacing-16;
  top: 2rem;
}

.billing-item-actions span {
  font-size: $font-size-20;
}
