@import '~@zf/stella-react/variables.scss';

.user-manager-wrapper {
  position: relative;
}

.back-btn {
  position: absolute;
  left: 2rem;
  top: -6.25rem;
  z-index: 51;
}

.back-btn * {
  font-size: $font-size-20 !important;
}

.user-manager {
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 2rem;
  height: calc(100vh - 21rem);
  padding: 2rem;
  overflow: hidden;
}

.user-cards-grid {
  display: grid;
  grid-template-columns: minmax(50rem, 30%) auto;
  gap: 2rem;
  overflow: auto;
}

.user-manager-input-container {
  padding-left: 14rem;
  margin: 0;
}

.heading {
  color: $silver-600;
}

.input {
  background-color: $silver-200;
}

.admins {
  height: fit-content;
  padding-left: 14rem;
  display: grid;
  gap: 2rem;
  border-right: 0.2rem solid $silver-200;
}

.users-wrapper {
  padding-left:$spacing-32;
}

.users {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 35rem);
  gap: 4rem;
}

.back {
  padding-top:$spacing-32;
}

.heading-wrapper {
  display: flex;
  height:$spacing-32;
  line-height: normal;
}

.coachmark {
  margin-top: 0.3rem;
  margin-left: $spacing-16;
}

.footer {
  display: grid;
  grid-template-columns: minmax(50rem, 30%) 70%;
  height: 10rem;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  color: white;
  text-align: center;
}

.footer > * {
  width: fit-content;
  height: fit-content;
  margin: auto;
}
