@import '~@zf/stella-react/variables.scss';

.popup {
  position: absolute;
}

.normal {
  top: 50%;
  left: 50%;
  min-height: 50rem;
  min-width: 50rem;
  max-width: 50%;
  text-align: center;
  align-items: center;
  transform: translate(-50%, -50%);
}

.normal-big {
  top: 50%;
  left: 50%;
  min-height: 50rem;
  max-height: 75vh;
  min-width: 50rem;
  max-width: 90%;
  align-items: center;
  transform: translate(-50%, -50%);
}

.right {
  top: 0;
  right: 0;
  height: 100vh;
  width: 95rem;
}

.modal {
  position: relative;
  opacity: 0;
  visibility: hidden;
  background-color: $white-200;
  transition: all 0.3s ease;
  transform: scale(0);
  box-shadow: $large-box-shadow;

  .popup-content-normal {
    color: $silver-700;
    overflow-y: auto;
    max-height: 80vh;
  }

  .popup-content-right {
    // All height - height header - height footer
    height: calc(100vh - 7.2rem - 7.2rem);
    color: $silver-700;
    overflow-y: auto;
    box-sizing: border-box;
  }

  &.toggled {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    border-radius: $border-radius-8;
  }
}

.header {
  padding: $spacing-32;
  padding-bottom: 0rem;
  box-sizing: border-box;
}

.dialog-footer {
  text-align: right;
  background-color: $silver-200;
  border-bottom-left-radius: $border-radius-8;
  border-bottom-right-radius: $border-radius-8;
  display: flex;
  justify-content: flex-end;
  padding: $spacing-16;
  box-sizing: border-box;
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba($silver-600, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.toggled {
    opacity: 1;
    visibility: visible;
  }
}

.full-height {
  height: 100vh;
}

.padding {
  padding: $spacing-16 $spacing-40;
}
