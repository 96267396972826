@import '~@zf/stella-react/variables.scss';

.droppeable {
  display: grid;
  grid-template-areas: 'illustration' 'heading' 'content';
  border: 0.2rem dashed $silver-200;
  border-radius: $border-radius-8;
  color: $silver-700;
  font-family: $font-default;
  outline: none;
  cursor: pointer;

  &.droppeable-dragged {
    border-color: $silver-700;
  }

  &.droppeable-dragged {
    background-color: $silver-50;
  }
}

.droppeable:hover {
  border: 0.2rem dashed $silver-700;
}

.draggeable {
  outline: none;
}
