@import '~@zf/stella-react/variables.scss';

.header {
  display: flex;
  position: relative;
  height: 8rem;
  width: 100vw;
  background-color: white;
  z-index: 1;
}

.header-left {
  width: 10rem;
  background-color: $white-200;
  border-right: $border-one $silver-200;
}

.header-left div {
  width: min-content;
  margin-top: $spacing-16;
}

.header-right-wrapper {
  position: relative;
  width: calc(100vw - 10rem);
  border-bottom: $border-one $silver-200;
}

.header-right-wrapper-no-logo {
  position: relative;
  width: 100vw;
  border-bottom: $border-one $silver-200;
}

.header-right {
  display: flex;
  gap: $spacing-24;
  height: min-content;
  width: min-content;
  padding: $spacing-12;
  margin-left: auto;
  margin-top: $spacing-4;
}

.header-right * {
  margin: auto;
}

.user-container {
  position: relative;
  width: 100%;
  height: min-content;
  margin: auto;
}

.user-menu {
  display: block;
  position: absolute;
  top: $spacing-48;
  right: 0;
  width: 25rem;

  .user-menu-tooltip {
    padding: 0 $spacing-16;
  }
}

.language-item {
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  padding: $spacing-8 $spacing-24 !important;
}

.language-item > div {
  margin-left: 0rem;
}

.language-item span {
  font-size: $font-size-20;
  margin-right: $spacing-4;
}

.external-link {
  font-size: $font-size-20 !important;
  font-weight: $font-weight-bold !important;
}

.dev-info {
  width: 40rem;
}

.dev-info > p {
  color: $silver-200;
  font-size: $font-size-12;
}

.account-list * {
  font-size: $font-size-16 !important;
  line-height: $line-height-24 !important;
}

.divider {
  margin: 0rem;
}
