@import '~@zf/stella-react/variables.scss';

.card-dash {
  padding: $spacing-16 $spacing-24;
  display: flex;
  justify-content: space-between;
  align-items: center;

  text-decoration: none;
}

.chevron-paragraph {
  display: flex;
  align-items: center;
  grid-template-columns: auto $spacing-32;
  gap: $spacing-16;
}
