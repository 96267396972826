@import '~@zf/stella-react/variables.scss';

.section-header {
  margin-bottom: $spacing-16;
}

.muting-header,
.expired-header {
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  width: fit-content;
  text-align: left;
  margin-inline: 0;
}

.subtext {
  font-weight: $font-weight-regular;
  font-size: $font-size-16;
  color: $silver-600;
}

.toggle-expired-rules-btn {
  width: fit-content;
  padding-right: 0;
}

.rule-list {
  margin-bottom: $spacing-16;
}
