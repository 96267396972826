@import '~@zf/stella-react/variables.scss';

.dropdown-clear {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-top: 0.1rem solid $blue-200;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  color: $silver-700;
  border-bottom-left-radius: $border-radius-8;
  border-bottom-right-radius: $border-radius-8;

  &:hover {
    background: $blue-600;
    color: $white-200;
  }
}
