@import '~@zf/stella-react/variables.scss';

.insights-preview {
  width: 75%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.insights-preview > div {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.insights-preview > div p {
  width: min-content;
}

.invalid-warning {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.invalid-msges {
  width: fit-content;
  text-align: left;
  color: $silver-200 !important;
}

.invalid-msges * {
  color: $silver-600;
}

.invalid-msges > p:first-of-type {
  font-weight: $font-weight-bold;
  margin-bottom: 0.5rem;
}
