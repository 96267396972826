@import '~@zf/stella-react/variables.scss';

.error-msg {
  display: flex;
  height: $tab-body-height;
  justify-content: center;
  align-items: center;
  font-size: $font-size-25;
  font-weight: $font-weight-bold;
}

.error-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: fit-content;
  height: 50%;
}
