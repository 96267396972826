@import '~@zf/stella-react/variables.scss';

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-single p {
  font-size: $font-size-12;
}
