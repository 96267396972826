@import '~@zf/stella-react/variables.scss';

.tooltip-trigger {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: $spacing-8 1.6rem;
  width: fit-content;
  background-color: $white-200;
  font-family: $font-default;
  font-size: $font-size-16;
  color: $graphite;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-8;
  box-shadow: $small-box-shadow;
  z-index: $highest-z-index;
}

.no-padding {
  padding: 0rem;
}
