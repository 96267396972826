@import '~@zf/stella-react/variables.scss';

.action-btn {
  color: white !important;
  background-color: $blue-600;
  height: 5.4rem !important;
  width: 5.4rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-4;

  &:hover,
  &:active,
  &:focus {
    background-color: $blue-700 !important;
    box-shadow: $large-box-shadow;
  }
}

.action-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
}

.actions {
  display: grid;
  grid-gap: 0.5rem;
}
