@import '~@zf/stella-react/variables.scss';

.action-button {
  display: flex;
  flex-direction: row;
  gap: $spacing-8;
  justify-content: center;
  align-items: center;
  // Compensate for border
  padding: calc($spacing-8 - 0.2rem) calc($spacing-24 - 0.2rem);
  border-radius: $border-radius-4;
  border: none;
  font-weight: $font-weight-bold;
  font-family: $font-default;
  line-height: $line-height-24;
  font-size: $font-size-16;
  cursor: pointer;
  // Prevent size changes due to border on/off
  border: solid 0.2rem transparent;
}

// Types
.primary {
  background-color: $blue-600;
  color: $white-200;
  transition: all .150s ease-in-out;
}

.primary:hover {
  background-color: $blue-700;
}

.primary:active {
  background-color: $blue-400;
}

.secondary {
  background-color: $white-200;
  color: $blue-600;
  border: 0.2rem solid $blue-600;
  padding: $spacing-8 $spacing-24;
}

.secondary:hover,
.secondary:hover > * {
  background-color: $blue-50;
  border-color: $blue-700;
  color: $blue-700;
}

.secondary:active,
.secondary:active > * {
  background-color: $white-200;
  border-color: $blue-400;
  color: $blue-400;
}

.center-part-primary {
  border-right: 0.2rem solid $white-200;
  padding-right: $spacing-16;
  margin-right: $spacing-8;
}

.center-part-secondary {
  border-right: 0.2rem solid $blue-600;
  padding-right: $spacing-16;
  margin-right: $spacing-8;
}
