@import '~@zf/stella-react/variables.scss';

.time-filters {
  display: flex;
}

.line {
  width: 6rem;
  height: 0.2rem;
  background-color: $silver-200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-header > div:last-of-type {
  margin-left: auto;
  padding-right: 2rem;
}
