@import '~@zf/stella-react/variables.scss';

.info-grid {
  width: 100%;
  grid-column-end: span 4;
}

.settlement-details-card-body {
  overflow: hidden;
  margin: $spacing-16;
}

.dot-large-settled {
  color: $green-600;
  font-size: large;
  display: contents !important;
}

.dot-large-unsettled {
  color: $blue-600;
  font-size: large;
  display: contents !important;
}

.locked {
  background-color: $orange-50;
  display: flex;
  padding: $spacing-12 $spacing-24;
  align-items: center;
  //gap: $spacing-8;
  box-sizing: border-box;
}

.invoice-detail {
  position: relative;
  width: 20rem;
  overflow: hidden;
}

.status-indicator {
  position: absolute;
  top: 0%;
  left: 80%;
}

.transaction-link {
  gap: 2.4rem !important;

  span {
    width: fit-content;
    justify-content: start;
  }

  span:nth-child(2) {
    width: 16rem;
    // background-color: aquamarine;
    // display: inline-block;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    justify-content: start;
  }
}
