@import '~@zf/stella-react/variables.scss';

.card-measurements {
  margin: 0rem 5rem;
  width: calc(100% - 10rem);
}

.measurements {
  width: 100%;
  height: 69vh;
  overflow-y: hidden;
}

.error-codes {
  color: $red-600;
  list-style: upper-roman;
  font-family: $font-default;
  font-size: $font-size-12;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  width: 100%;
  padding: 0$spacing-32 0$spacing-32;

  .error-code {
    margin-top: 0.5rem;
  }
}

.import-job-bank-detail-card {
  height: 100%;
}
