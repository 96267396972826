@import '~@zf/stella-react/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
  margin-top: 5rem;
}

.tenant-container-wrapper {
  display: flex;
  flex-direction: column;
  background-color: $silver-50;
  height: 100vh;
}

.inner-element {
  margin: auto;
}

.list {
  height: min-content;
  height: 70vh;
  width: 70%;
  margin:$spacing-32;
}

.button {
  margin: 2rem;
  height: min-content;
}

.container-no-tenants {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.tenant-container-searchbar-wrapper {
  display: flex;
  justify-content: center;
}

.searchbar {
  width: 30rem;
}
