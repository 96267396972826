@import '~@zf/stella-react/variables.scss';

.status-badge {
  display: inline-block;
  overflow: hidden;
  font-family: $font-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-12;
  padding: 0.5rem;
  border: 0.1rem solid;
  border-radius: $border-radius-4;
  color: $silver-700;

  &.bare {
    border-color: transparent;
  }
}
