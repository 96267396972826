@import '~@zf/stella-react/variables.scss';

.accuracy-indicator-btn {
  padding-right: 0rem;
}

.ai-dot-small {
  color: $blue-200;
  font-size: small;
  margin-inline: -$spacing-4;
}

.ai-dot-large {
  color: $blue-200;
  font-size: large;
  margin-inline: -0.6rem;
}

.ai-dot-small-active {
  color: $blue-600;
  font-size: small;
  margin-inline: -$spacing-4;
}

.ai-dot-large-active {
  color: $blue-600;
  font-size: large;
  margin-inline: -0.6rem;
}
