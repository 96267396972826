@import '~@zf/stella-react/variables.scss';

.radio-input {
  display: flex;
  gap: $spacing-4;
}

.radio-input input {
  height: $line-height-24 !important;
}

.text {
  line-height: $line-height-24;
  font-size: $font-size-16;
  color: $graphite;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
