@import '~@zf/stella-react/variables.scss';

.validation-rule-card-body {
  padding: $spacing-24;
}

.validation-rule-card-item:not(:first-child) {
  padding-top: $spacing-16;
}

.validation-rule-setting-desc {
  width: 100%;
}
