@import '~@zf/stella-react/variables.scss';

.history-picker {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1rem;
}

.option {
  padding: 0rem 2rem 1rem 4rem;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 1rem;

  span {
    font-family: $font-default;
    font-size: $font-size-16;
    line-height: $font-size-16;
    font-weight: $font-weight-regular;
    color: $graphite;
  }

  span:hover {
    color: $blue-600;
  }

  .checkbox span {
    color: white;
    font-size: $font-size-12;
  }
}

.selected span {
  color: $blue-600;

  .checkbox span {
    color: white;
    font-size: $font-size-12;
  }
}
