@import '~@zf/stella-react/variables.scss';

.invoice-specs {
  border-left: $border-two $silver-400;
  padding-left: $spacing-16;
}

.dont-generate-adv-checkbox {
  margin-top: $spacing-8;
}
