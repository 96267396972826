@import '~@zf/stella-react/variables.scss';

.input-wrapper {
  padding: $spacing-24;
  display: flex;
  justify-content: center;
}

.info {
  width: 65rem;
  margin-bottom: 2rem;
}

.period-wrapper {
  margin: auto;
  display: flex;
  align-items: flex-start;
  padding: $spacing-16 0rem;
  width: 45rem;
}

.period-wrapper > p {
  margin: 0;
  line-height: $line-height-40;
}

.date-wrapper {
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}
