@import '~@zf/stella-react/variables.scss';

.ignored-panel-body {
  height: 100%;
}

.resolved-by {
  margin-top: $spacing-24;
}

.before-after {
  margin: $spacing-16 0;
  padding: $spacing-16 $spacing-24;
  border: $border-one $silver-200;
  border-radius: $border-radius-4;
}
