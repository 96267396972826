@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: flex;
  height: 73vh;
  gap: 2rem;
}

.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;
  height: 100%;
  width: 50%;
  padding: $spacing-24 $spacing-24 0rem 4rem;
  border-right: 0.2rem dashed $silver-200;
}

// Table rows
.left > div:last-of-type > div:last-of-type * {
  cursor: pointer;
}

.completeness {
  &.completeness-25 {
    color: $red-600;
  }

  &.completeness-50 {
    color: $yellow-600;
  }

  &.completeness-100 {
    color: $green-600;
  }
}

.right {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: $white-200;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-4;
}
