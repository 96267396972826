@import '~@zf/stella-react/variables.scss';

.info-grid {
  padding: 0rem 2rem 0rem 2rem;
  display: grid;
  gap: 2rem;
}

.started {
  text-align: center !important;
}

.info-grid p {
  text-align: left;
  color: $silver-600;
}

.info-grid span {
  color: black;
  font-weight: $font-weight-bold;
}

.part-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.domain-name {
  text-align: left;
}

.percentage {
  text-align: right;
}
