@import '~@zf/stella-react/variables.scss';

.wizard-item {
  padding: $spacing-16 $spacing-24;
  padding-top: $spacing-24;
  border: $border-one $silver-600;
  border-radius: $border-radius-8;
}

.invoice-autofill-outgoing-wizard {
  margin-bottom: $spacing-16;
}
