@import '~@zf/stella-react/variables.scss';

.error-view {
  border-radius: $border-radius-4;
  padding: $spacing-8 2rem;
  padding-left: 4rem;
  line-height: $line-height-32;
  font-size: $font-size-16;
  margin-bottom:$spacing-32;
}

.error-view li {
  list-style-type: disc;
}
