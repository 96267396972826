@import '~@zf/stella-react/variables.scss';

.info-grid-column {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
  width: 100%;
  padding-left: $spacing-24;
  padding-right: $spacing-24;
}

.info-grid-column > div {
  width: 100%;
}
