@import '~@zf/stella-react/variables.scss';

.card-item {
  position: relative;
  display: flex;
  box-sizing: border-box;

  &.center {
    align-items: center;
    justify-content: center;
  }
}

@for $i from 1 through 12 {
  .card-item-#{$i} {
    grid-column-end: span $i;
  }
}
