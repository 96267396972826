@import '~@zf/stella-react/variables.scss';

.list-billing-item {
  outline: none;
  border-bottom: 0.2rem solid $silver-400;
  padding: 2rem $spacing-16 0rem 0rem;
}

.list-billing-item span {
  color: $silver-600;
}

.spinner {
  margin-left: $spacing-12;
}
