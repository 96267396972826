@import '~@zf/stella-react/variables.scss';

.balance {
  display: flex;
  justify-content: space-around;
}

.balance-label {
  margin-bottom: $spacing-8;
  text-align: right;
}

.balance-label-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.top-up {
  display: flex;
  align-items: flex-end;
  gap: $spacing-8;
  margin-top: $spacing-8;
  margin-bottom: $spacing-8;
}

.top-up-date {
  font-size: $font-size-12;
}

.balance-card-top-ups-wrapper {
  max-height: 30rem;
  overflow-y: scroll;
}
