@import '~@zf/stella-react/variables.scss';

.customer-details-card {
  max-height: 40rem;
}

.customer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // grid-gap: $spacing-16;
}

.address-input {
  width: 100%;
  border-bottom: 0.1rem solid transparent;
  outline: none;
  text-align: left;

  &:hover,
  &:focus {
    border-bottom: 0.1rem solid $silver-600;
  }
}

.invoice-address {
  margin-top: $spacing-16;
  display: grid;
  grid-template-areas: 'street-name street-name';
  grid-template-columns: 1fr 1fr;
  grid-row-gap: $spacing-24;
  grid-column-gap: $spacing-12;

  .street-name {
    grid-area: street-name;
  }
}

.customer-card-container {
  height: 100%;
}
