@import '~@zf/stella-react/variables.scss';

.paragraph {
  margin-bottom: 0.5rem;
  line-height: $line-height-24;
}

.header {
  margin-bottom: $spacing-8;
}
