@import '~@zf/stella-react/variables.scss';

.org-picker {
  display: grid;
  grid-template-rows: 10rem auto;
}

.org-grid {
  width: 100%;
  height: calc(100vh - 8rem);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
}

.org-grid-footer {
  height: calc(100vh - 18rem);
}

.no-organisations {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.no-organisations p:first-of-type {
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
}

.no-organisations p:nth-of-type(2) {
  padding-top: 1rem;
}
