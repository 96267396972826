@import '~@zf/stella-react/variables.scss';

.assign-to-transaction-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.with-border {
  padding-top:$spacing-32;
  width: 70rem;
  border: solid 0.2rem $silver-200;
  border-radius: $border-radius-4;
}

.assign-to-transaction-section > p {
  margin-top: 2rem;
  max-width: 75%;
}

.existing-paragraph {
  padding-bottom: 4rem;
}

.collection-date {
  max-width: 30rem;
}

.override-checkbox {
  margin-top: 4rem;
  margin-bottom:$spacing-32;
}
