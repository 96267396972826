@import '~@zf/stella-react/variables.scss';

.edit-bank-accounts-dialog {
  width: 60rem;
}

.bankaccounts-container {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
}

.edit-bank-accounts-dialog h3 {
  margin-bottom: 2rem;
  text-align: left;
}

.edit-bank-accounts-dialog > ul {
  margin-bottom: 2rem;
}

.edit-bank-accounts-dialog > div:last-of-type {
  margin-top: 2rem;
}

.heading-plus-action {
  display: flex;
}

.heading-plus-action > div:last-of-type {
  margin-left: auto;
}

.heading-plus-action > div:last-of-type > div span {
  font-size: $font-size-20;
}

.payment-method {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.payment-method > div:last-of-type {
  height: min-content;
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.payment-method * {
  line-height: normal;
}
