@import '~@zf/stella-react/variables.scss';

.bottom-cards-container {
  display: flex;
  justify-content: space-between;
}

.bottom-cards-container > div {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 1.75rem);
}
