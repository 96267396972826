@import '~@zf/stella-react/variables.scss';

.multivalue {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.multivalue-header {
  width: 100%;
  padding: $spacing-8 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.multivalue-header > div:first-of-type {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: $line-height-24;
  color: $graphite;
}

.multivalue-header > button {
  height: $line-height-24;
  padding-right: 0rem;
}

.items {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
}
