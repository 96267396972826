.info-grid-footer {
  margin-top: 2rem;
  min-height: fit-content;
  max-height: 15%;
  margin-bottom: 0;
}
.info-grid-footer-margin-right {
  margin-top: 2rem;
  min-height: fit-content;
  max-height: 15%;
  margin-right: 5rem;
  margin-bottom: 0;
}
