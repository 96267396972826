@import '~@zf/stella-react/variables.scss';

.radio-btn {
  display: flex;
  gap: $spacing-32;
  align-items: center;
  justify-content: center;
  font-size: $font-size-16;
  color: $graphite;
  line-height: $line-height-24;
}

input[type='radio'] {
  height: fit-content;
  margin: 0rem;
  margin-right: $spacing-8;
  color: $graphite;
}

.radio-btn-align{
  display: flex;
  align-items: center;
}
