@import '~@zf/stella-react/variables.scss';

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(0.5, 0.5, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.coachmark-circle {
  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    width: 2rem;
    height: 2rem;
  }

  .coachmark-inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;

    width: $spacing-16;
    height: $spacing-16;
    border-radius: 100%;

    color: $white-200;
    border: 0.2rem solid $blue-400;
    font-family: $font-default;
    font-size: $font-size-10;

    &.warning {
      border: 0.2rem solid $red-600 !important;
    }
  }

  .coachmark-outer-circle {
    position: absolute;
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 100%;
    background-color: rgba($blue-400, 0.2);

    &.warning {
      background-color: rgba($red-600, 0.2);
    }

    animation: pulse 3s infinite;
    animation-timing-function: linear;
  }

  .coachmark-outer-circle-static {
    position: absolute;

    width: $spacing-16;
    height: $spacing-16;
    border-radius: $border-radius-16;
    background-color: rgba($blue-400, 0.2);

    &.warning {
      background-color: rgba($red-600, 0.2);
    }
  }
}

.coachmark-icon {
  font-family: $font-default;
  font-weight: $font-weight-bold;
  font-size: $font-size-10;
  color: $silver-600;

  &.warning {
    color: $orange-600 !important;
  }
}

.coachmark-popup {
  position: absolute;
  top: 5rem;
  left: 0rem;
  font-family: $font-default;
  color: $silver-700;
  border: 0.1rem solid $blue-400;
  outline: none;
  border-radius: $border-radius-4;
  background: $white-200;
  padding: 1rem 2rem 1rem 2rem;
  line-height: $line-height-16;
  box-shadow: $large-box-shadow;
  transition: all 0.2s ease;
  z-index: $highest-z-index;

  &.warning {
    border: 0.1rem solid $red-600;
  }
}

.coachmark-container {
  position: inherit;
  height: 2rem;
}
