@import '~@zf/stella-react/variables.scss';

.tab-slider {
  display: flex;
  padding: $spacing-4;
  width: fit-content;
  box-sizing: border-box;

  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  white-space: nowrap;
}

.full-width {
  width: 100%;
}

.tab-slider > button {
  color: $silver-600;
  font-family: $font-default;
  font-weight: $font-weight-bold;
}

.grey {
  background-color: $silver-50;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-4;
}

.white-item {
  border: 0.2rem solid $silver-200;
}

.white {
  background-color: $white-200;
}

.white > div {
  border-radius: 0rem;
  border-right: none;
}

.white > div:first-of-type {
  border-top-left-radius: $border-radius-4;
  border-bottom-left-radius: $border-radius-4;
}

.white > div:last-of-type {
  border-top-right-radius: $border-radius-4;
  border-bottom-right-radius: $border-radius-4;
  border-right: 0.2rem solid $silver-200;
}

.tab-item {
  display: flex;
  background-color: $silver-50;
  justify-content: center;
  align-items: center;
  padding: $spacing-4 $spacing-16;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: $line-height-24;
  border-radius: $border-radius-4;
  width: 100%;
}

.left-align {
  justify-content: start;
}

.tab-item:hover {
  color: $graphite !important;
}

.tab-item span {
  margin-right: $spacing-8;
}

.selected {
  background-color: $white-200;
  color: $blue-600 !important;
}

.checkbox {
  margin-top: 0.25rem;
  margin-right: 1rem;
  height: $spacing-16;
  width: $spacing-16;
  border-radius: $border-radius-4;
  font-size: $font-size-10;
  cursor: pointer;
}
