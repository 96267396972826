@import '~@zf/stella-react/variables.scss';

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.checkbox-wrapper label {
  cursor: pointer;
  font-size: $font-size-16;
  line-height: $line-height-24;
  color: $graphite;
}

.checkbox-wrapper input[type='checkbox'] {
  // removing default appearance
  -webkit-appearance: none;
  appearance: none;
  margin: 0rem;

  // creating a custom design
  box-sizing: border-box;
  width: $spacing-16;
  height: $spacing-16;
  border-radius: $border-radius-2;
  border: $border-one $silver-600;
  outline: none;
  cursor: pointer;
}

input.checked {
  background-color: $blue-600;
  position: relative;
  border: none !important;
}

input.checked:hover {
  background-color: $blue-700;
  border: none;
}

.checkbox-wrapper input[type='checkbox']:disabled {
  border-color: $silver-400;
  background-color: transparent;
}

.checkbox-wrapper input[type='checkbox']:hover {
  border-color: $graphite;
}

.stack {
  display: grid;
  grid-template-columns: 1fr;
}

.stack > div {
  display: flex;
  align-items: center;
  grid-row-start: 1;
  grid-column-start: 1;
}

.overlay {
  color: $white-200;
  width: $spacing-16;
  height: $spacing-16;
  z-index: $overlay-z-index;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.overlay > span {
  font-size: $font-size-10;
  line-height: $font-size-10;
}

.description {
  margin: 0rem;
  padding-left: $spacing-16 + $spacing-8;
  font-size: $font-size-12;
  line-height: $line-height-24;
  color: $silver-600;
}
