@import '~@zf/stella-react/variables.scss';

.billing-item {
  display: grid;
  padding: 1rem !important;
  gap: 1rem;
  margin-top: $spacing-16;
  grid-template-columns: 5rem 1fr;
}

.row-light {
  display: flex;
  flex-direction: row;
}

.row-dark {
  display: flex;
  flex-direction: row;
}

.row-light span {
  color: $silver-600 !important;
}

.row-light p {
  color: $silver-600 !important;
  margin-left: 1rem;
}

.row-dark span {
  color: $silver-700 !important;
}

.row-dark p {
  color: $silver-700 !important;
  margin-left: 1rem;
}
