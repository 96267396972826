@import '~@zf/stella-react/variables.scss';

.wrapper {
  margin-top: $spacing-16;
  padding: 0rem $spacing-16;
}

.wrapper h3 {
  color: $graphite;
  text-align: left;
  margin-bottom: 0rem;
}

.property-groups-errors-card {
  margin: auto;
}
