@import '~@zf/stella-react/variables.scss';

.location-summary {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $spacing-16 $spacing-24;
  border: $border-one $silver-200;
  border-radius: $border-radius-8;
  box-shadow: $small-box-shadow;
}

.location-summary > div:first-of-type {
  margin-bottom: $spacing-16;
}

.location-summary > p {
  margin-bottom: $spacing-8;
}

.services-select {
  gap: $spacing-8;
}

.services-select > div {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.services-select > div > button {
  display: flex;
  flex-grow: 1;
  font-size: $font-size-16;
}

.service-warning {
  white-space: normal;
  overflow-wrap: break-word;
}
