@import '~@zf/stella-react/variables.scss';

.todos {
  padding: $spacing-16 $spacing-24;
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}

.todo-item {
  display: flex;
  justify-content: space-between;
  row-gap: $spacing-4;
  font-weight: $font-weight-bold;
  color: $graphite;
  text-decoration: none;
}

.todo-item span {
  padding-right: $spacing-4;
  transition: all 0.15s ease-out;
}

// Hover all childs css trick
.todo-item span:hover {
  padding-right: 0rem;
}

.todo-item:hover span {
  padding-right: 0rem;
}

///////////////////////////////

.todo-item div span:last-of-type {
  color: $silver-600;
  font-size: $font-size-16;
}

.arrow-paragraph {
  grid-template-columns: auto $spacing-32;
  gap: $spacing-16;
}
