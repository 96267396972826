@import '~@zf/stella-react/variables.scss';

.row {
  display: flex;

  p {
    text-align: right;
  }
}

.col-accuracy {
  display: flex;
  align-items: center;
  flex-grow: 2;
}

.invoice-line-date {
  width: max-content;
}
