@import '~@zf/stella-react/variables.scss';

.panel-icon span {
  height: min-content;
}

.column-view {
  margin-right: $spacing-16;
}

.column-view > div {
  margin-bottom: 2rem;
}

.toggle {
  display: flex;
  gap: $spacing-12;
  align-items: center;
}

.toggle > div:last-of-type {
  margin-top: $spacing-4;
}

.billing-item-horizontal-divider {
  width: 80%;
  margin: $spacing-8 auto;
}
