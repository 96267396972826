@import '~@zf/stella-react/variables.scss';

.tab-items {
  position: relative;
  display: flex;
  flex-direction: row;
  border-bottom: 0.2rem solid $silver-200;
}

.tab-item {
  margin-bottom: -0.1rem;
  padding-bottom: 0.5rem;
  font-weight: $font-weight-bold;
  color: $silver-700;
  font-size: $font-size-16;
  margin-right: 2rem;
  outline: none;
  cursor: pointer;
}

.tab-item:hover {
  color: $graphite;
}

.active {
  color: $graphite;
  border-bottom: 0.3rem solid $graphite;
}

.extra-right {
  position: absolute;
  right: 0rem;
}
