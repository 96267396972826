@import '~@zf/stella-react/variables.scss';

.actions {
  display: flex;
  gap: $spacing-16;
}

.transactions-card {
  overflow-y: auto;
}

.wrapper {
  margin-top: $spacing-8;
}

.input-container {
  padding: 0;
}

.refund-title {
  text-align: center;
  width: 100%;
  padding-bottom: $spacing-40;
  font-size: $font-size-16;
}
