@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.file-list {
  margin-top: $spacing-8;
  border-radius: $border-radius-4;
  max-height: 18rem;
  overflow-y: auto;
  background-color: $silver-50;
}

.file-list * {
  font-size: $font-size-16;
}

.file-list-column {
  border-radius: $border-radius-4;
  max-height: 18rem;
  overflow-y: auto;
  background-color: $silver-50;
  margin: $spacing-24;
}

.single-file {
  padding: $spacing-8 $spacing-12;
  margin-bottom: $spacing-8;
}

.padding {
  padding: 0rem $spacing-16 !important;
}

.scenario-upload-input {
  width: 100%;
}

.scenario-upload-input > div:first-of-type {
  margin-bottom: $spacing-8;
}

.disabled {
  display: flex;
  color: $silver-400;
}
