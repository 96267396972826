@import '~@zf/stella-react/variables.scss';

.badge {
  width: 14.5rem;
}

.search-bar {
  margin-right: $spacing-8 !important;
}

.more-actions {
  margin: auto;
  margin-left: $spacing-8;
  font-size: $font-size-16;
}

.card-body {
  min-height: 36rem;
  overflow-y: hidden;
}
