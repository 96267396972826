@import '~@zf/stella-react/variables.scss';

.existing-custom-html {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.existing-custom-html > div:first-of-type {
  color: $silver-700;
}

.existing-custom-html > div:last-of-type {
  margin-left: auto;
}

.existing-html-action-container {
  display: flex;
  gap: $spacing-8;
}
