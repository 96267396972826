@import '~@zf/stella-react/variables.scss';

.body-wrapper {
  padding: $spacing-8;
}

.body-wrapper > div > div:first-of-type {
  margin-bottom: $spacing-8;
}

.upload-input {
  display: flex;
  align-items: center;
  margin-left: $spacing-12;
  margin-right: $spacing-12;
  color: $silver-400;
}

.upload-icon {
  margin-right: $spacing-12;
  color: $silver-700;
  font-size: $font-size-20;
}

.attachment-card {
  min-height: 35rem;
}
