@import '~@zf/stella-react/variables.scss';

.asking-period {
  padding: $spacing-16;
  display: flex;
  background-color: $silver-200;
  border-radius: $border-radius-8;
}

.date-values {
  display: flex;
  white-space: nowrap;
}

.gap {
  gap: 2rem;
}

.receiving-periods {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
