@import '~@zf/stella-react/variables.scss';

.consumption-group-item {
  display: flex;
  align-items: flex-start;
  gap: $spacing-12;
  margin-top: $spacing-8;
  margin-bottom: $spacing-8;
}

.consumption-group-item-icon {
  margin-top: $spacing-8;
}

.inherited-from {
  margin-top: $spacing-8;
}

.estimated-anual-value {
  margin: 0 !important;
}

.consumption-group-item > div:first-of-type {
  width: 40%;
}

.consumption-group-item > div:last-of-type {
  width: 17%;
}

.input-wrapper {
  padding: 1.5rem 1rem 2rem 1rem;
}
