@import '~@zf/stella-react/variables.scss';

.data-grid-actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-16;
}

.data-grid-actions li > div {
  padding-left: 2rem;
  padding-right: 2rem;
}
