@import '~@zf/stella-react/variables.scss';

.date-picker {
  &:hover,
  &:focus {
    color: $silver-700;
  }
}

.date-range-picker {
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  max-width: 23rem;
  min-width: 20rem;
  .dash {
    margin-top: -10%;
    margin-left: 35%;
  }
}

.range-margin {
  padding: 3%;
}

.picker-clear {
  font-family: inherit;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: $spacing-8;
  padding: $spacing-16;
  border-top: 0.1rem solid $blue-200;

  &:hover {
    background: $blue-200;
    color: $white-200;
  }
}

.picker-clear p {
  text-align: center;
}

.calendar-wrapper {
  width: 35rem;
}
