@import '~@zf/stella-react/variables.scss';

@keyframes notification-pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(0.5, 0.5, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounce-in {
  from {
    opacity: 0;
    transform: translate3d(0, 300rem, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -2.5rem, 0);
  }
  75% {
    opacity: 1;
    transform: translate3d(0, 1rem, 0);
  }
  90% {
    opacity: 1;
    transform: translate3d(0, -0.5rem, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce-out {
  20% {
    opacity: 1;
    transform: translate3d(0, -2rem, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 200rem, 0);
  }
}

.notification-container {
  position: relative;
  background-color: $white-200;
  box-shadow: $small-box-shadow;
  overflow: hidden;
  margin-top: 1rem;
  width: 51rem;
  opacity: 0;
  outline: none;
  animation: bounce-in cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  border-radius: $border-radius-4;
  z-index: $highest-z-index;

  &.enter {
    opacity: 1;

    &:hover,
    &:focus {
      box-shadow: $small-box-shadow;
    }
  }

  &.exit {
    opacity: 1;
    animation: bounce-out cubic-bezier(0.215, 0.61, 0.355, 1) 2s;
  }

  .remove {
    position: absolute;
    top: 1rem;
    right: $spacing-16;
    font-size: $font-size-20;
    color: $red-600;
  }

  .notification {
    display: flex;
    gap: $spacing-8;
    align-items: center;
    padding: $spacing-16 $spacing-24;
    font-family: $font-default;
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
    line-height: $line-height-24;
    cursor: pointer;
  }

  a {
    font-weight: $font-weight-bold;
  }

  button {
    margin-left: auto;
  }

  &.notification-info {
    background-color: $silver-700;
    color: $white-200;

    .notification-time {
      background-color: $blue-400;
    }
  }

  &.notification-success {
    border: 0.1rem solid $green-600;

    .notification-icon {
      color: $green-600;
    }

    .notification-time {
      background-color: $green-600;
    }
  }

  &.notification-warning {
    border: 0.1rem solid $yellow-600;

    .notification-icon {
      color: $yellow-600;
    }

    .notification-time {
      background-color: $yellow-600;
    }
  }

  &.notification-error {
    border: 0.1rem solid $red-600;

    .notification-icon {
      color: $red-600;
    }

    .notification-time {
      background-color: $red-600;
    }
  }
}
