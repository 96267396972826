@import '~@zf/stella-react/variables.scss';

.btn-style {
  width: min-content;
  font-size: $font-size-16;
  padding: 0rem;
  padding-right: 0.5rem;
}

.btn-style * {
  font-size: $font-size-16 !important;
  line-height: normal;
  margin-right: 0.5rem !important;
}
