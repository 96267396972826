@import '~@zf/stella-react/variables.scss';

.billing-item {
  display: flex;
  gap: $spacing-4;
  width: fit-content;
}

.billing-item > span {
  font-size: $font-size-16;
}

.extra-left {
  display: flex;
}

.extra-left > div:last-of-type {
  margin-left: auto;
  margin-right: $spacing-4;
}

.info {
  padding: $spacing-16;
}

.meter-completeness {
  margin-top: $spacing-16;
}
