@import '~@zf/stella-react/variables.scss';

.shadow-container {
  position: relative;
  background-color: $white-200;
  padding: 2rem;
  border-radius: $border-radius-4;

  -webkit-box-shadow: 0px 0.2rem 1rem 0.2rem $silver-200;
  -moz-box-shadow: 0px 0.2rem 1rem 0.2rem $silver-200;
  box-shadow: 0px 0.2rem 1rem 0.2rem $silver-200;
}
