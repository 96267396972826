@import '~@zf/stella-react/variables.scss';

.comment {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: $spacing-16;
  padding-top: $spacing-8;
  font-family: $font-default;
  color: $silver-700;
  word-break: break-word;

  .comment-bubble {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
    box-sizing: border-box;
    background-color: rgba($silver-50, 0.7);
    border-radius: $border-radius-4;
    padding: $spacing-8;
    outline: none;

    .name {
      flex: 2;
      font-size: $font-size-16;
      font-weight: $font-weight-bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .time {
      font-size: $font-size-12;
      color: $silver-700;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: right;
      align-items: center;
    }

    .message {
      font-size: $font-size-16;
      white-space: break-spaces;
    }
  }
}

.avatar {
  padding-top: $spacing-8;
  padding-left: $spacing-8;
}

.first-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-8;
}
