@import '~@zf/stella-react/variables.scss';

.button {
  display: flex;
  flex-direction: row;
  gap: $spacing-8;
  justify-content: center;
  align-items: center;
  padding: $spacing-8 $spacing-24;
  border-radius: $border-radius-4;
  border: none;
  font-weight: $font-weight-bold;
  font-family: $font-default;
  font-size: $font-size-16;
  line-height: $line-height-24;
  cursor: pointer;
  // Prevent size changes due to border on/off
  border: solid 0.1rem transparent;
}

// Types
.primary {
  background-color: $blue-600;
  color: $white-200;
  transition: all .150s ease-in-out;
}

.primary:hover {
  background-color: $blue-700;
}

.primary:active {
  background-color: $blue-400;
}

.secondary {
  background-color: $white-200;
  color: $blue-600;
  border: 0.1rem solid $blue-600;
  padding: $spacing-8 $spacing-24;
}

.secondary:hover {
  background-color: $blue-50;
  border-color: $blue-700;
  color: $blue-700;
}

.secondary:active {
  background-color: $white-200;
  border-color: $blue-400;
  color: $blue-400;
}

.text {
  color: $silver-600;
  background-color: transparent;
  outline: none;
}

.text:hover {
  color: $blue-600;
}

.text:active {
  color: $blue-400;
}

// Sizes
.small {
  padding: $spacing-4 $spacing-16;
}

.standard {
  font-size: $font-size-16;
}

// Danger
.primary-danger {
  background-color: $red-600;
}

.primary-danger:hover {
  background-color: $red-700;
}

.primary-danger:active {
  background-color: $red-400;
}

.secondary-danger {
  border-color: $red-600;
  color: $red-600;
}

.secondary-danger > object {
  border-color: rgba(0, 145, 247, 0.2) rgba(0, 145, 247, 0.2) $red-400 rgba(0, 145, 247, 0.2);
}

.secondary-danger:hover {
  background-color: $red-50;
  border-color: $red-700;
  color: $red-700;
}

.secondary-danger:active {
  background-color: $white-200;
  border-color: $red-400;
  color: $red-400;
}

.text-danger:hover {
  color: $red-700;
}

.text-danger:active {
  color: $red-400;
}

// Disabled
.primary-disabled,
.primary-disabled:hover,
.primary-disabled:active {
  cursor: not-allowed;
  background-color: $silver-200;
  color: $silver-400;
}

.secondary-disabled,
.secondary-disabled:hover,
.secondary-disabled:active {
  cursor: not-allowed;
  background-color: transparent;
  border-color: $blue-200;
  color: $blue-200;
  outline: none;
}

.text-disabled,
.text-disabled:hover,
.text-disabled:active {
  cursor: not-allowed;
  color: $silver-200;
}

// Icon only
.primary-standard-icon-only,
.secondary-standard-icon-only,
.text-standard-icon-only {
  width: 4rem;
  height: 4rem;
  padding: 0rem !important;
}

.text-standard-icon-only:hover {
  background-color: $silver-200;
}

.text-standard-icon-only:active {
  background-color: transparent;
}

.text-small-icon-only-disabled,
.text-small-icon-only-danger-disabled {
  width: 2.4rem;
  height: 2.4rem;
  padding: 0rem !important;
}

.text-standard-icon-only-danger:hover {
  background-color: $red-200;
  color: $red-600 !important;
}

.text-standard-icon-only-danger:active {
  background-color: transparent;
  color: $red-700 !important;
}

.primary-small-icon-only,
.secondary-small-icon-only,
.primary-small-icon-only-danger,
.secondary-small-icon-only-danger,
.primary-small-icon-only-disabled,
.primary-small-icon-only-danger-disabled {
  width: 2.4rem;
  height: 2.4rem;
  padding: 0rem !important;
  border-radius: 100%;
  border-width: 0.16rem;
}

.text-small-icon-only,
.text-small-icon-only-danger {
  width: 2.4rem;
  height: 2.4rem;
  padding: 0rem !important;
  border-radius: $border-radius-4;
}

.text-small-icon-only-danger-disabled,
.text-standard-icon-only-danger-disabled {
  color: $red-200 !important;
}

.text-small-icon-only:hover {
  background-color: $silver-200;
}

.text-small-icon-only-danger:hover {
  background-color: $red-200;
  color: $red-600 !important;
}

.text-small-icon-only-danger:active {
  color: $red-700 !important;
  background-color: transparent;
}

.text-standard-icon-only-disabled,
.text-standard-icon-only-danger-disabled,
.primary-standard-icon-only-disabled,
.secondary-standard-icon-only-disabled {
  width: 4rem;
  height: 4rem;
  padding: 0rem !important;
}

.text-small-icon-only-danger-disabled:hover,
.text-small-icon-only-danger-disabled:active,
.text-standard-icon-only-danger-disabled:hover,
.text-standard-icon-only-danger-disabled:active {
  background-color: transparent;
  color: $red-200 !important;
}

.icon-only-rounded {
  border-radius: 100%;
}

.tooltip {
  width: fit-content;
}

.no-side-padding {
  padding: $spacing-8 0rem;
}
