@import '~@zf/stella-react/variables.scss';

.invoice-address > div:first-of-type {
  margin-top: $spacing-16;
}

.invoice-address > div:first-of-type > p {
  max-width: 70%;
  margin-left: 0rem;
  white-space: normal;
  overflow-wrap: break-word;
}

.invoice-address-inputs {
  display: grid;
  padding-top: $spacing-16;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: $spacing-24;
}

.prefill-btn {
  margin-left: auto;
}

.street {
  grid-column: 1/3;
}

.country {
  grid-column: 3/5;
}
