@import '~@zf/stella-react/variables.scss';

.timeline-item {
  display: grid;
  grid-template-rows: 4rem 1fr;
  gap: 1rem;
}

.grey-part {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: $spacing-16;
  margin-bottom: 1rem;
  background-color: $silver-50;
  border-left: 0.5rem solid $blue-200;
  border-radius: $border-radius-4;
}

.left {
  border-right: 0.3rem solid $silver-200;
  margin-right: 1rem;
}

.middle p {
  padding-top: 0.2rem;
  font-size: $font-size-16;
  color: $silver-700;
}

.left p:nth-of-type(2) {
  padding-top: 0.2rem;
  font-size: $font-size-16;
  color: $silver-700;
}

.white-part {
  height: 4rem;
  line-height: $line-height-32;
  padding-left: 1rem;
  margin-left: 0.3rem;
  border-left: 0.4rem solid $silver-200;
  font-size: $font-size-16;
  color: $silver-600;
}

.right {
  position: relative;
}

.actions-grid {
  width: fit-content;
  float: right;
  display: grid;
  grid-template-columns: min-content min-content min-content;
  gap: 2rem;
  font-size: $font-size-20;
}

.btn-invoice-preview {
  margin: 0;
  position: absolute;
  top: 50%;
  right: -14%;
  transform: translate(-50%, -50%);
}

.btn-invoice-preview * {
  font-weight: $font-weight-bold;
  color: $blue-600;
  line-height: normal;
}
