@import '~@zf/stella-react/variables.scss';

.tree-wrapper {
  width: 25rem;
}

.tree {
  background-color: $white-200;
  padding: $spacing-16;

  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-8;

  li {
    white-space: nowrap;
  }

  li:hover {
    color: $silver-700;
  }
}

.tree > ul > li {
  margin: 0rem;
  display: flex;
  align-items: center;
}

.tree > ul > li:first-of-type {
  border: none;
  margin: 0rem;
  padding: 0rem;
}

.tree > ul > li:nth-of-type(2) {
  border-top: 0.1rem solid $silver-200;
  margin-top: $spacing-16;
}

.tree-selected p {
  color: $blue-600;
}

.tree-item-selected {
  color: $blue-600;
  background-color: $silver-50;
}

.tree-item {
  line-height: $line-height-32;
  font-size: $font-size-16;
  line-height: $font-size-25;
  padding: 1rem;
}

.tree-item {
  text-align: left;
}
