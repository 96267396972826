@import '~@zf/stella-react/variables.scss';

.tile-body {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  border: $border-one $silver-200;
  border-radius: $border-radius-8;
  align-items: flex-start;
  flex-shrink: 0;
}

.hoverable {
  border: $border-one $blue-400;
}
