@import '~@zf/stella-react/variables.scss';

.checkbox-container {
  display: flex;
  width: fit-content;
  cursor: pointer;
  outline: none;

  .checkbox {
    display: none;
    margin: 0;
    padding: 0;

    & + .styled-checkbox {
      display: flex;
      position: relative;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      background-color: $white-200;
      border: 0.2rem solid $silver-400;
      border-radius: $border-radius-4;
      outline: none;
      color: $white-200;
      font-size: $font-size-16;
      line-height: $line-height-24;
      cursor: pointer;
      margin: $spacing-4 0rem;
    }

    & + .styled-checkbox-disabled {
      display: flex;
      position: relative;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      background-color: $white-200;
      border: 0.2rem solid $silver-400;
      border-radius: $border-radius-4;
      outline: none;
      color: $white-200;
      font-size: $font-size-16;
      line-height: $line-height-24;
      cursor: pointer;
    }

    &:checked + .styled-checkbox {
      border-color: $blue-600;
      background-color: $blue-600;
      color: $white-200;
    }

    &:checked + .styled-checkbox-disabled {
      border-color: $silver-600;
      background-color: $silver-600;
      color: $white-200;
    }
  }

  &.has-label + .styled-checkbox {
    margin: 0rem 2rem 0rem 2rem;
  }
}

.checkbox-container > button {
  text-align: left;
  font-family: $font-default;
}

.label-text {
  overflow-wrap: break-word;
  font-size: $font-size-16 !important;
  color: $graphite !important;
}
