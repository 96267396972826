@import '~@zf/stella-react/variables.scss';
@import './filter-tags.module.scss';

.tag-group-list {
  display: grid;
  min-width: 20rem;
  gap: $spacing-8;
  padding: 2rem;
}

.tag-group {
  padding-right: 0.75rem;
  width: fit-content;
  white-space: nowrap;
}
