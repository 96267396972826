@import '~@zf/stella-react/variables.scss';

.table-wrapper {
  height: calc($tab-body-height - 8.4rem - 3.5rem); // header height & tab padding
  overflow-y: auto;
}

.entity_attachment_visibility {
  display: flex;
  gap: $spacing-4;
}

.origin-column-extra-info {
  color: $silver-600;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: $spacing-8;
  padding: 0;
}

.origin-column-extra-info :hover {
  text-decoration: underline;
}

.origin-multiple-button-icon {
  margin-right: $spacing-4;
}

.origin-wrapper {
  margin: 1rem;
}
