@import '~@zf/stella-react/variables.scss';

.icon-paragraph {
  display: flex;
  align-items: flex-start;
  gap: $spacing-8;
  font-size: $font-size-16;
  line-height: $line-height-24;
}

.icon-paragraph-right {
  text-align: right;
  grid-template-columns: min-content 3rem;
}

.icon-paragraph-right * {
  overflow: visible !important;
}

.icon-wrapper > span {
  line-height: $line-height-24;
}
