@import '~@zf/stella-react/variables.scss';

.wrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}

.name-delete {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.name-delete h2 {
  color: $silver-600;
}

.name-delete span {
  text-align: right;
}

.collection-flow-steps {
  display: flex;
  flex-wrap: wrap;
}

.collection-flow-steps > button {
  margin-top: 1 $spacing-8;
}

.collection-flow-steps > div {
  margin-right: $spacing-16;
}

.icons {
  display: grid;
  grid-template-columns: min-content min-content;
  gap: $spacing-8;
  position: absolute;
  right: 0rem;
  width: fit-content;
}

.add-btn {
  margin-top: $spacing-16;
}
