@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: flex;
  height: fit-content;
  width: fit-content;
}

.clickable-icon {
  cursor: pointer;
  padding: 0.5rem;
  font-size: $font-size-16;
}

.clickable-icon * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.default-hover:hover {
  background-color: $blue-200;
  border-radius: $border-radius-4;
}

.danger:hover {
  color: $red-600 !important;
}

.danger-hover:hover {
  color: $red-600 !important;
  background-color: $red-200;
  border-radius: $border-radius-4;
}

.active {
  color: $silver-600;
}

.active:hover {
  color: $blue-400;
}

.disabled span {
  color: $silver-400;
  cursor: default !important;
}

.big {
  font-size: $font-size-20;
}
