@import '~@zf/stella-react/variables.scss';

.heading {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.heading > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.filters {
  display: flex;
  width: 100%;
  height: 4rem;
  gap: $spacing-16;
  justify-content: space-between;
}
