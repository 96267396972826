@import '~@zf/stella-react/variables.scss';

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-iframe-preview {
  width: 100%;
  height: 100%;
  border: none;
}
