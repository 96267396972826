@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.trail-and-tree-item {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 0rem $spacing-16;
}

.tree-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 4rem;
}

.tree-item-dark {
  background-color: $silver-200;
}

.tree-item-top-right {
  display: flex;
  gap: $spacing-16;
}

.tree-item-standard-actions {
  display: none;
}

.trail-and-tree-item:hover {
  .tree-item-standard-actions {
    display: unset;
  }
}

.tree-item-top {
  display: flex;
  align-items: center;
  gap: $spacing-16;
}

.tree-item-top-left {
  display: flex;
  gap: $spacing-8;
  cursor: pointer;
}

.extend-height-trail > div {
  height: 6rem;
}

.extend-height-trail > div:last-of-type {
  height: 2 $spacing-8;
}

.extend-height-tree-item {
  height: 6rem;
  justify-content: end;
}

.tree-item-top-left > span {
  width: $spacing-16;
}

.tree-item-top-left > p {
  width: fit-content;
  white-space: nowrap;
  text-align: left;
  margin-left: 0rem;
}

.info {
  width: 100%;
  display: flex;
  align-items: center;
}

.info > p {
  text-align: right;
}

.tree-item-bottom > p {
  padding-left: $spacing-48;
  font-size: $font-size-12;
  line-height: calc($spacing-4 + $line-height-16);
  color: $silver-600;
}

// Trail
.trail-wrapper {
  display: flex;
}

.trail-dark > div {
  border-left: 0.2rem solid $silver-400;
}

.trail {
  height: 4rem;
  width: 2rem;
  border-left: 0.2rem solid $silver-200;
}

.rounded {
  height: 2rem;
  border-bottom-left-radius: $border-radius-8;
}

.custom-actions {
  display: flex;
  gap: 1rem;
}
