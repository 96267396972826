@import '~@zf/stella-react/variables.scss';

.dropdown-container {
  position: inherit;
  width: 100%;
}

.dropdown {
  cursor: pointer;
}

.dropdown-icon {
  padding-bottom: $spacing-4;
}

.dropdown-label {
  width: 100%;
  cursor: pointer;
  padding: $spacing-8 $spacing-16;
  padding-right: $spacing-40;
}

.dropdown-input {
  display: none;
}

.dropdown-popup {
  font-family: $font-default;
  z-index: $input-popup-z-index;
  color: $silver-700;
  outline: none;
  background: $white-200;
  min-width: 20rem;
  max-width: 100rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: $border-radius-8;

  .dropdown-items {
    position: relative;
    font-size: $font-size-16;
    color: $graphite;
    overflow-y: auto;
    max-height: 42rem;
    outline: none;

    .dropdown-item {
      cursor: pointer;
      min-width: $spacing-12;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-40;
      outline: none;
      line-height: $line-height-24;

      &:hover {
        background: $silver-50 !important;
      }

      &.focus {
        background: $silver-50 !important;
      }

      &.selected {
        background: $blue-50 !important;
      }
    }

    .dropdown-item-non-semantic {
      padding: $spacing-8 $spacing-16;
    }

    .dropdown-checkbox {
      margin-right: 0.5rem;
    }
  }

  .dropdown-search {
    margin-bottom: 0.5rem;
  }
}

.dropdown-popup-grid {
  display: grid;
  grid-template-rows: min-content 1fr;
}

.filters-wrapper {
  display: grid;
  grid-template-columns: min-content auto;
  border-bottom: 0.1rem solid $silver-200;
}

.lbl-filters {
  color: $silver-600;
  font-weight: $font-weight-bold;
  padding: 2rem;
}

.lbl-details {
  color: $silver-600;
  font-weight: $font-weight-bold;
}

.filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: $spacing-16;
  padding: 2rem;
  width: 70rem;
}

.list-and-details {
  display: grid;
  grid-template-columns: 50rem 50rem;
}

.details {
  padding: 2rem 0.5rem 2rem 2rem;
}

.focussedEntity {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 1rem;
}

.dropdown-item * {
  outline: none !important;
}

.searchbar {
  padding: 2rem;
  border-bottom: 0.1rem solid $silver-200;
}
