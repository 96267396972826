@import '~@zf/stella-react/variables.scss';

.items-list {
  padding: 2rem;
  text-align: left;
  border-left: 0.1rem solid $silver-200;
}

.items-list h3 {
  color: $graphite;
  margin-bottom: 0rem;
}

.items-list p {
  line-height: $line-height-24;
}

.items-list-heading {
  margin-bottom: 2rem;
}

.list-wrapper {
  overflow-y: auto;
  height: 59.5rem;
}
