@import '~@zf/stella-react/variables.scss';

.no-customers-yet-label {
  font-size: $font-default !important;
  color: $silver-600;
}
.footer {
  text-align: center;
  margin: auto;
  padding-top: $spacing-16;

  button {
    margin-inline: auto;
  }
}
