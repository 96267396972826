@import '~@zf/stella-react/variables.scss';

.add-edit-wrapper {
  width: 60rem;
  margin: auto;
}

.inputs {
  display: grid;
  gap:$spacing-32;
}

.toggle {
  margin-top: 4rem;
}

.user-name {
  color: $graphite;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
}

.warning-message {
  color: $red-400;
}
