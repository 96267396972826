@import '~@zf/stella-react/variables.scss';

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: $border-radius-4;
}

.icon-wrapper {
  width: min-content;
  height: min-content;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: $font-size-20;
}
