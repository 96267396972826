@import '~@zf/stella-react/variables.scss';

.paragraph {
  font-family: $font-default;
  font-weight: $font-weight-regular;
  font-size: $font-size-16;
  line-height: $line-height-24;
  color: $graphite;
  margin: auto;
  width: 100%;
  overflow-wrap: break-word;
  outline: none !important;
}

.text-bold {
  font-weight: $font-weight-bold;
}
