@import '~@zf/stella-react/variables.scss';

.reset-card {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.icon-text {
  width: min-content;
  display: grid;
  margin: auto;
}

.icon-text * {
  color: $silver-600;
  font-weight: $font-weight-bold;
  white-space: nowrap;
  margin: auto;
  text-align: center;
}

.icon {
  font-size: $font-size-20;
}

.progress-bar {
  margin-top: $spacing-16;
}

.progress-bar > div {
  margin: auto;
}

.progress {
  margin-top: 1rem;
  color: $silver-600;
}

.details {
  margin-top: 2rem;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
}

.details:hover {
  color: $blue-600;
}
