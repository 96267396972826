@import '~@zf/stella-react/variables.scss';

.no-issues-yet-label {
  font-size: $font-size-16 !important;
  color: $silver-600;
}

.footer {
  text-align: center;
  margin: auto;
  padding-top: $spacing-16;

  button {
    margin-inline: auto;
  }
}
