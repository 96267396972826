@import '~@zf/stella-react/variables.scss';

.input-wrapper {
  margin-top: 3.5rem;
  position: relative;
}

.add-margin-top {
  margin-top: 2rem;
}

.terminate-add-measurement {
  background-color: $silver-50;
  padding: 0.25rem;
  border-radius: $border-radius-4;
  margin-top: $spacing-8;
  margin-bottom: 2rem;
  p {
    text-align: left;
  }
}

.measurement-header {
  text-align: left;
}

.multivalue-wrapper {
  max-height: 45rem;
}

.date-hour-minute {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: $spacing-16;
}

.prev-date-hour-minute {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-16;
}

.date-hour-no-minute {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $spacing-16;
}

.date-only {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-16;
}

.margin-top {
  margin-top: 4rem;
}

.actions-edit-info {
  margin-bottom: $spacing-16;
}

.box {
  padding: $spacing-16;
  display: grid;
  grid-template-columns: 10rem 7rem 15rem 7rem 10rem;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'date datepick datepick datepick dateTwo'
    'smallBall  line ball line2 smallBall2'
    'time . timepick . time2'
    'value . valuepick . value2';
}

.date {
  grid-area: date;
  margin: auto;
  text-align: center;
}

.dateTwo {
  grid-area: dateTwo;
  margin: auto;
  text-align: center;
}

.datePick {
  grid-area: datepick;
  align-self: flex-end;
  margin-bottom: $spacing-12;
  justify-self: center;
}

.border {
  outline: none;
  border: none;
  background: transparent;
}

.smallBall {
  display: flex;
  grid-area: smallBall;
  justify-content: flex-end;
  align-items: center;
}
.smallBallShape {
  width: $spacing-8;
  height: $spacing-8;
  background-color: $white-200;
  border: 0.1rem solid $blue-200;
  border-radius: 100%;
}

.smallBall2 {
  display: flex;
  grid-area: smallBall2;
  justify-content: flex-start;
  align-items: center;
}

.ball {
  grid-area: ball;
  width: 2rem;
  height: 2rem;
  border: 0.1 solid rgba($blue-400, 0.4);
  background-color: rgba($blue-400, 0.2);
  border-radius: 100%;
  align-self: center;
  z-index: 2;
  display: grid;
  justify-self: center;
}

.line {
  grid-area: line;
  background-color: rgba($silver-600, 0.5);
  height: 0.3rem;
  align-self: center;
}

.line2 {
  grid-area: line2;
  background-color: rgba($silver-600, 0.5);
  height: 0.3rem;
  align-self: center;
}

.time {
  grid-area: time;
  justify-self: flex-end;
  text-align: center;
  color: $silver-600;
}
.time2 {
  grid-area: time2;
  text-align: center;
  color: $silver-600;
}

.timePick {
  grid-area: timepick;
  align-self: flex-start;
  justify-self: center;
  margin-top: $spacing-12;
  max-width: 15rem;
}

.hour-minutes {
  display: grid;
  gap: $spacing-12;
  grid-template-columns: 6rem 6rem;
}

.value {
  grid-area: value;
  padding-top: $spacing-12;
  justify-self: flex-end;
  align-self: flex-start;
  color: $silver-600;
  text-align: center;
}
.valuePick {
  grid-area: valuepick;
  padding-top: $spacing-12;
  align-self: flex-start;
  justify-self: center;
}
.value2 {
  grid-area: value2;
  padding-top: $spacing-12;
  align-self: flex-start;
  color: $silver-600;
  text-align: center;
}

.measurement-wrapper {
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
}

.rcnf-opt-msmt-wrapper {
  display: flex;
  flex-direction: column;
  border: $border-one $silver-600;
  border-radius: $border-radius-8;
  padding: $spacing-16 $spacing-24;
  margin-top: $spacing-32;
}

.remove-msmt-btn {
  margin-left: auto;
  margin-bottom: $spacing-8;
}

.input-container-class {
  position: relative;
  width: auto !important;
  padding-bottom: 0rem;
  padding-top: $spacing-8;
  margin-bottom: 0rem;
}

.rcnf-add-msmt-btn {
  display: flex !important;
  margin-top: $spacing-12;
  justify-content: flex-end !important;
}

.mutation-date {
  width: fit-content;
}
