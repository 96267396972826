@import '~@zf/stella-react/variables.scss';

.advance-info {
  position: relative;
  width: 40rem;
  padding: 1rem 2rem;
}

.advance-info p:first-of-type {
  color: $silver-600;
}

.empty {
  color: $silver-600;
}

.amount {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
}

.status-wrapper {
  width: fit-content !important;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

.spinner-wrapper {
  padding: 2rem;
}

.advance-info-tax-rate {
  color: $silver-700;
  text-align: right;
}
