.values-table-measurements {
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex: 1 1 auto;
}

.values-table-consumptions {
  padding-top: 2rem;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.issues-link {
  text-decoration: none !important;
}
