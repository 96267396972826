@import '~@zf/stella-react/variables.scss';

.warning {
  margin-top: $spacing-32;
}

.warning > div:first-of-type {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-12;
}

.warning > p {
  max-width: 80%;
}
