@import '~@zf/stella-react/variables.scss';

.empty-state {
  display: flex;
  gap: $spacing-8;
}

.text-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $spacing-8;
}

.text-part > p:last-of-type {
  font-size: $font-size-12;
  line-height: $font-size-12;
}

.empty-state-horizontal {
  display: flex;
  gap: $spacing-8;

  .text-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text-part > p:first-of-type {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }

  .text-part > p:last-of-type {
    font-size: $font-size-12;
    line-height: $font-size-12;
    margin: 0rem;
  }
}

.empty-state-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
