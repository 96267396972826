@import '~@zf/stella-react/variables.scss';

.filter-button {
  display: flex;
  border-radius: $border-radius-4 $border-radius-4 0rem 0rem !important;
  z-index: 2000;
  box-shadow: none !important;
  margin-bottom: -0.1rem;
  transition: none;
  color: $silver-600;
  font-weight: $font-weight-bold;
  font-size: $font-size-16;
  background-color: $white-200;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: $font-size-20;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.count {
  font-size: $font-size-20;
}
