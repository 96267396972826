@import '~@zf/stella-react/variables.scss';

.basic-stats {
  flex: 0 1 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 2rem;
  padding: 1rem 0rem;
  font-size: $font-size-16;
  color: $silver-700;
  background-color: $silver-200;
  border-radius: $border-radius-4;
  border: 0.2rem solid $silver-200;
}

.basic-stats > div:first-of-type {
  border-right: 0.2rem solid $silver-200;
}

.stat-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0rem $spacing-32;
}

.stat-column > div:nth-child(even) {
  text-align: right;
  color: $graphite;
  font-weight: $font-weight-bold;
}
