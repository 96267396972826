@import '~@zf/stella-react/variables.scss';

.yearly-estimate {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-4;
}

.estimated-yearly-consumption-value {
  margin-right: $spacing-8;
}

.estimated-consumptions-card-item {
  align-items: center;
}
