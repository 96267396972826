@import '~@zf/stella-react/variables.scss';

.navigator {
  position: absolute;
  right: -2.4rem;
  display: flex;
}

.back-to-list {
  margin: auto;
  height: fit-content;
  color: $silver-600;
  margin-right: 1.6rem;
}

.back-to-list span {
  margin-right: 1rem;
}

.dot {
  height: fit-content;
  margin: auto;
  margin-right: 1.6rem;
}

.dot span {
  font-size: $font-size-20;
}

.wrapper {
  color: $graphite;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 1rem;
  align-items: center;
}

.position {
  width: 100%;
  text-align: center;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: $silver-600;
  font-weight: $font-weight-bold;
}

.navigate {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  color: $silver-600;
  font-size: $font-size-20;

  :hover {
    color: $blue-600;
    cursor: pointer;
  }
}

.navigator-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border: $border-one $silver-200;
  background-color: white;
  border-radius: $border-radius-4;
  font-size: $font-size-16;
  color: $silver-600;
}

.navigator-btn:hover,
.navigator-btn > span:hover {
  color: $graphite !important;
}
