@import '~@zf/stella-react/variables.scss';

.tenant-btn {
  padding: $spacing-16 2rem $spacing-16 2rem;
  display: grid;
  gap: $spacing-8;
  color: $silver-600;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  border: 0.2rem solid $silver-600;
  border-radius: $border-radius-8;
  opacity: 0.7;
}

.grid-view {
  grid-template-columns: min-content 1fr;
}

.tenant-btn:hover {
  color: $blue-600;
  border-color: $blue-600;
}

.tenant-btn * {
  line-height: normal;
}

.small {
  width: fit-content;
  padding: 0.5rem $spacing-16 0.5rem $spacing-16;
  font-size: $font-size-16;
  border-radius: $border-radius-4;
  border: 0.1rem solid $silver-600;
  opacity: 1;
}

.no-trigger {
  cursor: default !important;
}
