@import '~@zf/stella-react/variables.scss';

.card {
  height: 61vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.info-grid {
  width: 100%;
  grid-column-end: span 4;
}

.buttons {
  display: flex;
  gap: $spacing-4;
  justify-content: right;
}

.collection-date-picker {
  width: 50%;
}

.outgoing-bakking-transaction-cards-container {
  height: 100%;
}
