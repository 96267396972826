@import '~@zf/stella-react/variables.scss';

.inputs-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-8;
  white-space: nowrap;
  margin-bottom: $spacing-8;
}

.inputs-wrapper > p {
  width: fit-content;
}

.info {
  margin-bottom: $spacing-8;
}

.attachment {
  padding: 0rem $spacing-16;
  margin-top: $spacing-8;
}

.expanded {
  border-radius: $border-radius-8;
  border: 0.2rem solid $silver-200;
  margin: 1rem 0rem;
  margin-bottom: $spacing-24;
  padding: $spacing-16;
}

.default {
  margin-bottom: $spacing-16;
}

.name-input {
  margin-top: 2rem;
  width: 50%;
}

.flag-culture {
  display: flex;
}

.flag-culture > p {
  margin-left: 1rem;
}

.minimized {
  display: flex;
}

.minimized > div:first-of-type {
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.dialog-wrapper {
  width: 80rem;
}

.divider {
  margin: $spacing-24 0rem;
}
