@import '~@zf/stella-react/variables.scss';

.badge {
  display: inline-block;
  width: fit-content;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: $line-height-24;
  border-radius: $border-radius-4;
  padding: $spacing-4 $spacing-16;
  text-align: center;
}
