@import '~@zf/stella-react/variables.scss';
.history {
  position: relative;
}

.history > div {
  display: flex;
  gap: $spacing-32;
  margin-bottom: $spacing-24;
}

.history > div > div span {
  width: 100%;
  text-align: left;
  margin-bottom: $spacing-4;
}

.history > div > div p {
  text-align: left;
}
