@import '~@zf/stella-react/variables.scss';

.table {
  outline: none;
}

.static-table {
  width: 100%;

  &.accent {
    border: 0.2rem dashed $blue-200;
  }
}

.static-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  font-family: $font-default;
  padding: 1rem;
  padding-left: 2rem;
  background: $white-200;
  color: $silver-600;
  font-weight: $font-weight-bold;
  font-size: $font-size-16;

  &.accent {
    background-color: $silver-200;
  }
}

.static-table-content {
  overflow-y: auto;
  height: 100%;
}

.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  font-family: $font-default;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  background: $white-200;
  width: 100%;
  font-weight: $font-weight-regular;
  color: $silver-600;
  text-transform: uppercase;
  box-shadow: $small-box-shadow;
}

.sortable-header {
  outline: none;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    color: $silver-700;
  }
}

.table-sort-icon {
  display: inline-block;
  margin-right: 0.5rem;
}

.table-item {
  position: relative;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $font-default;
  font-size: $font-size-16;
  padding-left: 2rem;
  height: 100%;
  box-sizing: border-box;
  color: $silver-700;
  overflow-x: hidden;

  &.static-table-item {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &.hover {
      background: $silver-50;
      opacity: 1 !important;

      &.accent {
        background-color: $white-200;
      }
    }
  }

  &.hover,
  &.selected {
    background: rgba($white-200, 1);
  }
}

.table-item-datafield {
  box-sizing: border-box;

  &.fixed-height-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.hidden {
    opacity: 0;
  }
}

.table-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.simple-tooltip {
  border: 0.1rem solid $silver-200 !important;
  border-radius: $border-radius-4 !important;
  font-family: $font-default !important;
  font-size: $font-size-16 !important;
  box-shadow: $small-box-shadow !important;
  opacity: 1 !important;
  z-index: $highest-z-index;
}
