@import '~@zf/stella-react/variables.scss';
@import '~@zf/stella-react/src/atoms/Chart/chart-style.module.scss';

.extra-right {
  display: flex;
  gap: 2rem;
}

.btns-wrapper {
  display: flex;
  gap: $spacing-16;
  flex-direction: row;
}

.btns-wrapper > span {
  margin-left: 1rem;
}
