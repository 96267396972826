@import '~@zf/stella-react/variables.scss';
@import '../products/billing-items-column/product-billing-items.module.scss';

.billing-items-config {
  grid-template-columns: 1.1fr 1fr;
}

@media screen and (max-width: 1900px) {
  .billing-items-config {
    grid-template-columns: 1.5fr 1fr;
  }
}

@media screen and (max-width: 1600px) {
  .billing-items-config {
    grid-template-columns: 2.5fr 1fr;
  }
}

.full-space {
  grid-template-columns: 1fr;
}

.items-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, 44rem);
}

.items-grid > div {
  height: fit-content;
}

.items-filter {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-right: 0.5rem;
}
