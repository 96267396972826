@import '~@zf/stella-react/variables.scss';

.alert-circle {
  height: $spacing-24;
  width: $spacing-24;
  background-color: rgba(234, 68, 53, 0.2);
  border-radius: 50%;
  color: $red-600;
  font-size: $spacing-16;
  display: flex;
  justify-content: center;
  align-items: center;
}
