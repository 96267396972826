@import '~@zf/stella-react/variables.scss';

.content-wrapper {
  width: 100%;
}

.dashboard {
  position: relative;
  display: flex;
}

.container {
  box-sizing: border-box;
  overflow: hidden;
  background: $silver-50;
  height: calc(100vh - 8rem);
  margin: 0;
}

.container > div {
  height: 100%;
}

.container > div > div {
  height: 100%;
}

.scrollable {
  overflow: auto;
}
