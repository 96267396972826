@import '~@zf/stella-react/variables.scss';

.right-header {
  display: flex;
  height: 7rem;
  padding: 0rem $spacing-24;
  border-bottom: $border-two $silver-200;
}

.right-header > div:first-of-type {
  font-size: $font-size-20;
}
