@import '~@zf/stella-react/variables.scss';

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 56vh;
}

.compare-mode {
  height: 49vh !important;
}

.stackedDiv1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba($white-200, 0.7);
  z-index: 1000;
}

.stackedDiv2 {
  position: absolute;
  width: 100%;
  height: 56vh;
}

.loader {
  width: 30rem;
}
