@import '~@zf/stella-react/variables.scss';

.org-card {
  position: relative;
  width: 40rem;
  font-family: $font-default;
  border-radius: $border-radius-4;
  background-color: $white-200;
  box-shadow: $medium-box-shadow;
  margin: 2rem;
  height: 26rem;

  .empty-div {
    display: grid;
    height: 12rem;
  }

  .spinner {
    margin: auto;
  }

  .header {
    height: 7rem;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    flex: 1;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    color: $graphite;

    &:hover,
    &:focus {
      color: $blue-400;
    }

    .avatar {
      margin-left: 1rem;
    }
  }

  .quick-numbers {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 9.5rem - 1.6rem);
    padding-left: 9.5rem;
    margin-top: -0.5rem;
    margin-bottom: 2rem;

    .quick-number {
      display: flex;
      gap: $spacing-4;

      span {
        color: $silver-600;
        font-size: $font-size-20;
      }

      p {
        width: fit-content;
      }
    }
  }

  .supplied {
    &:hover {
      color: $blue-400 !important;
    }
  }

  .unsupplied {
    &:hover {
      color: $yellow-600 !important;
    }
  }

  .unpaid {
    &:hover {
      color: $red-600 !important;
    }
  }

  .tosend {
    &:hover {
      color: $green-600 !important;
    }
  }

  .created {
    &:hover {
      color: $yellow-600 !important;
    }
  }

  .intel-container {
    margin-left: 1rem;
    display: grid;
    grid-template-areas: 'workload invoicestatus';
    grid-template-columns: 1fr 1fr;
  }

  .workload {
    display: grid;
    grid-area: workload;
    grid-template-columns: 1fr;
    // grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 2rem;

    .item {
      display: grid;
      grid-template-columns: $spacing-24 1fr;

      span {
        font-size: $font-size-20;
      }
    }
  }

  .error {
    color: $red-600 !important;
  }

  .warning {
    color: $yellow-600 !important;
  }

  .info {
    color: $blue-400 !important;
  }

  .invoicestatus {
    grid-area: invoicestatus;
    display: grid;
    margin: auto;
  }

  .invoicenumber {
    display: grid;
    grid-template-rows: 1fr 1fr;

    .title {
      display: grid;
      margin: auto;
    }

    .number {
      display: grid;
      grid-template-columns: $spacing-24 1fr;

      span {
        font-size: $font-size-20;
      }
    }
  }

  .overview {
    height: 6rem;
    flex: 1;
    padding: 1rem;
  }

  .links {
    position: absolute;
    bottom: 0rem;
    width: 100%;
    border-top: 0.1rem solid $silver-200;
    height: fit-content;
    padding: 1rem 0rem;
    display: flex;
    justify-content: space-around;
    color: $silver-600;
    font-size: $font-size-20;
  }

  .links > *:hover {
    color: $blue-400;
  }

  .links > a {
    text-decoration: none;
    color: $silver-600;
  }

  .actions {
    color: $silver-600;
    position: absolute;
    top: 0.75rem;
    right: 1.25rem;
    font-size: $font-size-20;
    cursor: pointer;
    outline: none;
  }

  .actions:focus {
    color: $blue-400;
  }

  .message-div {
    height: 9rem;
    padding: 2rem;
  }
}
