@import '~@zf/stella-react/variables.scss';

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
}

.inline-input {
  display: flex;
  background-color: $silver-200;
  margin: 0rem !important;
}
