@import '~@zf/stella-react/variables.scss';

.split-view {
  margin-top: 1rem;
  display: flex;
  gap: 3.5rem;
}

.cards-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: $spacing-32;
}
