@import '~@zf/stella-react/variables.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.table-overlay {
  position: absolute;
  top: 0rem;
  left: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.4);
  animation: fadeIn 500ms;

  &.table-overlay-hidden {
    display: none;
  }
}

.error-overlay-default {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin: auto;
}

.heading {
  text-align: center;
}

.overlay-wrapper {
  position: relative;
}
