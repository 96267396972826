@import '~@zf/stella-react/variables.scss';

.wizard-section {
  position: relative;
  min-width: 26rem; // fixed width of inputs is 260px
  padding: 2rem 5rem;
  margin-bottom: $spacing-32;
  border-radius: $border-radius-8;
  border: rgba($blue-200, 0.55) 0.1rem solid;
  box-shadow: $medium-box-shadow;
  background-color: $white-200;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wizard-section > h3 {
  width: 95% !important;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($white-200, 0.8);
  z-index: $overlay-z-index;
}
