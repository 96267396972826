@import '~@zf/stella-react/variables.scss';

.toggle {
  display: grid;
  grid-template-columns: min-content min-content min-content;
  gap: 0.75rem;
  font-size: $font-size-12;
  font-weight: $font-weight-bold;
  color: $silver-600;
  white-space: nowrap;
}

.toggle div {
  margin-top: -0.1rem;
}

.switch {
  position: relative;
  display: flex;
  align-items: center;
  width: $spacing-40;
  height: $spacing-24;
  cursor: pointer;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.1rem solid $blue-600;
  border-radius: 4rem;
  background-color: white;
}

.dot {
  position: absolute;
  content: '';
  height: 1rem;
  width: 1rem;
  left: 0.3rem;
  bottom: 0.2rem;
  background-color: $blue-600;
  border-radius: 4rem;
}

.dot-right {
  position: absolute;
  content: '';
  height: 1rem;
  width: 1rem;
  left: 0.3rem;
  bottom: 0.2rem;
  background-color: $blue-600;
  border-radius: 4rem;
  animation: rightmover 0.4s ease-in-out forwards;
}

.dot-left {
  position: absolute;
  content: '';
  height: 1rem;
  width: 1rem;
  left: 0.3rem;
  bottom: 0.2rem;
  background-color: $blue-600;
  border-radius: 4rem;
  animation: leftmover 0.4s ease-in-out backwards;
}

@-webkit-keyframes rightmover {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(2rem);
  }
}

@-moz-keyframes rightmover {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(2rem);
  }
}

@keyframes rightmover {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(2rem);
  }
}

//////////////////////////////////////////

@-webkit-keyframes leftmover {
  0% {
    -webkit-transform: translateX(2rem);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes leftmover {
  0% {
    -moz-transform: translateX(2rem);
  }
  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes leftmover {
  0% {
    transform: translateX(2rem);
  }
  100% {
    transform: translateX(0);
  }
}
