@import '~@zf/stella-react/variables.scss';

.default-invoice {
  width: 100%;
}

.invoice-moment {
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr 1rem 1fr;
  gap: 1rem;
}

.divider {
  line-height: $line-height-24;
  margin: auto;
  font-size: $font-size-16;
}
