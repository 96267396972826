@import '~@zf/stella-react/variables.scss';

.issue-title {
  font-size: 1.6rem;
}

.issue-subtitle {
  font-size: 1.4rem;
  font-weight: 400;
  color: $silver-600;

  * {
    font-size: 1.4rem;
    font-weight: 400;
    color: $silver-600;
    text-decoration: underline;
  }
}
