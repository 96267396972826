@import '~@zf/stella-react/variables.scss';

.page-header {
  display: grid;
  white-space: nowrap;
  grid-template-columns: min-content min-content min-content;
  gap: $spacing-24;

  .actions {
    height: 4.5rem;
    width: 4.5rem;
    font-size: $font-size-20;
    border-radius: $border-radius-4;
  }
}

.page-header h1 {
  max-width: 110rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-header * {
  margin: auto;
}
