@import '~@zf/stella-react/variables.scss';

.range {
  display: flex;
  gap: $spacing-8;
  width: 80%;
}

.range-spacer {
  width: unset !important;
}

.adjustable-range-inputs {
  width: 100%;
  display: flex;
  align-items: center;
  gap: $spacing-24;
}
