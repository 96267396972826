@import '~@zf/stella-react/variables.scss';

.pdf-preview {
  width: 50%;
  height: 100%;
  padding:$spacing-32;
}

.pdf-translations-wrapper {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 5rem 1fr;
  padding:$spacing-32;
}

.secondary-button {
  background-color: $blue-600;
}

.pdf-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
}

.pdf-preview-example {
  background-color: $white-200;
  padding:$spacing-16;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 80vh;
  grid-column: 1;
  grid-row: 2;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-translations {
  grid-column: 2;
  grid-row: 2;
  margin: 2rem;
}

.translations-close-preview {
  display: flex;
  justify-content: flex-end;
  margin-right: 1.75rem;
}
