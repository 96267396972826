@import '~@zf/stella-react/variables.scss';

.muting-rule-wrapper {
  width: 100%;
}

.comment {
  div {
    width: 100%;
    color: $silver-600;
  }
}
