@import '~@zf/stella-react/variables.scss';

.metering-issues-card {
  &:hover,
  &:focus {
    animation: shadow_hover 150ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  &:active {
    animation: shadow_active;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
}

@keyframes shadow_hover {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: $medium-box-shadow;
  }
}

@keyframes shadow_active {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: $small-box-shadow;
  }
}
