@import '~@zf/stella-react/variables.scss';

.heading {
  margin-bottom: $spacing-8;
}

.file-name-and-file {
  margin-bottom: $spacing-40;
}

.file-name-and-file > div:first-of-type {
  margin-bottom: $spacing-8;
}
