@import '~@zf/stella-react/variables.scss';

.allocation-list-items {
  margin-top: $spacing-8;
  padding-left: $spacing-16;
  text-align: left;
  border-left: 0.1rem solid $silver-200;
}

.allocation-list-items h3 {
  margin-bottom: $spacing-16;
}
