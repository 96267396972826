@import '~@zf/stella-react/src/atoms/Heading/heading.module.scss';

.error-image {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50vh;
  max-height: 50vh;
}

.error-container {
  position: relative;
  height: 100%;
}

.wrapper {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
