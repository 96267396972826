@import '~@zf/stella-react/variables.scss';

.type-input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  gap: $spacing-48;
  padding: 2rem;

  span {
    font-size: $font-size-20;
  }

  .input {
    width: 100%;
  }
}

.type-input-wrapper > div {
  width: 16rem;
}

.notify-icon-customer-add {
  margin-right: $spacing-4;
}
