@import '~@zf/stella-react/variables.scss';

.inputs-wrapper {
  width: fit-content;
  margin: auto;
}

.input-container {
  width: 40rem;
}

.property-groups-container {
  position: relative;
}
