.customer-select {
  margin-top: 2rem;
}

.contract-dropdown {
  width: 65%;
}

.description {
  width: 100%;
}
