@import '~@zf/stella-react/variables.scss';

.multi-locations-body {
  padding: $spacing-16 $spacing-24;
  font-size: $font-size-16;
}

.multi-locations-body > p:first-of-type {
  margin-bottom: $spacing-8;
}
