@import '~@zf/stella-react/variables.scss';

.reversal-wrapper {
  padding: 0.5rem;
  display: grid;
  grid-auto-rows: min-content;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
