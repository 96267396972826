@import '~@zf/stella-react/variables.scss';

.comment {
  display: flex;
  align-items: center;
  gap: $spacing-12;
  background-color: $silver-50;
  padding: $spacing-12 $spacing-16;
  font-size: $font-size-16;
  line-height: $line-height-24;
  border-radius: $border-radius-4;
}
