@import '~@zf/stella-react/variables.scss';

.uppercase-text {
  width: fit-content;
  white-space: nowrap;
  font-size: $font-size-16;
  font-weight: $font-weight-extra-bold;
  line-height: $line-height-32;
  text-transform: uppercase;
  color: $silver-600;
  letter-spacing: 0.08rem;
}
