@import '~@zf/stella-react/variables.scss';

.list-heading {
  padding: 1rem $spacing-32 1rem $spacing-32;
  list-style: none;
  font-size: $font-size-16;
  line-height: $line-height-24;
  font-weight: $font-weight-bold;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-heading span {
  margin-right: 1rem;
}
