@import '~@zf/stella-react/variables.scss';

.mandate {
  margin-top: 2rem;
  padding: $spacing-16;
  border-radius: $border-radius-4;
  border: 0.2rem solid $silver-200;
}

.mandate > div:first-of-type > div:last-of-type {
  margin-left: auto;
}

.mandate > div:first-of-type {
  margin-bottom: 2rem;
}

.mandate > div:last-of-type {
  width: 60%;
}
