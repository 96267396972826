@import '~@zf/stella-react/variables.scss';

.dialog-button {
  padding: 0;
  text-align: 'left';
  justify-content: 'start';
  color: $graphite;
}

.meter-dialog {
  width: 100%;
}

div:has(div > .meter-dialog) {
  width: 100% !important;
}
