@import '~@zf/stella-react/variables.scss';

.contact-content-grid {
  display: grid;
  grid-template-columns: repeat(4, 24rem);
  gap: 1rem;
  text-overflow: ellipsis;
}

.listpage-wrapper {
  width: 80vw;
  height: 65vh;
  overflow: hidden;
  border-bottom: $border-one $silver-200;
  border-bottom-left-radius: $border-radius-8;
  border-bottom-right-radius: $border-radius-8;
}
