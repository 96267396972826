@import '~@zf/stella-react/variables.scss';

.spinner-wrapper {
  height: 100vh;
  width: 100%;
}

.tab-height {
  height: $tab-body-height;
}
