@import '~@zf/stella-react/variables.scss';

.number-sequence-config {
  display: grid;
  grid-template-areas: 'numbersequences segments';
  grid-template-columns: 30% 1fr;
  grid-template-rows: 70vh;
  grid-gap: $spacing-24;
  padding: 3.5rem;
  position: relative;
}

.number-sequences {
  grid-area: numbersequences;
}

.segments {
  grid-area: segments;
}

.tree {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.prepaid-billing-setting {
  width: 100%;
}

.segment-title {
  display: grid;
  width: 100%;
  grid-template-areas: 'number type action add';
  grid-template-columns: 5rem auto 2rem 5.5rem;
}

.number {
  grid-area: number;
}

.type {
  grid-area: type;
}

.action {
  grid-area: action;
}

.add {
  grid-area: add;
}

.tree-item-lookup {
  padding: 1rem;
}
