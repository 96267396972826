@import '~@zf/stella-react/variables.scss';

.wrap {
  height: 20rem;
  width: 13rem;
  margin: 1rem 0 0 $spacing-16;
  padding: 0;
  overflow: hidden;
}

.frame {
  width: 53rem;
  height: 80rem;
  transform: scale(0.25);
  -moz-transform: scale(0.25);
  transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.25);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.25);
  -webkit-transform-origin: 0 0;
}

.preview-btn {
  display: flex;
  margin: 0 1rem 0 1rem;

  * {
    padding: 0.5rem;
  }
}
