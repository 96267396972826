@import '~@zf/stella-react/variables.scss';

.listpage-wrapper {
  width: 80vw;
  height: 65vh;
  overflow: hidden;
  border-bottom-left-radius: $border-radius-8;
  border-bottom-right-radius: $border-radius-8;
  padding-bottom: $spacing-16;
}

// The listpage
.listpage-wrapper > div:first-of-type > div:first-of-type {
  width: 100%;
}

// The table
.listpage-wrapper > div:first-of-type > div:first-of-type > div:last-of-type {
  border-radius: $border-radius-8;
}
