@import '~@zf/stella-react/variables.scss';

.conditions-add-options-wrapper {
  display: flex;
}

.conditions-add-options-content-trail {
  display: flex;
}

.conditions-add-options-content {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}

.conditions-add-options-content {
  border: $border-one $silver-200;
  border-radius: $border-radius-8;
  padding: $spacing-16;
  margin-top: $spacing-16;
}

.conditions-add-option {
  display: flex;
  justify-content: flex-end;
}

.conditions-add-header {
  display: flex;
}
