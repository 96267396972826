@import '~@zf/stella-react/variables.scss';

.inputfield-wrapper {
  width: 100%;
  position: relative;
  cursor: text;
  background-color: $silver-200;
  border-radius: $border-radius-4;
  border: 0.1rem solid transparent;
  transition: all 0.3s ease-in-out;
  height: fit-content;
  white-space: nowrap;

  &:hover {
    background-color: $silver-400;
  }

  label:hover {
    color: $silver-600 !important;
  }

  &.focused {
    border: 0.1rem solid $blue-600 !important;
    background-color: $white-200;
  }

  &.error {
    border-bottom: 0.1rem solid $red-600;
  }

  .center-input {
    text-align: center;
  }

  .hidden-arrows::-webkit-outer-spin-button,
  .hidden-arrows::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  .hidden-arrows[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  .validation-popup {
    margin-right: $spacing-8;
    cursor: default;
  }

  .inputfield-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    cursor: inherit;
    outline: none;
    color: $graphite;

    .input-field {
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      box-sizing: border-box;
      font-family: $font-default;
      font-size: $font-size-16;
      color: inherit;
      padding: $spacing-8 $spacing-16;
      line-height: $line-height-24;
    }

    .input-field::selection {
      background: $blue-200;
    }

    .input-icon {
      line-height: $line-height-16;
      margin: auto;
      font-size: $font-size-16;
      color: $silver-600;
      box-sizing: border-box;
      cursor: pointer;

      &.input-icon-left {
        padding-left: $spacing-16;
      }

      &.input-icon-right {
        padding-right: $spacing-16;
      }
    }

    .suffix {
      font-size: $font-size-16;
      color: inherit;
      box-sizing: border-box;
    }

    .prefix {
      margin-left: $spacing-16;
    }

    .postfix {
      margin-right: $spacing-16;
    }

    .input-icon-disabled {
      font-size: $font-size-16;
      color: $silver-400;
      box-sizing: border-box;

      &.input-icon-left-disabled {
        padding-right: $spacing-4;
      }

      &.input-icon-right-disabled {
        padding: 0 $spacing-4;
      }
    }
  }

  .inputfield-label {
    position: absolute;
    padding-left: $spacing-8;
    cursor: inherit;
    font-family: $font-default;
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
    line-height: $line-height-24;
    color: $silver-600;
    transition: all 0.1s ease-in-out;
    left: $spacing-8;
    bottom: 0.85rem;

    &.inputfield-label-with-prefix {
      padding-left: $spacing-32;
    }

    &.inputfield-label-icon-offset {
      padding-left: $spacing-40;
    }

    &.inputfield-floating-label-prefix {
      padding-left: $spacing-8;
    }

    &.inputfield-floating-label {
      font-size: $font-size-12;
      font-weight: $font-weight-bold;
      color: $silver-600;
      margin-top: -2.2rem;
      left: -$spacing-8;
      bottom: auto;

      &.inputfield-label-hidden {
        display: none;
      }
    }
  }

  &.disabled {
    cursor: default;

    .inputfield-container,
    .inputfield-label {
      color: $silver-600;
      border-color: $silver-400;
    }

    &.focused {
      border: none !important;
    }

    &::after {
      border: none !important;
    }
  }

  &.light-mode {
    background-color: $white-200;
    border-color: $silver-200;

    &.focused {
      background-color: $white-200;
    }
  }

  &.dark-mode {
    background-color: $silver-200;

    &.focused {
      background-color: $white-200;
    }
  }
}

.tooltip {
  padding: $spacing-8 $spacing-16;
}
