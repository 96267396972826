@import '~@zf/stella-react/variables.scss';

.address-input {
  width: 100%;
  border-bottom: 0.1rem solid transparent;
  outline: none;
  text-align: right;

  &:hover,
  &:focus {
    border-bottom: 0.1rem solid $silver-600;
  }
}

.invoice-address {
  margin-top: $spacing-16;
  display: grid;
  grid-template-areas: 'street-name street-name';
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 2rem;
  grid-column-gap: 1rem;

  .street-name {
    grid-area: street-name;
  }
}

.relative {
  position: relative;
  margin-bottom: 8rem;
}

.type {
  position: absolute;
  right: 0rem;
}
