@import '~@zf/stella-react/variables.scss';

.radio-options {
  margin-top: $spacing-40;
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
}

.ubl-export-warnings {
  margin-top: $spacing-16;
  margin-bottom: $spacing-16;
  max-width: 45rem;

  ul {
    text-align: left;
    margin: 0;
    padding-left: $spacing-16;
  }
}

.invoice-issues-desc {
  text-align: left;
}
