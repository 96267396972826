@import '~@zf/stella-react/variables.scss';

.side-panel-body {
  height: 100%;
}

.graph-wrapper {
  display: flex;
  flex: 1;
  max-height: 55rem;
}

.info-banner {
  border-radius: $border-radius-4;
}
