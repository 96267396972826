@import '~@zf/stella-react/variables.scss';

.locations {
  margin-bottom: $spacing-16;
}

.locations ul li {
  font-size: $font-size-16;
  text-align: left;
  color: $graphite;
}

.locations ul {
  padding: 0rem $spacing-24;
}
