@import '~@zf/stella-react/variables.scss';

.details {
  margin-bottom: 2rem;
}

.details > div,
.contract > div {
  padding: 0rem 0rem 1rem 0rem;
}

.spinner {
  height: 10rem;
}

.potentially-linked-entities {
  margin-top: $spacing-32;
}

.comment {
  margin-bottom: $spacing-32;
}

.comment p {
  padding-top: 0.5rem;
  padding-left: 1rem;
  font-style: italic;
}
