@import '~@zf/stella-react/variables.scss';

.fileformat-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
  padding-top: $spacing-8;
  width: 60rem;
}

.fileformat-container > button {
  margin-bottom: $spacing-8;
}
