@import '~@zf/stella-react/variables.scss';

.customer-bank-account-wrapper-default {
  display: grid;
  grid-template-columns: 93% min-content;
  gap: $spacing-8;
  border-radius: $border-radius-4;
  padding: 2rem;
  padding-top: 2.4rem;
  background-color: $silver-50;
  margin-bottom: 2rem;
  border: solid $blue-400 1px;
}

.customer-bank-account-wrapper {
  display: grid;
  grid-template-columns: 93% min-content;
  gap: $spacing-8;
  border-radius: $border-radius-4;
  padding: 2rem;
  padding-top: $spacing-24;
  background-color: $silver-50;
  margin-bottom: 2rem;
}

.customer-bank-account > div:last-of-type > div:first-of-type {
  margin-top: 0.25rem;
}

.customer-bank-account > div:last-of-type {
  margin-top: $spacing-8;
  text-align: left;
  gap: $spacing-8;
}

.input-field-bankaccounts {
  height: 4rem;
}

.customer-bank-account > div:last-of-type > div {
  white-space: normal !important;
}

.is-default {
  margin: $spacing-8 0rem;
}

.bottom-actions {
  display: flex;
  margin-top: $spacing-8;
}

.bottom-actions > div:last-of-type {
  display: flex;
  margin-left: auto;
  margin-right: $spacing-8;
  align-items: center;
  gap:$spacing-32;
}

.border-red {
  border: 0.2rem solid $red-600;
  border-radius: $border-radius-4;
  padding: 0.75rem;
}

.inline-delete-confirmation {
  border-radius: $border-radius-4;
  padding: 2rem;
  background-color: $silver-200;
  margin-bottom: 2rem;
}

.inline-delete-confirmation > div {
  margin-top: 2rem;
  display: flex;
  gap:$spacing-32;
  justify-content: center;
}
