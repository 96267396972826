@import '~@zf/stella-react/variables.scss';
.commit-wrapper {
  width: max-content;
  display: grid;
  margin-left: auto;
}

.commit-actions {
  display: flex;
  margin-left: auto;
  z-index: 1;
  margin: 0rem $spacing-8 $spacing-8 0rem;
}

.action {
  display: inline-block;
}
