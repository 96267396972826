@import '~@zf/stella-react/variables.scss';

.file-generating {
  display: flex;
  gap: $spacing-16;
}

.tab-slider-only-mutations {
  margin-bottom: $spacing-32;
}

.label-export-tariff-range {
  margin-bottom: $spacing-16;
}

.export-social-tariff {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.protected-customer-dropdown {
  width: 20rem;
  margin: 0 auto;
}

.social-tariff-is-moved {
  margin-top: $spacing-12;
}

.tab-slider-label {
  margin-bottom: $spacing-4;
}

.quarters-inputs {
  display: flex;
  gap: $spacing-16;
}

.quarters-inputs {
  margin-top: $spacing-16;
}

.protected-customer-dropdown-extra-info {
  margin-top: $spacing-16;
}
