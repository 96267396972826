@import '~@zf/stella-react/variables.scss';

.attachments {
  margin: 2rem 0rem;
}

.heading-plus-icon {
  display: flex;
}

.heading-plus-icon > button {
  margin-left: auto;
}

.plus-icon {
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: fit-content;
  font-size: $font-size-20;
}
