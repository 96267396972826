@import '~@zf/stella-react/variables.scss';

.prefs-grid {
  margin-top: 2rem;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: $spacing-16;
}

.prefs-row-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.prefs-row-grid span {
  text-align: left;
}

.check-wrapper {
  > * {
    padding: $spacing-8;
  }
}

.card-body {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
}

.type-select * {
  font-size: $font-size-25;
}
