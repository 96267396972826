@import '~@zf/stella-react/variables.scss';

.header {
  padding: $spacing-16;
  .title {
    font-weight: $font-weight-bold;
    line-height: $line-height-32;
  }
  .desc {
    font-weight: $font-weight-regular;
    line-height: $line-height-24;
  }
}

.line {
  grid-area: line;
  align-self: center;
  border: $border-one $silver-200;
  width: 94%;
}

.settings {
  border: none !important;
  padding: $spacing-16;
}
