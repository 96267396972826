.pdf-preview-header-options {
  display: flex;
  flex-direction: column;
}

.pdf-preview-header-options > button {
  width: 10rem;
}

.pdf-preview-text-labels {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.download-icon {
  margin-right: 1rem;
}
