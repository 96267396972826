@import '~@zf/stella-react/variables.scss';

.incoming-cost {
  position: relative;
  margin-bottom: $spacing-16;
}

.incoming-cost h3 {
  margin-bottom: 0.5rem;
}

.period {
  font-size: $font-size-16;
  color: $silver-600;
}

.actions {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 2rem;
  top: 2rem;
}

.actions span {
  margin-left: 0.5rem;
}

.actions * {
  font-size: $font-size-16 !important;
}

.flex-div {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.flex-div p {
  text-align: right;
}

.inv-number {
  text-align: left;
  font-size: $font-size-16;
  color: $silver-700;
}

.delete-icon span {
  margin-left: 1rem;
  font-size: $font-size-16;
  line-height: normal;
}

.total {
  margin-right: 2.6rem;
}
