@import '~@zf/stella-react/variables.scss';

.spinner,
.spinner:after {
  border-radius: 50%;
}

.spinner-xsmall,
.spinner-xsmall:after {
  width: 1rem;
  height: 1rem;
  border-width: 0.2rem;
}

.spinner-small,
.spinner-small:after {
  width: 2rem;
  height: 2rem;
  border-width: 0.2rem;
}

.spinner-medium,
.spinner-medium:after {
  width: 4rem;
  height: 4rem;
  border-width: 0.4rem;
}

.spinner-large,
.spinner-large:after {
  width: 6rem;
  height: 6rem;
  border-width: 0.5rem;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  margin: 0;
  transform: translateZ(0);
  animation: loadkeyframes 1.1s infinite ease-in-out;
  border-style: solid;
  border-color: rgba(0, 145, 247, 0.2) rgba(0, 145, 247, 0.2) $blue-400 rgba(0, 145, 247, 0.2);
}

.spinner-inverted {
  border-color: rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.2) $white-200 rgba(255, 255, 255, 0.2);
}

@keyframes loadkeyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
