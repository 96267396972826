@import '~@zf/stella-react/variables.scss';

.text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  .title {
    width: 26rem;
    font-size: $font-size-20;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-8;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
