@import '~@zf/stella-react/variables.scss';

$file-list-grid-column: 1fr;

.file-list {
  display: grid;
  padding: 1rem;
  grid-gap: 1rem;

  &.file-list-rows-1 {
    grid-template-columns: $file-list-grid-column;
  }

  &.file-list-rows-2 {
    grid-template-columns: repeat(2, $file-list-grid-column);
  }
}

@media (max-width: 750px) {
  .file-list {
    grid-template-columns: $file-list-grid-column;
  }
}
