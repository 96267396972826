@import '~@zf/stella-react/variables.scss';

.wrapper {
  margin-top: $spacing-8;
}

.top-divider {
  margin-bottom: $spacing-32;
}

.divider {
  margin-bottom: $spacing-16;
}

.parameters {
  margin-top: $spacing-32;
}

.contract-lvl {
  margin-bottom: $spacing-16;
}

.specific-service {
  margin-bottom: $spacing-8;
}

.show-contract-lvl-option {
  padding: 0rem $spacing-12;
}

.service-dropdown {
  padding-top: $spacing-8;
  max-width: 30rem;
}

.service-dropdown-border {
  padding-left: $spacing-16;
  border-left: $border-two $silver-400;
}

.loader-wrapper {
  // Reference is subscription type parameters height to prevent jumpyness
  height: calc(29.8rem + $spacing-32);
}
