@import '~@zf/stella-react/variables.scss';

.item-name {
  width: fit-content;
}

.asking-periods {
  margin-top: $spacing-16;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info {
  padding: $spacing-16;
}

.extra-left {
  display: flex;
}

.extra-left > div:last-of-type {
  margin-left: auto;
  margin-right: 1rem;
}
