@import '~@zf/stella-react/variables.scss';

.dialog-header {
  display: flex;
  gap: $spacing-8;
  line-height: $line-height-32;
  font-weight: $font-weight-extra-bold;
  align-items: center;
  justify-content: center;
  color: $blue-600;
  letter-spacing: 0.08rem;
}

.dialog-header > span {
  font-size: $font-size-20;
}

.dialog-header > h2 {
  color: $blue-600;
  font-size: $font-size-16;
  font-weight: $font-weight-extra-bold;
  text-transform: uppercase;
  letter-spacing: 0.08rem;
}

.danger > span,
.danger > h2 {
  color: $red-600 !important;
}
