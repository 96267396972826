@import '~@zf/stella-react/variables.scss';

.footer {
  height: 12rem;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $silver-50;
  color: white;
  text-align: center;
}

.btns {
  height: 100%;
  width: 100%;
  display: flex;
  gap: $spacing-16;
  align-items: center;
  justify-content: center;
}

.btns > div {
  width: 23rem;
}

.btns div:nth-of-type(2) {
  margin-left: 2rem;
}
