@import '~@zf/stella-react/variables.scss';

.color-div {
  margin: 0.2rem 0.2rem;
  width: $spacing-8;
  height: $spacing-8;
  border-radius: $border-radius-2;
}

.color-boxes {
  display: flex;
  flex-wrap: wrap;
}

.color-div-wrapper {
  width: $spacing-8;
  margin: auto;
  margin-left: 0rem;
  margin-right: 0.6rem;
}

.progress {
  width: 5rem;
  margin-right: 0.5rem;
}

.tooltip {
  display: flex;
}

.tooltip * {
  white-space: nowrap;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
}

.tooltip p:nth-of-type(2) {
  margin-left: 0.5rem;
}

.no-cursor {
  cursor: default !important;
}
