@import '~@zf/stella-react/variables.scss';

.file {
  display: grid;
  grid-template-areas: 'icon name validation-icon';
  grid-template-columns: 2rem 1fr auto;
  grid-column-gap: 1rem;
  font-family: $font-default;
  color: $silver-700;
  cursor: pointer;

  &:focus {
    text-decoration: underline;
  }

  .file-icon,
  .file-validation-icon {
    display: flex;
    justify-content: center;
  }

  .file-icon {
    grid-area: icon;
    width: 2rem;
  }

  .file-name {
    outline: none;
    grid-area: name;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }

  .file-validation-icon {
    grid-area: validation-icon;
    width: 2rem;

    &.is-valid {
      color: $green-600;
    }

    &.is-invalid {
      color: $red-600;
    }

    &.none {
      &:hover {
        cursor: pointer;
        outline: none;
        color: $red-600;
      }
    }
  }
}
