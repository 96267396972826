@import '~@zf/stella-react/variables.scss';

.action {
  padding-top: 0.2rem;
  position: absolute;
  right: 2%;
}

.overflow-button {
  margin-left: $spacing-24;
  font-size: $font-size-31;
  display: flex;
  align-items: right;
  justify-content: right;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: color 0.2s ease;
  color: $silver-600;

  ul {
    padding-right: 3.5rem;
    margin-left: -$spacing-24;
  }

  ul li {
    list-style: none;
    padding: 0.5rem;
    margin-bottom: 0.3rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
  }
}

.overflow-button:hover {
  color: $blue-600;
}

.overflow-title {
  transition: color 0.2s ease;
  color: $silver-600;
  padding: 0.5rem;
  font-size: $font-size-16;
}

.tab-container-subtitle {
  grid-template-rows: 16rem $tab-body-height !important;
}

.tab-container-subtitles {
  grid-template-rows: 18rem $tab-body-height !important;
}

.tab-container {
  padding: $spacing-48 $spacing-56;
  display: grid;
  grid-template-rows: 13.5rem $tab-body-height;
  font-family: $font-default;
  height: calc(100vh - 8rem);
  overflow-y: hidden;

  .tab-header-wrapper {
    width: 100%;
  }

  .header-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 1.6rem;
  }

  .header-tabs-row {
    display: grid;
    grid-template-columns: 1fr 2rem;
    z-index: 2;
  }

  .tab-content {
    overflow: auto;
  }

  .tab-content-noscroll {
    height: 82vh;
    overflow: hidden;
  }

  .overflow-tab {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: left;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    outline: none;

    .title {
      transition: color 0.2s ease;
      color: $silver-600;
      padding: 0.5rem;
    }

    .border {
      border-bottom: 0.2rem solid $blue-600;
      transition: all 0.3s ease;
      transform: scaleX(0);
      margin-top: $spacing-16;
    }

    &.is-selected {
      .title {
        color: $blue-600;
      }
      .border {
        transform: scaleX(1);
      }
    }

    &:hover,
    &:focus {
      .title {
        color: $blue-600;
      }
    }
  }
}

.right-action-wrapper {
  height: 4.5rem;
  z-index: 2;
}

.right-actions {
  float: right;
  padding: 0rem 1rem 0rem 1rem;
  background-color: white;
  border: 0.1rem solid $silver-200;
  border-radius: $border-radius-4;
  display: grid;
  height: 100%;
  grid-template-columns: min-content 2rem;
  transition: all 0.2s ease;
  z-index: 2;
}
.right-actions:hover {
  background-color: $silver-50;
}

.right-actions span {
  margin: auto;
  font-size: $font-size-20;
  font-weight: $font-weight-regular;
}

.wrapper {
  display: grid;
  grid-template-columns: auto 67rem;
}

.tab-items {
  position: relative;
  font-family: $font-default;
  font-weight: $font-weight-bold;
  color: $silver-600;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: $spacing-24;
}

.action-icon-label {
  color: $red-400;
  width: 4rem;
}

.chevron-icon {
  font-size: $font-size-20;
  color: $silver-600;
}

.title span {
  padding-right: 0.75rem;
  line-height: normal;
}

.tooltip-content {
  padding: $spacing-16 $spacing-24;
}

.tooltip-content > div {
  margin-left: 0.5rem;
}
