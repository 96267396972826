@import '~@zf/stella-react/variables.scss';

.column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-coachmark {
  height: 4rem;
  display: flex;
  gap: $spacing-16;
  align-items: center;
}

.title {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: $spacing-8;
}

.title p {
  max-width: 14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1750px) {
  .title p {
    max-width: 16rem;
  }
}

.extras {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.column-header,
.title-coachmark,
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle-below span {
  font-size: $font-size-16;
  color: $silver-700;
}
