@import '~@zf/stella-react/variables.scss';

.wrapper {
  height: 72rem;
  width: 80vw;
  margin-top:$spacing-32;
  padding: 2rem 5rem 2rem 5rem;
  background-color: $silver-50;
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  border-radius: $border-radius-4;
  gap: 2rem;
}

.items-grid {
  height: 66rem;
  overflow-y: auto;
}

.items-header {
  height: $spacing-48;
  display: flex;
  align-items: center;
}

.items-filter {
  display: flex;
  align-items: center;
  gap: $spacing-16;
}

.items-header > div:last-of-type {
  margin-left: auto;
}

.items-list-entry {
  display: flex;
  flex-direction: row;
}

.items-list-entry span {
  margin-left: 0.5rem;
}

.placeholder-div {
  width: 3.5rem;
}

.list-column-headers {
  margin-bottom: 2rem;
}

.list {
  padding: 0rem $spacing-16;
}

.row {
  // row height - padding
  height: calc(40.5rem - $spacing-24) !important;
  display: flex;
  gap: $spacing-24;
  padding: $spacing-24;
}
