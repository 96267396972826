@import '~@zf/stella-react/variables.scss';

.footer {
  height: 10rem;
  width: 100%;
  background-color: $silver-50;
  color: white;
  text-align: center;
}

.center {
  display: grid;
  width: fit-content;
  margin: auto;
}
