@import '~@zf/stella-react/variables.scss';

.change-product-dialog-wrapper {
  width: 65rem;
}

.current-products {
  display: flex;
  flex-direction: column;
  padding: $spacing-24 $spacing-16 $spacing-24 $spacing-16;
  margin-top: $spacing-8;
  border-radius: $border-radius-8;
  border: $border-one $silver-200;
  gap: $spacing-8;
}

.current-products-item {
  display: flex;
  justify-content: space-between;
  gap: $spacing-32;
}

.current-products-product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.change-products-title {
  margin-bottom: $spacing-8;
  margin-top: $spacing-16;
}

.change-date-selection {
  width: 55rem;
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}

.change-date-selection-item {
  display: flex;
  align-items: center;
}

.credited-invoices {
  border-radius: $border-radius-8;
  border: 1px solid $orange-600;
  padding: $spacing-8 $spacing-8 $spacing-8 $spacing-8;
  margin-top: $spacing-24;
}

.credited-invoices-show-more {
  white-space: nowrap;
}

.credited-invoices-wrapper {
  display: flex;
  justify-content: space-between;
}

.credited-invoices-wrapper > div:first-of-type {
  margin-left: $font-size-12;
}

.change-product-dialog {
  width: 100%;
}

.credited-invoice-table {
  height: 20rem;
}

.select-product {
  margin-bottom: $spacing-8;
}

.date-selection-wrapper {
  margin-top: $spacing-32;
}

.flex-p {
  white-space: nowrap;
  display: flex;
  gap: $spacing-8;
}

.product-dropdown {
  margin-bottom: $spacing-32;
}
