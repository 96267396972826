@import '~@zf/stella-react/variables.scss';

.affected-products {
  font-size: $font-size-16;
  line-height: $line-height-24;
  text-align: left;
  color: $graphite;
}

.affected-products > ul {
  margin-top: 0rem;
}
