@import '~@zf/stella-react/variables.scss';

.table-wrapper {
  height: 100%;
  width: 100%;
}

.table-wrapper * {
  outline: none;
}

.header-row > div {
  color: $silver-600;
  text-transform: capitalize;
  font-weight: $font-weight-bold;
  font-size: $font-size-16;
}

.cell-wrapper {
  display: flex;
  padding: 0rem 1rem;
  align-items: center;
}

.cell-wrapper * span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell-wrapper > span {
  margin-left: 1rem;
}

.sort-trigger {
  cursor: pointer;
}

.header-cell {
  margin-left: 1rem;
}

.simple-tooltip {
  border: 0.1rem solid $silver-200 !important;
  border-radius: $border-radius-4 !important;
  font-family: $font-default !important;
  font-size: $font-size-16 !important;
  box-shadow: $small-box-shadow !important;
}
