@import '~@zf/stella-react/variables.scss';

.tooltip {
  background-color: $white-200;
  border-radius: $border-radius-4;
  border: 0.1rem solid $blue-400;
}

.tooltip > div {
  margin: 0.25rem !important;
  border: none !important;
}

.tooltip p {
  color: $silver-700 !important;
  font-weight: $font-weight-bold !important;
}
