@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: grid;
  grid-template-rows: 1fr min-content min-content;
  padding: 1rem 0rem 1rem 0rem;
}

.header {
  display: grid;
  grid-template-columns: 1fr min-content;
}

.header > button {
  text-align: left;
}

.title {
  display: flex;
  white-space: nowrap;
  padding: 0rem;
}

.title span {
  text-align: center;
  height: min-content;
  width: 2rem;
  color: $silver-700;
  padding-left: 0.5rem;
}

.title * {
  margin: auto;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
}

.collapsable-content {
  opacity: 0;
  animation: forwards slideIn 0.6s ease-in-out;
}

@keyframes slideIn {
  from {
    margin-top: $spacing-16;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}
