@import '~@zf/stella-react/variables.scss';

.button {
  margin-left:$spacing-32;
}

.spacing {
  margin-top: $spacing-8;
  margin-bottom: $spacing-8;
}

.logging-list-item {
  margin-bottom: $spacing-8;
}

.iconButton {
  margin-left: 2rem;
}

.no-shadow {
  box-shadow: none !important;
}

.extra {
  display: flex;
  gap: $spacing-16;
  justify-content: center;
  align-items: center;
}
