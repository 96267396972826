@import '~@zf/stella-react/variables.scss';

.feed-item {
  box-sizing: border-box;
  display: grid;
  width: 100%;
  grid-template-areas: 'icon heading time' 'icon content content';
  grid-template-columns: 3.5rem 1fr 14rem;
  grid-gap: 0.5rem;
  padding: 0rem $spacing-8;

  .icon {
    grid-area: icon;
    align-self: center;
    font-size: $font-size-20;
  }

  .heading {
    grid-area: heading;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .time {
    grid-area: time;
    font-size: $font-size-12;
    color: $silver-700;
    text-align: right;
  }

  .content {
    grid-area: content;
  }

  .content * {
    font-size: $font-size-12 !important;
  }
}
