@import '~@zf/stella-react/variables.scss';

.heading {
  height:$spacing-32;
  display: flex;
  flex-direction: row;
}

.heading,
h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.heading h3 {
  font-size: $font-size-16;
  color: $graphite;
  padding-right: 2rem;
}

.product-container-child {
  padding: 2rem;
}

.heading > div {
  display: flex;
  width:$spacing-32;
  height:$spacing-32;
}

.heading > div * {
  align-items: center;
  justify-content: center;
}

.icon {
  margin-left: 1rem;
}

.actions {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
}

.info-grid {
  margin-top: 1rem;
  padding: 0rem;
}

.counts {
  display: flex;
  margin-left: auto;
}

.counts > p:last-of-type {
  margin-left: 0.5rem;
}

.product-container-card {
  padding: 0;
}
