@import '~@zf/stella-react/variables.scss';
.input-container {
  max-width: $spacing-40;
  margin: auto;
  margin-top: $spacing-32;
}

.input-container > div {
  margin: auto;
}

.progress-bar {
  margin-top: $spacing-48;
}
