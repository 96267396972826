@import '~@zf/stella-react/variables.scss';

.wrapper {
  display: grid;
  padding: 1rem !important;
  gap: 1rem;
  margin-bottom: $spacing-16;
  grid-template-columns: 5rem 1fr;
}

.heading {
  display: flex;
  flex-direction: row;
}

.heading h4 {
  margin-bottom: 0rem;
}

.heading h4:nth-of-type(2) {
  margin-left: 0.5rem;
  color: $silver-700;
}

.value-msmt-btn {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  white-space: nowrap;
  margin-bottom: 0rem;
}

.value-msmt-btn p {
  white-space: nowrap;
  margin-bottom: 0rem;
}
