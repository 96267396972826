.wrapper {
  display: grid;
  grid-auto-rows: min-content;
  padding: 1rem;
}

.info-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.info-row p {
  text-align: right;
}

.tier {
  margin-top: 1rem;
}
