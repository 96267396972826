@import '~@zf/stella-react/variables.scss';

.accessible-div-btn {
  position: relative;
}

.disabled {
  cursor: auto !important;
  color: $silver-400 !important;
}

.disabled-info {
  padding: 1rem;
}
