@import '~@zf/stella-react/variables.scss';

.wrapper {
  font-size: $font-size-16;
  height: 4rem;
  display: flex;
  align-items: center;
  gap: $spacing-8;
  cursor: pointer;
  outline: none;
}

.org-label {
  color: $graphite;
  line-height: $line-height-24;
}

.down-icon {
  color: $graphite;
}

.exclamation-icon {
  font-size: $font-size-16 !important;
  margin-left: 0.5rem;
}

.dropdown-item {
  display: grid;
  grid-template-columns: 2rem min-content;
  line-height: $line-height-24;
}

.open-issues {
  text-align: right;
}

.list {
  padding-top: 0rem;
}

.list > button {
  background-color: $silver-200;
  width: 100%;
}

.list > button:hover {
  background-color: $silver-400;
  color: $graphite;
  border-radius: 0rem;
}
