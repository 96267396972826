@import '~@zf/stella-react/variables.scss';

.table {
  height: 20rem;
  margin-bottom: $spacing-40;
}

.invoice-status-icon {
  margin-right: $spacing-16;
}
