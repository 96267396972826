@import '~@zf/stella-react/variables.scss';

.operator {
  cursor: pointer;
  font-size: $font-size-25 !important;
  font-weight: $font-weight-bold;
  color: $silver-700;
  border-radius: $border-radius-8;
  padding: 0.5rem;
  text-align: center;
  height: 4rem;
  width: 4rem;
  margin-right: $spacing-16;
  border: 0.1rem solid rgba($silver-400, 0.3);
  box-shadow: $small-box-shadow;
  outline: none;

  &:hover,
  &:focus {
    box-shadow: $small-box-shadow;
  }
}

.cost-component {
  border-left: 0.5rem solid transparent;
  border-radius: $border-radius-4;
  padding: 0.5rem;
  width: 20rem;
  height: 8rem;
  padding-left: $spacing-8;
  box-shadow: $small-box-shadow;
  outline: none;

  &:hover,
  &:focus {
    box-shadow: $large-box-shadow;
  }
}

.heading-cost-component {
  cursor: pointer;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-20;
}

.formulae-wrapper {
  margin: 4rem;
  outline: none;
}

.delete {
  justify-content: flex-end;
  font-size: $font-size-25;
  margin-left: auto;
  outline: none;

  .active,
  &:focus {
    box-shadow: $large-box-shadow;
  }
}

.formulae-builder {
  display: flex;
  padding: 2rem;
  grid-gap: 2rem;
  flex-direction: column;
}

.formula-wrapper {
  min-height: 25rem;
  max-height: 45rem;
  overflow-y: auto;

  background-color: rgba($silver-200, 0.9);
  border: 0.1rem solid rgba($silver-400, 0.1);
  border-radius: $border-radius-4;
}

.formula-text {
  color: $silver-600;
  margin: 2rem;
  width: calc(100% - 4rem);
}

.droppeable {
  display: flex;
  flex-wrap: wrap;
  outline: none;
  padding: 0.5rem;
}

.cost-components {
  display: grid;
  grid-template-columns: auto;
  grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
  grid-gap: $spacing-24;
}

.operators {
  display: flex;
  margin-left: 5rem;
  margin-right: 5rem;
}
