.info {
  width: 75rem;
  margin-bottom: 2rem;
}

.date-picker-wrapper {
  margin: auto;
  padding: 2rem;
  width: 30rem;
}
