@import '~@zf/stella-react/variables.scss';

.icon {
  margin-right: 0.5rem;
}

.dns-records-table {
  width: 100%;
  height: 17rem;
}
