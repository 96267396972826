@import '~@zf/stella-react/variables.scss';

.address {
  margin-bottom: $spacing-8;
}

.full-width {
  width: 100%;
}

.service-period {
  font-size: $font-size-12;
  color: $silver-600;
  text-align: left;
  margin-top: $spacing-4;
}

.icon-utilityType {
  font-size: $font-size-31;
}
