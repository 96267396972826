@import '~@zf/stella-react/variables.scss';

.product-attachment {
  position: relative;
  border: 0.2rem solid $silver-200;
  border-radius: $border-radius-4;
  padding: 1rem;
  margin-bottom: 2rem;
}

.greyed-out * {
  color: $silver-600 !important;
}

.product-attachment > div:first-of-type {
  margin-top: 1rem;
}

.product-attachment > p:nth-of-type(2) {
  margin-top: 1rem;
  color: $silver-600;
}

.actions {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
